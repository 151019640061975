import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from 'reactstrap';

import { useHistory } from 'react-router-dom';
//import images
import avatar1 from '../../assets/images/users/avatar-1.jpg';
// import useAuth from '../../utils/useAuth';

const ProfileDropdown = () => {
	//Dropdown Toggle
	const [isProfileDropdown, setIsProfileDropdown] = useState(false);
	const [shortName, setShortName] = useState('');
	const toggleProfileDropdown = () => {
		setIsProfileDropdown(!isProfileDropdown);
	};

	const profile = useSelector((state) => state.authSlice);
	const fullName = profile?.user?.name.profile.full_name;
	const position = profile?.user?.name?.role?.translation?.name;
	useEffect(() => {
		if (fullName) {
			setShortName(
				fullName
					?.split(' ')
					.map((n) => n[0])
					.join('')
			);
		} else {
			setShortName('A');
		}
	}, [shortName, fullName]);

	const history = useHistory();
	return (
		<React.Fragment>
			<Dropdown
				isOpen={isProfileDropdown}
				toggle={toggleProfileDropdown}
				className='ms-sm-3 header-item topbar-user'
			>
				<DropdownToggle tag='button' type='button' className='btn shadow-none'>
					<span className='d-flex align-items-center'>
						<div
							style={{
								width: '60px',
								height: '60px',
								borderRadius: '50%',
								backgroundColor: '#fff',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontWeight: 'bold'
							}}
						>
							{shortName}
						</div>
						<span className='text-start ms-xl-2'>
							<span className='d-none d-xl-inline-block ms-1 fw-medium user-name-text'>
								{fullName ? fullName : 'John Doe'}
							</span>
							<span className='d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text'>
								{position ? position : 'Admin'}
							</span>
						</span>
					</span>
				</DropdownToggle>
				<DropdownMenu className='dropdown-menu-end'>
					<h6 className='dropdown-header'>
						Welcome {fullName ? fullName : ''}!
					</h6>
					<div className='dropdown-divider'></div>

					<DropdownItem href='/settings'>
						<i className='mdi mdi-cog-outline text-muted fs-16 align-middle me-1'></i>{' '}
						<span className='align-middle'>Ayarlar</span>
					</DropdownItem>

					<DropdownItem onClick={() => history.push('/logout')}>
						<i className='mdi mdi-logout text-muted fs-16 align-middle me-1'></i>{' '}
						<span className='align-middle' data-key='t-logout'>
							Çıkış Yap
						</span>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</React.Fragment>
	);
};

export default ProfileDropdown;
