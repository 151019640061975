import { createSlice } from '@reduxjs/toolkit';

export const companiesSlice = createSlice({
	name: 'companies',
	initialState: {
		companies: []
	},
	reducers: {
		setCompanies(state, action) {
			state.companies = action.payload.companies;
		},
		addCompany(state, action) {
			state.companies.push(action.payload.company);
		},
		updateCompany(state, action) {
			const index = state.companies.findIndex(
				(company) => company.id === action.payload.company.id
			);
			state.companies[index] = action.payload.company;
		},
		deleteCompany(state, action) {
			const index = state.companies.findIndex(
				(company) => company.id === action.payload.id
			);
			state.companies.splice(index, 1);
		}
	}
});

export const { setCompanies, addCompany, updateCompany, deleteCompany } =
	companiesSlice.actions;

export default companiesSlice.reducer;
