//User Images
import avatar1 from '../../../assets/images/users/avatar-1.jpg';
import profileBg from '../../../assets/images/profile-bg.jpg';
import smallImg from '../../../assets/images/img-2.jpg';

const general = [
	{
		id: 1,
		company: 'Kılıç Balık',
		name: 'Berkman Adbulan',
		avatar: avatar1,
		coverBg: profileBg,
		about:
			'Hahn Online is the online shopping site for premium outdoor, survival, emergency and sporting goods. Hahn Online offers various products such as airguns, knives, multi-tools, flashlights, headlamps, shooting and personal safety items. We offer high quality brands like Leatherman, Ledlenser, Gamo, Hatsan, Bushnell, Sabre and more. We provide convenient and effortless online shopping with various payment methods.Hahn Online is operated by Willi Hahn Enterprises. A spin-off from Hahn-Manila, a family business which, for fifty years, served as the sole distributor of fine German cutlery, BMW Cars and Motorcycles in the Philippines. To cater to the growing needs of the Filipino outdoor & sports enthusiasts, Willi and Marian Hahn established Willi Hahn Enterprises (WHE) in 1978 and started its retail operations with two outlets: one at Shoppesville in Greenhills, San Juan, and another at Ali Mall in Cubao, Quezon City. The company sought out a whole line of sporting products from which they had envisioned. From German cutlery and local knives, they now hold exclusive distributorship of several well-known brands.',
		shipping: 358,
		process: 23,
		url: 'www.wiha.solutions'
	}
];

const overview = [
	{
		exporter: {
			name: 'Willi Hahn Enterprises',
			phone: '+90 232 323 35 56',
			email: 'info@willihahn.com',
			location: 'Türkiye, Istanbul',
			membershipDate: '22 Mart 2020'
		},
		importer: {
			name: 'Bahtiyar Av Malzemeleri',
			phone: '+90 232 323 35 56',
			email: 'info@bahtiyarav.com',
			location: 'Türkiye, İzmir',
			membershipDate: '13 Ocak 2017'
		}
	}
];

const activities = [
	{
		id: 1,
		name: 'Cansu Yemez',
		status: 'Status 1',
		text: 'Sevkiyatın durumunu "Yeni" den "İşleniyor" a getirdi',
		date: 'Bugün',
		avatar: avatar1,
		attachments: [smallImg, smallImg, smallImg, smallImg, smallImg]
	},
	{
		id: 2,
		name: 'Yeliz Aydın',
		status: 'Status 2',
		text: 'İç Poşet sorusuna istinaden aşağıdaki görselleri ekledi',
		date: '25 Nov',
		img: '',
		attachments: []
	}
];

const projects = [
	{
		id: 1,
		title: 'Chat App Update',
		updatedTime: '2 year Ago',
		badgeText: 'Inprogress',
		badgeClass: 'warning',
		member: [
			{
				id: 1,
				img: avatar1
			},
			{
				id: 2,
				img: avatar1
			}
		],
		cardBorderColor: 'warning',
		memberName: [
			{
				id: 1,
				memberText: 'J'
			}
		]
	},
	{
		id: 2,
		title: 'ABC Project Customization',
		updatedTime: '2 month Ago',
		badgeText: 'Progress',
		badgeClass: 'primary',
		member: [
			{
				id: 1,
				img: avatar1
			},
			{
				id: 2,
				img: avatar1
			},
			{
				id: 3,
				img: avatar1
			}
		],
		cardBorderColor: 'success',
		memberName: [
			{
				id: 1,
				memberText: '2+'
			}
		]
	},
	{
		id: 3,
		title: 'Client - Frank Hook',
		updatedTime: '1 hr Ago',
		badgeText: 'New',
		badgeClass: 'info',
		member: [
			{
				id: 1,
				img: avatar1
			},
			{
				id: 2,
				img: avatar1
			}
		],
		cardBorderColor: 'info',
		memberName: [
			{
				id: 1,
				memberText: 'M'
			}
		]
	},
	{
		id: 4,
		title: 'Velzon Project',
		updatedTime: '11 hr Ago',
		badgeText: 'Completed',
		badgeClass: 'success',
		member: [
			{
				id: 1,
				img: avatar1
			},
			{
				id: 2,
				img: avatar1
			}
		],
		cardBorderColor: 'primary'
	},
	{
		id: 5,
		title: 'Brand Logo Design',
		updatedTime: '10 min Ago',
		badgeText: 'New',
		badgeClass: 'info',
		member: [
			{
				id: 1,
				img: avatar1
			},
			{
				id: 2,
				img: avatar1
			}
		],
		cardBorderColor: 'danger',
		memberName: [
			{
				id: 1,
				memberText: 'E'
			}
		]
	},
	{
		id: 6,
		title: 'Chat App',
		updatedTime: '8 hr Ago',
		badgeText: 'Inprogress',
		badgeClass: 'warning',
		member: [
			{
				id: 1,
				img: avatar1
			},
			{
				id: 2,
				img: avatar1
			}
		],
		memberName: [
			{
				id: 1,
				memberText: 'R'
			}
		],
		cardBorderColor: 'primary'
	},
	{
		id: 7,
		title: 'Project Update',
		updatedTime: '48 min Ago',
		badgeText: 'Inprogress',
		badgeClass: 'warning',
		member: [
			{
				id: 1,
				img: avatar1
			},
			{
				id: 2,
				img: avatar1
			},
			{
				id: 3,
				img: avatar1
			}
		],
		cardBorderColor: 'warning'
	},
	{
		id: 8,
		title: 'Client - Jennifer',
		updatedTime: '30 min Ago',
		badgeText: 'Process',
		badgeClass: 'primary',
		member: [
			{
				id: 1,
				img: avatar1
			}
		],
		cardBorderColor: 'success'
	},
	{
		id: 9,
		title: 'Business Template -UI/UX design',
		updatedTime: '7 month Ago',
		badgeText: 'Completed',
		badgeClass: 'success',
		member: [
			{
				id: 1,
				img: avatar1
			},
			{
				id: 2,
				img: avatar1
			},
			{
				id: 3,
				img: avatar1
			}
		],
		cardBorderColor: 'info',
		memberName: [
			{
				id: 1,
				memberText: '2+'
			}
		]
	},
	{
		id: 10,
		title: 'Update Project',
		updatedTime: '1 month Ago',
		badgeText: 'New',
		badgeClass: 'info',
		member: [
			{
				id: 1,
				img: avatar1
			}
		],
		memberName: [
			{
				id: 1,
				memberText: 'A'
			}
		],
		cardBorderColor: 'success'
	},
	{
		id: 11,
		title: 'Bank Management System',
		updatedTime: '10 month Ago',
		badgeText: 'Completed',
		badgeClass: 'success',
		member: [
			{
				id: 1,
				img: avatar1
			},
			{
				id: 2,
				img: avatar1
			},
			{
				id: 3,
				img: avatar1
			}
		],
		cardBorderColor: 'danger',
		memberName: [
			{
				id: 1,
				memberText: '2+'
			}
		]
	},
	{
		id: 12,
		title: 'PSD to HTML Convert',
		updatedTime: '29 min Ago',
		badgeText: 'New',
		badgeClass: 'info',
		member: [
			{
				id: 1,
				img: avatar1
			}
		],
		cardBorderColor: 'primary'
	}
];

const document = [
	{
		id: 1,
		icon: 'ri-file-zip-fill',
		iconBackgroundClass: 'primary',
		fileName: 'Artboard-documents.zip',
		fileType: 'Zip File',
		fileSize: '4.57 MB',
		updatedDate: '12 Dec 2021'
	},
	{
		id: 2,
		icon: 'ri-file-pdf-fill',
		iconBackgroundClass: 'danger',
		fileName: 'Bank Management System',
		fileType: 'PDF File',
		fileSize: '8.89 MB',
		updatedDate: '24 Nov 2021'
	},
	{
		id: 3,
		icon: 'ri-video-line',
		iconBackgroundClass: 'secondary',
		fileName: 'Tour-video.mp4',
		fileType: 'MP4 File',
		fileSize: '14.62 MB',
		updatedDate: '19 Nov 2021'
	},
	{
		id: 4,
		icon: 'ri-file-excel-fill',
		iconBackgroundClass: 'secondary',
		fileName: 'Tour-video.mp4',
		fileType: 'XSL File',
		fileSize: '2.38 KB',
		updatedDate: '14 Nov 2021'
	},
	{
		id: 5,
		icon: 'ri-folder-line',
		iconBackgroundClass: 'info',
		fileName: 'Project Screenshots Collection',
		fileType: 'Folder File',
		fileSize: '87.24 MB',
		updatedDate: '08 Nov 2021'
	},
	{
		id: 6,
		icon: 'ri-image-2-fill',
		iconBackgroundClass: 'danger',
		fileName: 'Velzon-logo.png',
		fileType: 'PNG File',
		fileSize: '879 KB',
		updatedDate: '02 Nov 2021'
	}
];

export { general, activities, overview, projects, document };
