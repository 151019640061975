import React, { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Alert,
	ModalFooter
} from 'reactstrap';

import {
	useSetModuleStatusMutation,
	useCreateModuleMutation,
	useGetModulesByIdQuery,
	useGetAllPermissionsQuery,
	useGetAllRoleQuery
} from '../../../store/api/roles';

import { toast } from 'react-hot-toast';

import LanguagesTab from '../../Common/MultiLanguageTab';
import Select from 'react-select';
import { useSelector } from 'react-redux';

export const AddModuleModal = ({ addModal, setAddModal, isEdit, dataId }) => {
	//Api calls - start

	const { data: permissionsData, isSuccess: permissionsSuccess } =
		useGetAllPermissionsQuery({ skip: addModal === false });

	const [createPermission, { isError: isRoleError, isSuccess: isRoleSuccess }] =
		useCreateModuleMutation();

	const { data: getDataFromId, isSuccess } = useGetModulesByIdQuery(
		{ id: dataId },
		{ skip: !isEdit }
	);

	const [updatePermission] = useSetModuleStatusMutation(
		{
			id: dataId
		},
		{
			skip: !isEdit && !dataId
		},
		{
			onSuccess: () => {
				toast.success('İzin başarıyla güncellendi');
			},
			onError: () => {
				toast.error('İzin güncellenirken bir hata oluştu');
			}
		}
	);

	//Api calls - end

	//Language - start
	const languageID = useSelector((state) => state.authSlice.menuLang);
	//Language - end

	const [selectedPermissions, setSelectedPermissions] = useState([]);
	const [checked, setChecked] = useState(true);
	const [roles, setRoles] = useState({
		key: '',
		translations: [],
		permissions: []
	});

	const [options, setOptions] = useState();

	useEffect(() => {
		if (permissionsSuccess) {
			setOptions(permissionsData.data);
		}
	}, [permissionsSuccess, permissionsData]);

	const [selectedOption, setSelectedOption] = useState();

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (isEdit) {
			updatePermission({
				id: dataId,
				data: {
					...roles,
					permissions: selectedPermissions
				}
			});

			setAddModal(false);
		} else {
			createPermission({
				...roles,
				permissions: selectedPermissions
			});
			setAddModal(false);
		}
	};

	const handleSwitch = (e) => {
		setChecked(e.target.checked);
	};

	const handleRoleChange = (e, language) => {
		setRoles({
			...roles,
			translations: {
				...roles.translations,
				[language.id]: {
					...roles.translations[language.id],
					name: e.target.value
				}
			}
		});
	};

	useEffect(() => {
		if (isRoleSuccess) {
			toast.success('İzin başarıyla oluşturuldu.');
		} else if (isRoleError) {
			toast.error('İzin oluşturulurken bir hata oluştu.');
		}
	}, [isRoleSuccess, isRoleError]);

	const handleSelectChange = (e) => {
		setSelectedPermissions(e.map((item) => item.value));
	};

	return (
		<Modal
			id='RoleModal'
			isOpen={addModal}
			toggle={() => setAddModal((prevState) => !prevState)}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
			size='lg'
		>
			<ModalHeader toggle={() => setAddModal(false)} className='p-3 bg-white'>
				{isEdit ? 'Modülü Düzenle' : 'Modül Oluştur'}
			</ModalHeader>
			<ModalBody>
				<LanguagesTab
					roles={roles}
					setRoles={setRoles}
					handleRoleChange={handleRoleChange}
					fetchedData={getDataFromId}
					fetchedDataSuccess={isSuccess}
				/>
				<Form onSubmit={(e) => handleFormSubmit(e)}>
					<Row>
						<Col md={12}>
							<label>İzin Adı</label>
							<Select
								className='mb-4'
								placeholder='İzin Seçiniz'
								isMulti
								onChange={(e) => handleSelectChange(e)}
								options={options}
								// value={isEdit ? dataFromSelectedOption : null}

								getOptionLabel={(option) => option.translation.name}
								getOptionValue={(option) => option.value}

								// defaultValue={isEdit ? dataFromSelectedOption : null}
							/>
						</Col>

						<Col md={12}>
							<label>Durumu</label>
							<div className='mb-4 form-check form-switch mb-2'>
								<Input
									className='form-check-input'
									type='checkbox'
									role='switch'
									id='flexSwitchCheckDefault'
									name='isActive'
									onChange={(e) => handleSwitch(e)}
									checked={checked}
								/>

								<label
									className='form-check-label'
									htmlFor='flexSwitchCheckDefault'
								>
									{checked ? 'Aktif' : 'Pasif'}
								</label>
							</div>
						</Col>
					</Row>

					<ModalFooter className='p-3 bg-white'>
						<Button color='primary' type='submit'>
							{isEdit ? 'Düzenle' : 'Oluştur'}
						</Button>
					</ModalFooter>
				</Form>
			</ModalBody>
		</Modal>
	);
};

export default AddModuleModal;
