import React from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import ReactApexChart from 'react-apexcharts';
import ShipmentChart from './ShipmentChart';
import ShipmentsByCountry from './ShipmentsByCountry';
import CompaniesByShipments from './CompaniesByShipments';

const DashboardCrm = () => {
	document.title = 'Sevkiyat Raporları | Yeditepe Go';
	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<BreadCrumb title='Sevkiyat Raporları' pageTitle='Sevkiyatlar' />
					<Row>
						<Col lg={7}>
							<ShipmentChart />
						</Col>
						<Col lg={5}>
							<CompaniesByShipments />
						</Col>
					</Row>
					<Row>
						<Col lg={6}>
							<ShipmentsByCountry />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default DashboardCrm;
