import React, { useState } from 'react';
import {
	Button,
	Col,
	Input,
	Label,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane
} from 'reactstrap';
import classnames from 'classnames';
import LanguageTab from '../../../pages/Settings/LanguageTab';
import { useUpdateUserMutation } from '../../../store/api/user';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
const Tab = ({ children, activeTab, label }) => {
	// State for current active Tab
	const [currentActiveTab, setCurrentActiveTab] = useState('1');

	// Toggle active state for Tab
	const toggle = (tab) => {
		if (currentActiveTab !== tab) setCurrentActiveTab(tab);
	};

	const [updateUser, { isLoading, isSuccess, isError, error }] =
		useUpdateUserMutation();

	const { id } = useSelector((state) => state.authSlice.user.name);

	const [form, setForm] = useState({
		name: '',
		surname: '',
		password: '',
		password_confirmation: ''
	});

	const handleSubmit = (e) => {
		e.preventDefault();

		if (form.password !== form.password_confirmation) {
			toast.error('Şifreler uyuşmuyor');
			return;
		}

		updateUser({
			id: id,
			data: {
				...form
			}
		});
	};

	return (
		<div>
			<Nav tabs className='nav-custom-outline'>
				<NavItem>
					<NavLink
						className={classnames(
							{
								active: currentActiveTab === '1'
							},
							'tab-link'
						)}
						onClick={() => {
							toggle('1');
						}}
					>
						Profil Ayarları
					</NavLink>
				</NavItem>

				{/* <NavItem>
					<NavLink
						className={classnames(
							{
								active: currentActiveTab === '2'
							},
							'tab-link'
						)}
						onClick={() => {
							toggle('2');
						}}
					>
						Dil Ayarları
					</NavLink>
				</NavItem> */}
			</Nav>
			<TabContent activeTab={currentActiveTab}>
				<TabPane tabId='1' className='tab-content p-3 bg-white mt-4'>
					<Row>
						<Col sm='6' className='mb-4'>
							<Label for='name'>Adınız</Label>
							<Input
								type='text'
								name='name'
								id='name'
								placeholder='John'
								onChange={(e) => {
									setForm({ ...form, name: e.target.value });
								}}
							/>
						</Col>{' '}
						<Col sm='6' className='mb-4'>
							<Label for='surname'>Soyadınız</Label>
							<Input
								type='text'
								name='surname'
								id='surname'
								placeholder='Doe'
								onChange={(e) => {
									setForm({ ...form, surname: e.target.value });
								}}
							/>
						</Col>
						<Col sm='6' className='mb-4'>
							<Label for='email'>E-posta Adresiniz</Label>
							<Input
								type='email'
								name='email'
								id='email'
								placeholder='email'
								disabled
							/>
						</Col>
						<Col sm='6' className='mb-4'>
							<Label for='phone'>Telefon Numaranız</Label>
							<Input
								type='text'
								name='phone'
								id='phone'
								placeholder='phone'
								disabled
							/>
						</Col>
						<Col sm='6' className='mb-4'>
							<Label for='password'>Şifreniz</Label>
							<Input
								type='password'
								name='password'
								id='password'
								placeholder='Şifre'
								onChange={(e) => {
									setForm({ ...form, password: e.target.value });
								}}
							/>
						</Col>
						<Col sm='6' className='mb-4'>
							<Label for='confirmPassword'>Şifreniz (Yeniden)</Label>
							<Input
								type='password'
								name='confirmPassword'
								id='confirmPassword'
								placeholder='Şifre (Yeniden)'
								onChange={(e) => {
									setForm({
										...form,
										password_confirmation: e.target.value
									});
								}}
							/>
						</Col>
						<Col sm='6' className='mb-4'>
							<Label for='company'>Şirketiniz</Label>
							<Input
								type='text'
								name='company'
								id='company'
								placeholder='Yeditepe Lojistik'
								disabled
							/>
						</Col>
						<Col sm='6' className='mb-4'>
							<Label for='title'>Ünvan</Label>
							<Input
								type='text'
								name='title'
								id='title'
								placeholder='Lojistik Sorumlusu'
								disabled
							/>
						</Col>
						<Button
							color='primary'
							className='btn btn-primary btn-block'
							onClick={handleSubmit}
						>
							Kaydet
						</Button>
					</Row>
				</TabPane>

				{/* <TabPane tabId='2' className='tab-content bg-white mt-4'>
					<Row>
						<LanguageTab />
					</Row>
				</TabPane> */}
			</TabContent>
		</div>
	);
};

export default Tab;
