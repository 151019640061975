import React from 'react';
import {
	Col,
	Form,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Row
} from 'reactstrap';
import FormInput from '../form/Input';
import { useGetCompaniesByIdQuery } from '../../../store/api/companies';
import defaultNoImage from '../../../assets/images/default-no-image.png';

export const AddCompanyModal = ({
	switchStatus,
	validation,
	addModal,
	handleFile,
	isEdit,
	visibility,
	setSwitchStatus,
	imageUploader,
	setAddModal,
	setVisibility,
	preview,
	id,
	dataId
}) => {
	const {
		data: getImageByIdData,
		isLoading,
		error,
		isSuccess
	} = useGetCompaniesByIdQuery(
		{ id: dataId?.id },
		{ skip: !isEdit && !addModal }
	);

	const [image, setImage] = React.useState(null);
	const [img, setImg] = React.useState(null);

	React.useEffect(() => {
		if (isSuccess) {
			setImage(getImageByIdData.data?.media?.url);
		}
	}, [isSuccess, getImageByIdData]);

	return (
		<Modal
			id={id}
			isOpen={addModal}
			toggle={() => setAddModal((prevState) => !prevState)}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
		>
			<ModalHeader toggle={() => setAddModal(false)} className='p-3 bg-white'>
				{isEdit ? 'Şirket Düzenle' : 'Şirket Ekle'}
			</ModalHeader>
			<ModalBody>
				<Form
					id='creatname-form'
					onSubmit={(e) => {
						e.preventDefault();
						validation.handleSubmit();
					}}
				>
					<div
						onMouseEnter={() => setVisibility(true)}
						onMouseLeave={() => setVisibility(false)}
						className='d-flex align-items-center justify-content-between'
					>
						<div className='position-relative mx-auto'>
							<img
								src={image ? image : preview ? preview : defaultNoImage}
								className='rounded-circle shadow-4 mx-auto'
								style={{ width: '100px', height: '100px', objectFit: 'cover' }}
								alt='Avatar'
								onClick={() => imageUploader.current.click()}
							/>

							{visibility && (
								<div
									style={{
										width: '40px',
										height: '40px'
									}}
									className='d-flex flex-column align-items-center justify-content-center position-absolute bottom-0 end-0 bg-white rounded-circle p-1 '
								>
									<i className='ri-pencil-line ri-2x'></i>
								</div>
							)}
						</div>

						<input
							type='file'
							accept='JPG, JPEG, PNG'
							onChange={handleFile}
							ref={imageUploader}
							className='d-none'
						/>
					</div>

					<input type='hidden' id='nameid-input' className='form-control' />
					<div className='mb-3'>
						<FormInput
							label='Şirket Adı'
							name='name'
							type='text'
							validation={validation}
							placeholder='Şirket Adı'
						/>
					</div>

					<Row className='g-4 mb-3'>
						<Col md={6}>
							<FormInput
								text='Kod'
								name='code'
								placeholder='Kod'
								validation={validation}
							/>
						</Col>

						<Col lg={6}>
							<FormInput
								text='Kısa Ad'
								name='short_name'
								placeholder='Kısa Ad'
								validation={validation}
							/>
						</Col>
					</Row>
					<div className='mb-4'>
						<FormInput
							text='Açıklama'
							name='description'
							placeholder='Açıklama'
							validation={validation}
							type='textarea'
							id='description-field'
						/>
					</div>

					<div className='mb-4 form-check form-switch mb-2'>
						<Input
							className='form-check-input'
							type='checkbox'
							role='switch'
							id='flexSwitchCheckDefault'
							name='isActive'
							onChange={(e) => setSwitchStatus(e.target.checked)}
							checked={switchStatus}
						/>
						<label
							className='form-check-label'
							htmlFor='flexSwitchCheckDefault'
						>
							{switchStatus ? 'Aktif' : 'Pasif'}
						</label>
					</div>

					<div className='hstack gap-2 justify-content-start'>
						<button type='submit' className='btn btn-primary' id='addNewTodo'>
							{isEdit ? 'Düzenle' : 'Yeni Şirket Ekle'}
						</button>

						<button
							type='button'
							className='btn btn-outline-primary'
							onClick={() => setAddModal(false)}
						>
							<i className='ri-close-fill align-bottom'></i> Kapat
						</button>
					</div>
				</Form>
			</ModalBody>
		</Modal>
	);
};
export default AddCompanyModal;
