import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import {
	Badge,
	Button,
	Input,
	Modal,
	ModalHeader,
	Spinner,
	Table
} from 'reactstrap';
import PaginationComponent from '../../Common/Pagination';
import useDebounce from '../../../helpers/useDebounceInput';
import moment from 'moment';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CitiesModal from '../modals/CitiesModal';

export const TableComponent = ({
	columnData,
	data,
	handleEdit,
	openDeleteModal,
	isLoading,
	handleView,
	search,
	setSearch,
	handleAdd,
	filter,
	limit,
	handlePageChange,
	setPage,
	page,
	pagination,
	setLimit,
	addData,
	refetch,
	status,
	setDebounceSearch,
	showStatus,
	modalData,
	addButtonText,
	languageId,
	handleShowModal,
	handleMatch,
	handleEditAirport,
	toggleMatchModal,
	handleGoToDetail,
	hasDetail
}) => {
	const debouncedSearchTerm = useDebounce(search, 500);

	const changeLimit = (e) => {
		setLimit(e.value);
	};

	const totalPages = pagination?.totalPages;

	const handleSearch = (e) => {
		e.preventDefault();
		setSearch(e.target.value);
	};

	const clearInput = () => {
		setSearch('');
	};

	const [cityModal, setCityModal] = useState(false);

	useEffect(() => {
		if (debouncedSearchTerm) {
			setDebounceSearch && setDebounceSearch(debouncedSearchTerm);
		} else {
			setDebounceSearch && setDebounceSearch('');
		}
	}, [debouncedSearchTerm, setDebounceSearch]);

	const [view, setView] = useState(false);

	const history = useHistory();

	const showModal = () => {
		setCityModal(true);
	};

	return (
		<div className='table-wrapper'>
			{isLoading ? (
				<>
					<div className='d-flex justify-content-center align-items-center'>
						<Spinner color='primary' />
					</div>
				</>
			) : (
				filter && (
					<>
						<div className='table-filter'>
							<div className='d-flex position-relative mb-0'>
								<Input
									type='text'
									name='search'
									placeholder='Ara...'
									value={search}
									onChange={handleSearch}
									className='table-search w-100'
									minLength='2'
								/>
								{search.length > 0 && (
									<Button
										className={`position-absolute top-50 end-0 translate-middle-y btn-soft-primary  btn-icon-end btn-search shadow-none hover-text-white border-0 h-full`}
										onClick={(e) => clearInput(e)}
									>
										<i className='bx bx-trash'></i>
									</Button>
								)}
							</div>

							{addData && (
								<Button
									color='primary'
									className='d-flex align-items-center g-x-2'
									onClick={() => handleAdd()}
								>
									<i className='ri-add-line me-1'></i>
									{addButtonText ? addButtonText : 'Ekle'}
								</Button>
							)}
						</div>

						<Table striped bordered hover className='table'>
							<thead className='table-head'>
								<tr>
									{columnData.map((item, index) => (
										<th
											className={hasDetail && index === 0 && 'col-9'}
											key={item.id}
										>
											{item.name}
										</th>
									))}
								</tr>
							</thead>
							<tbody className='table-body h-100'>
								{isLoading ? (
									<div className='flex justify-content-center align-items-center w-100 h-100'>
										<Spinner color='primary' />
									</div>
								) : (
									data?.map((item, index) => (
										<React.Fragment key={index}>
											<tr>
												{columnData.map((columnItem, index) => {
													if (columnItem.value === 'actions') {
														return (
															<td
																className='table-td table--action'
																key={index}
															>
																{handleMatch && (
																	<>
																		<Button
																			color='success'
																			size='sm'
																			onClick={() => handleMatch(item)}
																			className='d-flex align-items-center'
																		>
																			<i className='ri-checkbox-circle-line mx-1'></i>
																			Eşleştir
																		</Button>
																	</>
																)}
																{handleEdit && (
																	<Button
																		color='primary'
																		size='sm'
																		id='edit'
																		onClick={() => handleEdit(item)}
																		className='d-flex align-items-center'
																	>
																		<i className='ri-pencil-line  mx-1'></i>
																		Düzenle
																	</Button>
																)}
																{handleEditAirport && (
																	<Button
																		color='success'
																		size='sm'
																		onClick={() => handleEditAirport(item)}
																		className='d-flex align-items-center'
																	>
																		<i className='ri-flight-takeoff-line mx-1'></i>
																		Havaalanı Ayarları
																	</Button>
																)}
																{handleView && (
																	<Button color='info' size='sm'>
																		<i className='ri-eye-line'></i>
																	</Button>
																)}
																{openDeleteModal && (
																	<Button
																		color='danger'
																		size='sm'
																		onClick={() => openDeleteModal(item.id)}
																		className='d-flex align-items-center'
																	>
																		<i className='ri-delete-bin-line mx-1'></i>
																		Sil
																	</Button>
																)}
																{handleGoToDetail && (
																	<Button
																		color='primary'
																		size='sm'
																		onClick={() => handleGoToDetail(item)}
																		className='d-flex align-items-center'
																	>
																		<i className='ri-arrow-right-line mx-1'></i>
																		Git
																	</Button>
																)}
															</td>
														);
													}
													if (columnItem.value === 'num_code') {
														return (
															<td key={index}>
																<img
																	src={
																		'../../../assets/images/logos/' +
																		item?.num_code +
																		'.svg'
																	}
																	alt=''
																	height='30'
																	style={{ borderRadius: '50%' }}
																/>
															</td>
														);
													}

													if (columnItem.value === 'carrier.num_code') {
														return (
															<td key={index}>
																<img
																	src={
																		'../../../assets/images/logos/' +
																		item?.carrier?.num_code +
																		'.svg'
																	}
																	alt=''
																	height='30'
																	style={{ borderRadius: '50%' }}
																/>
															</td>
														);
													}
													if (columnItem.value === 'carrier.name') {
														return <td key={index}>{item?.carrier?.name}</td>;
													}
													if (columnItem.value === 'product_code.name') {
														return (
															<td key={index}>{item?.product_code?.name}</td>
														);
													}

													if (columnItem.value === 'companyName') {
														return (
															<td key={index}>
																{item?.company?.name}{' '}
																{item.type == 0 && (
																	<span className='badge bg-danger'>R</span>
																)}
															</td>
														);
													}

													if (columnItem.value === 'rolesName') {
														return (
															<td key={index}>{item?.translation?.name}</td>
														);
													}

													if (columnItem.value === 'questionName') {
														return (
															<td key={index}>
																{
																	item.translations.find(
																		(x) => x.language_id === languageId
																	)?.name
																}
															</td>
														);
													}
													if (columnItem.value === 'company.name') {
														return (
															<td key={index}>
																{item.company?.name}{' '}
																{item.type == 0 && (
																	<span className='badge bg-danger'>R</span>
																)}
															</td>
														);
													}

													if (columnItem.value === 'roles') {
														return (
															<td key={index}>
																{item?.role?.translation.name}
															</td>
														);
													}

													if (columnItem.value === 'translation') {
														return (
															<td key={index}>{item?.translation?.name}</td>
														);
													}

													if (columnItem.value === 'status' && !showStatus) {
														return (
															<td key={index}>
																<Badge
																	color={
																		item.status === 1 ? 'success' : 'danger'
																	}
																>
																	{item.status === 1 ? 'Aktif' : 'Pasif'}
																</Badge>
															</td>
														);
													}

													if (columnItem.value === 'details') {
														return (
															<td key={index}>
																<Button
																	color='info'
																	size='sm'
																	className='d-flex gap-1'
																	onClick={() => {
																		history.push(
																			`${columnItem.detailLink}/${item.id}`
																		);
																	}}
																>
																	<i className='ri-eye-line'></i>
																	Görüntüle
																</Button>
															</td>
														);
													}

													if (columnItem.value === 'company') {
														return (
															<td key={index}>
																{item.company.name}{' '}
																{item.type == 0 && (
																	<span className='badge bg-danger'>R</span>
																)}
															</td>
														);
													}
													if (columnItem.value === 'carrier') {
														return <td key={index}>{item.carrier.name}</td>;
													}

													if (columnItem.value === 'status') {
														return (
															<td key={index}>
																<SwitchInput
																	item={item}
																	setPage={setPage}
																	page={page}
																	value={item.status}
																	status={status}
																	refetch={refetch}
																/>
															</td>
														);
													}

													if (columnItem.value === 'action') {
														return (
															<td key={index}>
																{item.action === 'CREATED' && (
																	<span className='badge bg-success'>
																		Oluşturuldu
																	</span>
																)}
																{item.action === 'DELETED' && (
																	<span className='badge bg-danger'>
																		Silindi
																	</span>
																)}
																{item.action === 'UPDATED' && (
																	<span className='badge bg-warning'>
																		Güncellendi
																	</span>
																)}
															</td>
														);
													}

													if (columnItem.value === 'option_date') {
														return (
															<td key={index}>
																{moment(item.option_date).format('DD.MM.YYYY')}
															</td>
														);
													}

													if (columnItem.value === 'created_at') {
														return (
															<td key={index}>
																{moment(item.created_at).format(
																	'DD.MM.YYYY HH:mm'
																)}
															</td>
														);
													}

													if (columnItem.value === 'user_id') {
														return (
															<td key={index}>
																{item.user_id
																	? item.users
																		? item.users.email
																		: item.user_id
																	: 'Sistem'}
															</td>
														);
													}
													if (columnItem.value === 'view') {
														return (
															<td key={index}>
																<ViewButton item={item} />
															</td>
														);
													}

													if (columnItem.value === 'shipment_detail') {
														return (
															<td key={index}>
																<ShipmentListButton item={item} />

																{/* <div className='d-flex h-100'>
																	{item.air_shipments.map((item, index) => {
																		return (
																			<div className='mx-1' key={index}>
																				<Badge
																					className='badge'
																					color='primary'
																					style={{
																						cursor: 'pointer'
																					}}
																					onClick={() => {
																						history.push(
																							`/shipments/detail/${item.id}`
																						);
																					}}
																				>
																					{item.hawb_code ? (
																						<>
																							<span className='badge-dot'>
																								<i className='bx bx bxs-truck me-1' />
																							</span>
																							{item.hawb_code}
																						</>
																					) : (
																						<>
																							<span className='badge-dot'>
																								<i className='bx bx bxs-plane me-1' />
																							</span>
																							{item.mawb_code}
																						</>
																					)}
																				</Badge>
																			</div>
																		);
																	})}
																</div> */}
															</td>
														);
													}

													if (columnItem.value === 'cities') {
														return (
															<CityModal
																item={item}
																setCityModal={setCityModal}
																key={index}
																cityModal={cityModal}
																refetch={refetch}
															/>
														);
													}

													return <td key={index}>{item[columnItem.value]}</td>;
												})}
											</tr>
											{view && (
												<tr>
													<td>
														<div className='d-flex justify-content-between'>
															{item.new_data}
														</div>
													</td>
												</tr>
											)}

											{cityModal === item.id && (
												<CitiesModal
													item={item}
													setCityModal={setCityModal}
													cityModal={cityModal}
													refetch={refetch}
												/>
											)}
										</React.Fragment>
									))
								)}
							</tbody>
						</Table>
						<div className='table--footer'>
							{limit && (
								<Select
									className='table-select'
									placeholder={limit + ' kayıt göster'}
									onChange={changeLimit}
									options={[
										{ value: '1', label: '1' },
										{ value: '10', label: '10' },
										{ value: '20', label: '20' },
										{ value: '50', label: '50' },
										{ value: '100', label: '100' }
									]}
								/>
							)}

							{totalPages && (
								<PaginationComponent
									handlePageChange={handlePageChange}
									page={page}
									setPage={setPage}
									totalPages={totalPages}
									limit={limit}
									total={pagination.total}
								/>
							)}
						</div>
					</>
				)
			)}
		</div>
	);
};

const ViewButton = ({ item }) => {
	const [viewDetails, setViewDetails] = useState(false);

	const toggleViewDetails = () => {
		setViewDetails((prevState) => !prevState);
	};

	const newData = item.new_data && JSON.parse(item.new_data);
	const oldData = item.old_data && JSON.parse(item.old_data);

	return (
		<>
			<Button
				className='btn'
				color='primary'
				type='button'
				onClick={toggleViewDetails}
			>
				<i className='ri-eye-line' />
			</Button>

			<Modal
				className='modal-dialog-centered'
				isOpen={viewDetails}
				toggle={toggleViewDetails}
			>
				<ModalHeader toggle={toggleViewDetails}>Detaylar</ModalHeader>
				<div className='modal-body'>
					<div className=''>
						{item.message && (
							<>
								<h6 className='heading-small text-muted mb-4'>Açıklama</h6>
								<p>{item.message}</p>
							</>
						)}

						{oldData && (
							<div className='mt-4'>
								<h6 className='heading-small text-muted mb-4'>Yeni Veri</h6>

								<pre
									style={{
										backgroundColor: '#f6f8fa',
										borderRadius: '5px',
										padding: '12px',
										whiteSpace: 'pre-wrap',
										fontSize: '12px'
									}}
								>
									{newData &&
										Object.keys(newData).map((key, index) => (
											<div key={index}>
												{key} : {newData[key]}
											</div>
										))}
								</pre>
							</div>
						)}
						<div className='mt-4'>
							{newData && (
								<>
									<h6 className='heading-small text-muted mb-4'>Yeni Veri</h6>

									<pre
										style={{
											backgroundColor: '#f6f8fa',
											borderRadius: '5px',
											padding: '12px',
											whiteSpace: 'pre-wrap',
											fontSize: '12px'
										}}
									>
										{Object.keys(newData).map((key, index) => (
											<div key={index}>
												{key} : {newData[key]}
											</div>
										))}
									</pre>
								</>
							)}
						</div>
					</div>
				</div>
				<div className='modal-footer'>
					<Button
						className='ml-auto'
						color='primary'
						data-dismiss='modal'
						type='button'
						onClick={toggleViewDetails}
					>
						Kapat
					</Button>
				</div>
			</Modal>
		</>
	);
};

const SwitchInput = ({ value, status, item, setPage, page, refetch }) => {
	const [checked, setChecked] = useState(value === 1 ? true : false);

	const handleSwitch = async (item, e) => {
		setChecked(!checked);

		status({
			id: item.id,
			data: {
				status: checked ? 0 : 1
			}
		});

		setPage(page);
		toast.success('Durum başarıyla güncellendi.');
	};
	return (
		<div className='mb-4 form-check form-switch mb-2'>
			<Input
				className='form-check-input'
				type='checkbox'
				role='switch'
				id={`${item.id}`}
				name='isActive'
				onChange={() => handleSwitch(item)}
				checked={checked}
			/>

			<label className='form-check-label' htmlFor={`${item.id}`}>
				{checked ? 'Aktif' : 'Pasif'}
			</label>
		</div>
	);
};

const CityModal = ({ item, key, setCityModal, cityModal }) => {
	return (
		<>
			<td key={key}>
				<Button
					color='outline-primary'
					className=' btn-sm d-flex align-items-center gap-2'
					onClick={() => {
						setCityModal(cityModal === item.id ? null : item.id);
					}}
				>
					<i className='ri-eye-line'></i>
					Görüntüle
				</Button>
			</td>
		</>
	);
};

const ShipmentListButton = ({ item }) => {
	const [viewDetails, setViewDetails] = useState(false);
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(50);

	const toggleViewDetails = () => {
		setViewDetails((prevState) => !prevState);
	};

	const columnData = [
		{
			id: 1,
			name: 'Taşıyıcı',
			value: 'carrier.num_code'
		},
		{
			id: 3,
			name: 'MAWB',
			value: 'mawb_code'
		},
		{
			id: 2,
			name: 'HAWB',
			value: 'hawb_code'
		},
		{
			id: 4,
			name: 'Firma Adı',
			value: 'company.name'
		},
		{
			id: 5,
			name: 'Ürün Türü',
			value: 'product_code.name'
		},
		{
			id: 6,
			name: 'Kalkış Yeri',
			value: 'port_departure'
		},
		{
			id: 7,
			name: 'Varış Yeri',
			value: 'port_arrival'
		},
		{
			id: 8,
			name: 'Tarih',
			value: 'option_date'
		},
		{
			id: 9,
			name: 'Detaylar',
			value: 'details',
			detailLink: '/shipments/detail'
		}
	];

	return (
		<>
			<Button
				className='btn'
				color='primary'
				type='button'
				onClick={toggleViewDetails}
			>
				<i className='ri-eye-line' /> Sevkiyatları Görüntüle
			</Button>
			<Modal
				className='modal-dialog-centered'
				isOpen={viewDetails}
				toggle={toggleViewDetails}
				size='xl'
			>
				<ModalHeader toggle={toggleViewDetails}>
					Bağlı Olduğu Sevkiyatlar
				</ModalHeader>
				<div className='modal-body'>
					<TableComponent
						data={item.air_shipments}
						columnData={columnData}
						filter='false'
						search={search}
						setSearch={setSearch}
					/>
				</div>
			</Modal>
		</>
	);
};

export default TableComponent;
