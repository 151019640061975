import { createSlice } from '@reduxjs/toolkit';

export const customersSlices = createSlice({
	name: 'customers',
	initialState: {
		count: 0,
		staffs: 0,
		shipments: 0
	},
	reducers: {
		setCustomersCount(state, action) {
			state.count = action.payload.count;
			state.staffs = action.payload.staffs;
			state.shipments = action.payload.shipments;
		}
	}
});

export const { setCustomersCount } = customersSlices.actions;

export default customersSlices.reducer;
