import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout';
import VerticalLayout from '../Layouts/index';
//routes
import { authProtectedRoutes, publicRoutes, customRoutes } from './allRoutes';
import { AuthProtected, AccessRoute } from './AuthProtected';

import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
import { useSelector } from 'react-redux';

import Dashboard from '../pages/Dashboard';
import TwoStepVerification from '../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify';
import { useHistory } from 'react-router-dom';

//Shipments Pages
import ActiveShipments from '../pages/Shipments/ActiveShipments/index';
import CompletedShipments from '../pages/Shipments/ComplatedShipments/index';
import PendingShipments from '../pages/Shipments/PendingShipments/index';
import ReportsShipments from '../pages/Shipments/ShipmentReports/index';

//Vehicles Pages
import ActiveVehicles from '../pages/Vehicles/ActiveVehicles/index';
import CompletedVehicles from '../pages/Vehicles/ComplatedVehicles/index';
import PendingVehicles from '../pages/Vehicles/PendingVehicles/index';
import ReportsVehicles from '../pages/Pages/Starter/Starter';

// Questions Pages
import QuestionList from '../pages/Questions/QuestionList/index';
import QuestionReports from '../pages/Pages/Starter/Starter';

// Users Page
import UsersReports from '../pages/Pages/Starter/Starter';

//Customer Pages
import CustomerList from '../pages/Customers/CustomerList';
import CustomerDetail from '../pages/Pages/Starter/Starter';

//Admin
import ActivityLog from '../pages/Administration/ActiviyLogs';
import Notifications from '../pages/Administration/Notifications';
import SystemLogs from '../pages/Administration/SystemLogs';
import Companies from '../pages/Companies';
import Roles from '../pages/Roles/index.js';
import AirPorts from '../pages/Airports/index.js';
import Seaports from '../pages/Seaports/index.js';
import ShippingParameters from '../pages/ShippingParameters/index.js';
import TransactionsCode from '../pages/TransactionsCodes';
import Collaborations from '../pages/Collaborations';
import CapCodes from '../pages/CapCodes';
import EditRoleModal from '../Components/ui/modals/EditRoleModal';
import Users from '../pages/Users/Users';
const Index = () => {
	const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
	const MenuItem = useSelector((state) => state.authSlice.menu);
	const availableRoutesPaths = MenuItem.map((r) => r.path);

	const customRoutesPath = customRoutes.map((r) => r.path);

	//Route yapılacak sistemdeki componentlerin pathleri
	//Apiden gelen veri string olduğu için Dasboard : Dashboard olarak çeviriyoruz
	const components = {
		Dashboard,
		AirPorts
		// Companies
	};

	const subItemComponents = {
		ActiveShipments,
		CompletedShipments,
		PendingShipments,
		ReportsShipments,
		ActiveVehicles,
		CompletedVehicles,
		PendingVehicles,
		ReportsVehicles,
		QuestionList,
		QuestionReports,
		UsersReports,
		CustomerList,
		CustomerDetail,
		Roles,
		AirPorts,
		Seaports,
		ShippingParameters,
		TransactionsCode,
		Collaborations,
		CapCodes,
		Users
	};

	const url = window.location.pathname;
	const history = useHistory();

	useEffect(() => {
		url === '/' && history.push('/dashboard');
		url === '' && history.push('/dashboard');
	}, [url, history]);

	return (
		<React.Fragment>
			<Switch>
				<Route path={availablePublicRoutesPaths}>
					<NonAuthLayout>
						<Switch>
							{publicRoutes.map((route, idx) => (
								<Route
									path={route.path}
									component={route.component}
									key={idx}
									exact={true}
								/>
							))}
						</Switch>
					</NonAuthLayout>
				</Route>

				<Route path={customRoutesPath}>
					<AuthProtected>
						<VerticalLayout>
							<Switch>
								{customRoutes.map((route, idx) => (
									<AccessRoute
										path={route.path}
										component={route.component}
										key={idx}
										exact={true}
									/>
								))}
							</Switch>
						</VerticalLayout>
					</AuthProtected>
				</Route>

				<Route path={availableRoutesPaths}>
					<AuthProtected>
						<VerticalLayout>
							<Switch>
								{MenuItem.map((route, idx) => (
									<React.Fragment key={idx}>
										<AccessRoute
											path={route.path}
											component={components[route.component]}
											key={idx}
											exact
											subItems={route.subItems}
										/>
									</React.Fragment>
								))}
							</Switch>
						</VerticalLayout>
					</AuthProtected>
				</Route>

				<AuthProtected>
					<VerticalLayout>
						{MenuItem.map(
							(route, idx) =>
								route.subItems &&
								route.subItems.map((subItem, idx) => (
									<Route
										key={idx}
										path={subItem.path}
										component={subItemComponents[subItem.component]}
										exact={true}
									/>
								))
						)}
					</VerticalLayout>
				</AuthProtected>
			</Switch>
		</React.Fragment>
	);
};

export default Index;
