import React, { useEffect, useState } from 'react';
import {
	Badge,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Nav,
	NavItem,
	NavLink,
	Row,
	Spinner,
	TabContent,
	Table,
	TabPane
} from 'reactstrap';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import {
	useGetShipmentDetailQuery,
	useGetShipmentDetailByMawbQuery,
	useRegenerateAirShipmentPdfQuery
} from '../../../store/api/shipments';
import moment from 'moment';

//Images

import detailBg from '../../../assets/images/detail-bg.jpg';
import { useSelector } from 'react-redux';
import Fancybox from '../../../Components/Common/Fancybox';
import { toast } from 'react-hot-toast';

const ShipmentDetail = () => {
	const history = useHistory();
	const { menuLang } = useSelector((state) => state.authSlice);
	const [activeTab, setActiveTab] = useState('1');
	const [shipmentId, setShipmentId] = useState();

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const dataId = history.location.pathname.split('/')[3];
	const hash = history.location.hash;
	const mawb = hash.replace('#', '');

	const {
		data: detail,
		isLoading,
		isFetching,
		isSuccess
	} = useGetShipmentDetailQuery(dataId, {
		skip: !dataId || mawb
	});

	const {
		data: detailByMawb,
		isLoading: isLoadingByMawb,
		isFetching: isFetchingByMawb
	} = useGetShipmentDetailByMawbQuery(mawb, {
		skip: !mawb
	});

	const {
		data: regenerate,
		isLoading: regenerateIsLoading,
		isError: regenerateIsError,
		isFetching: regenerateIsFetching
	} = useRegenerateAirShipmentPdfQuery(shipmentId, {
		skip: shipmentId == undefined
	});

	//remove # from hash

	const [detailData, setDetailData] = useState([]);
	const [data, setData] = useState([]);

	useEffect(() => {
		if (detail) {
			setDetailData(detail.data);
			setData(detail.data.air_shipment);
		}
	}, [detail]);

	useEffect(() => {
		if (detailByMawb) {
			setDetailData(detailByMawb.data);
			setData(detailByMawb);
		}
	}, [detailByMawb]);

	useEffect(() => {
		if (regenerateIsLoading) {
			toast.success('PDF oluşturma işlemi başlatıldı.');
		}
		if (!regenerateIsLoading && regenerate) {
			toast.success('PDF oluşturma işlemi tamamlandı.');
			setShipmentId();
		}
		if (regenerateIsError) {
			toast.error('Yeniden PDF oluşturma işlemi sırasında bir hata oluştu.');
			setShipmentId();
		}
	}, [regenerate, regenerateIsLoading, regenerateIsError]);

	document.title = 'Sevkiyat Detay | Yeditepe Go';

	const getAnswer = (type, answer) => {
		var ans = '';
		if (type == 'Evet/Hayır') {
			if (answer) {
				ans = 'Evet';
			} else {
				ans = 'Hayır';
			}
		}

		if (type == 'Yazı') {
			ans = answer;
		}

		if (type == 'Ondalıklı') {
			ans = answer;
		}

		if (type == 'Tarih') {
			var date = moment(new Date(answer), 'DD.MM.YYYY');
			var month = date.month() + 1;
			if (month < 10) {
				month = '0' + month;
			}
			var hour = date.hour();
			if (hour < 10) {
				hour = '0' + hour;
			}
			var min = date.minute();
			if (min < 10) {
				min = '0' + min;
			}

			ans =
				date.date() + '.' + month + '.' + date.year() + ' ' + hour + ':' + min;
		}

		return ans;
	};

	const openPdf = (url) => {
		window.open(url, '_blank');
	};

	const regeneratePdf = (id) => {
		setShipmentId(id);
	};

	return (
		<React.Fragment>
			{hash ? (
				isLoading || isFetching || !isSuccess
			) : isLoadingByMawb && isFetchingByMawb ? (
				<div className='page-content w-100 h-100 d-flex align-items-center justify-content-center'>
					<Container fluid>
						<Spinner color='primary' />
					</Container>
				</div>
			) : (
				<div className='page-content'>
					<Container fluid>
						<div className='profile-foreground position-relative mx-n4 mt-n4'>
							<div className='profile-wid-bg'>
								<img src={detailBg} alt='' className='profile-wid-img' />
							</div>
						</div>
						<div className='pt-4 mb-4 mb-lg-3 pb-lg-4'>
							<Row className='g-4'>
								<Col>
									<div className='p-2'>
										<h3 className='text-white mb-1'>{data?.customer?.name}</h3>
										<div className='hstack text-white-50 gap-1'>
											<div className='me-2 d-flex align-items-center'>
												<i className='ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle'></i>
												{data?.port_via}-{data?.port_arrival}
											</div>

											<div className='me-2 d-flex align-items-center'>
												<i className='ri-calendar-2-line me-1 text-white-75 fs-16 align-middle'></i>
												{detailData?.air_shipment?.option_date}
												{'   '}
												{detailData?.air_shipment?.option_time}
											</div>
										</div>
									</div>
								</Col>

								<Col xs={12} lg={3} className=' order-last order-lg-0'>
									<Row className='text text-white-50 text-center'>
										<Col lg={6} xs={6}>
											<div className='p-2'>
												<h5 className='text-white mb-1'>
													{data?.chargeable_weight} KG
												</h5>
												<p className='fs-14 mb-0'>Ağırlık</p>
											</div>
										</Col>
										<Col lg={6} xs={4}>
											<div className='p-2'>
												<h4 className='text-white mb-1'>{data?.no_pcs}</h4>
												<p className='fs-14 mb-0'>Adet</p>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>

						<Row>
							<Col lg={12}>
								<div>
									<div className='d-flex'>
										<Nav
											pills
											className='animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1'
											role='tablist'
										>
											<NavItem>
												<NavLink
													className={classnames(
														{ active: activeTab === '1' },
														'fs-14 cursor-pointer'
													)}
													onClick={() => {
														toggleTab('1');
													}}
												>
													<i className='ri-airplay-fill d-inline-block d-md-none'></i>{' '}
													<span className='d-none d-md-inline-block'>
														Detay
													</span>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames(
														{ active: activeTab === '2' },
														'fs-14 cursor-pointer'
													)}
													onClick={() => {
														toggleTab('2');
													}}
												>
													<i className='ri-list-unordered d-inline-block d-md-none'></i>{' '}
													<span className='d-none d-md-inline-block'>
														Aktiviteler
													</span>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames(
														{ active: activeTab === '3' },
														'fs-14 cursor-pointer'
													)}
													onClick={() => {
														toggleTab('3');
													}}
												>
													<i className='ri-price-tag-line d-inline-block d-md-none'></i>{' '}
													<span className='d-none d-md-inline-block'>
														Sorular
													</span>
												</NavLink>
											</NavItem>
											{/* <NavItem>
												<NavLink
													className={classnames(
														{ active: activeTab === '4' },
														'fs-14 cursor-pointer'
													)}
													onClick={() => {
														toggleTab('4');
													}}
												>
													<i className='ri-folder-4-line d-inline-block d-md-none'></i>{' '}
													<span className='d-none d-md-inline-block'>
														Dökümanlar
													</span>
												</NavLink>
											</NavItem> */}
											<NavItem>
												<NavLink
													className={classnames(
														{ active: activeTab === '5' },
														'fs-14 cursor-pointer'
													)}
													onClick={() => {
														toggleTab('5');
													}}
												>
													<i className='ri-folder-4-line d-inline-block d-md-none'></i>{' '}
													<span className='d-none d-md-inline-block'>
														Araçlar
													</span>
												</NavLink>
											</NavItem>
											{detailData?.air_shipment?.pdf != null && (
												<NavItem>
													<Button
														className='btn'
														color='info'
														type='button'
														onClick={() => {
															openPdf(detailData.air_shipment.pdf);
														}}
													>
														PDF Görüntüle
													</Button>
												</NavItem>
											)}
											{detailData?.air_shipment?.pdf != null && (
												<NavItem>
													<Button
														className='btn'
														color='danger'
														type='button'
														disabled={regenerateIsLoading}
														onClick={() => {
															regeneratePdf(detailData.air_shipment.id);
														}}
													>
														Yeniden PDF Oluştur
													</Button>
												</NavItem>
											)}
										</Nav>
									</div>

									<TabContent activeTab={activeTab} className='pt-4'>
										<TabPane tabId='1'>
											<Row>
												<Col xxl={6}>
													<Card>
														<CardBody>
															<h5 className='card-title mb-3'>İhracatçı</h5>
															<div className='table-responsive'>
																<Table className='table-borderless mb-0'>
																	<tbody>
																		<tr>
																			<th className='ps-0' scope='row'>
																				Firma:
																			</th>
																			<td className='text-muted'>
																				{data?.company?.name}
																			</td>
																		</tr>
																		<tr>
																			<th className='ps-0' scope='row'>
																				Telefon
																			</th>
																			<td className='text-muted'>
																				{data?.company?.phone ?? '-'}
																			</td>
																		</tr>
																		{data?.company?.email && (
																			<tr>
																				<th className='ps-0' scope='row'>
																					Eposta
																				</th>
																				<td className='text-muted'>
																					{data?.company?.email}
																				</td>
																			</tr>
																		)}
																		{data?.company?.address && (
																			<tr>
																				<th className='ps-0' scope='row'>
																					Konum:
																				</th>
																				<td className='text-muted'>
																					{data?.company?.address}
																				</td>
																			</tr>
																		)}
																		{data?.company?.created_at && (
																			<tr>
																				<th className='ps-0' scope='row'>
																					Üyelik Tarhih
																				</th>
																				<td className='text-muted'>
																					{moment(
																						data?.company?.created_at,
																						'DD.MM.YYYY'
																					)}
																				</td>
																			</tr>
																		)}

																		{data?.customer?.created_at && (
																			<tr>
																				<th className='ps-0' scope='row'>
																					Üyelik Tarhih
																				</th>
																				<td className='text-muted'>
																					{data?.customer?.created_at}
																				</td>
																			</tr>
																		)}
																	</tbody>
																</Table>
															</div>
														</CardBody>
													</Card>
												</Col>
												<Col xxl={6}>
													<Card>
														<CardBody>
															<h5 className='card-title mb-3'>İthalatçı</h5>
															<div className='table-responsive'>
																<Table className='table-borderless mb-0'>
																	<tbody>
																		<tr>
																			<th className='ps-0' scope='row'>
																				Firma:
																			</th>
																			<td className='text-muted'>
																				{data?.consignee?.name}
																			</td>
																		</tr>
																		{data?.consignee?.phone && (
																			<tr>
																				<th className='ps-0' scope='row'>
																					Telefon
																				</th>
																				<td className='text-muted'>
																					{data?.consignee?.phone}
																				</td>
																			</tr>
																		)}
																		{data?.consignee?.email && (
																			<tr>
																				<th className='ps-0' scope='row'>
																					Eposta
																				</th>
																				<td className='text-muted'>
																					{data?.consignee?.email}
																				</td>
																			</tr>
																		)}
																		{data?.consignee?.address && (
																			<tr>
																				<th className='ps-0' scope='row'>
																					Konum:
																				</th>
																				<td className='text-muted'>
																					{data?.consignee?.address}
																				</td>
																			</tr>
																		)}
																		{data?.consignee?.taxNumber && (
																			<tr>
																				<th className='ps-0' scope='row'>
																					Üyelik Tarhih
																				</th>
																				<td className='text-muted'>
																					{data?.consignee?.taxNumber}
																				</td>
																			</tr>
																		)}

																		{data?.consignee?.created_at && (
																			<tr>
																				<th className='ps-0' scope='row'>
																					Üyelik Tarhih
																				</th>
																				<td className='text-muted'>
																					{data?.consignee?.created_at}
																				</td>
																			</tr>
																		)}
																	</tbody>
																</Table>
															</div>
														</CardBody>
													</Card>
												</Col>
												<Col xxl={12}>
													<Card>
														<CardBody>
															<div className='table-responsive'>
																<Table className='table table-centered table-nowrap mb-0'>
																	<thead className='table-light'>
																		<tr>
																			<th>Ürün Tipi</th>
																			<th>Taşıyıcı</th>
																			<th>Ürün Kodu</th>
																			<th>NawB Kodu</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr>
																			<td>
																				{
																					detailData?.air_shipment
																						?.dimensions?.[0]?.type
																				}
																			</td>
																			<td>
																				{
																					detailData?.air_shipment?.carrier
																						?.name
																				}
																			</td>
																			<td>{data?.product_code_name}</td>
																			<td>
																				{detailData?.air_shipment?.mawb_code}
																			</td>
																		</tr>
																	</tbody>
																</Table>
															</div>
														</CardBody>
													</Card>
												</Col>
											</Row>
										</TabPane>
										<TabPane tabId='2'>
											<Card>
												<CardBody>
													<h5 className='card-title mb-3'>Aktiviteler</h5>
													<div className='acitivity-timeline'>
														{detailData?.activities?.map((item, key) => (
															<React.Fragment key={key}>
																<div className='acitivity-item py-3 d-flex'>
																	<div className='flex-shrink-0 avatar-xs acitivity-avatar'>
																		{item.img !== '' ? (
																			<></>
																		) : (
																			<div className='avatar-title bg-soft-success text-success rounded-circle'>
																				{item.user[0]}
																			</div>
																		)}
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<h6 className='mb-1'>
																			{item.user}
																			<span className='badge bg-soft-secondary text-secondary align-middle ms-1'>
																				{item.status}
																			</span>
																		</h6>
																		<p className='text-muted mb-2'>
																			{item.message}
																		</p>
																		{/* {item.attachments.length > 0 && (
																			<Col xxl={4}>
																				<div className='row border border-dashed gx-2 p-2 mb-2'>
																					{item.attachments.map(
																						(img, index) => (
																							<div
																								className='col-4 mt-1 mb-1'
																								key={index}
																							>
																								<img
																									src={img}
																									alt=''
																									className='img-fluid rounded'
																								/>
																							</div>
																						)
																					)}
																				</div>
																			</Col>
																		)} */}

																		<small className='mb-0 text-muted'>
																			{moment(item.date).format(
																				'DD.MM.YYYY HH:mm'
																			)}
																		</small>
																	</div>
																</div>
															</React.Fragment>
														))}
													</div>
												</CardBody>
											</Card>
										</TabPane>
										<TabPane tabId='3'>
											<Card>
												<CardBody>
													<div className='table-responsive'>
														<Table className='table table-centered table-nowrap mb-0'>
															<thead className='table-light'>
																<tr>
																	<th>Soru</th>
																	<th>Ürün Kodu</th>
																	<th>Soru Türü</th>
																	<th>Cevap</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																{detailData?.questions?.map((item, i) => {
																	const question =
																		item?.questions?.translations.find(
																			(t) => t.language_id == menuLang
																		);
																	return (
																		<tr key={i}>
																			<td className='align-middle'>
																				{question?.name}
																			</td>
																			<td className='align-middle'>
																				{
																					detailData?.air_shipment
																						?.product_code_name
																				}
																			</td>
																			<td className='align-middle'>
																				{item?.questions?.question_type_string}
																			</td>
																			<td className='align-middle'>
																				{getAnswer(
																					item?.questions?.question_type_string,
																					item.answer[0]
																				)}
																			</td>
																			<td className='align-middle'>
																				{item.medias.length > 0 && (
																					<Fancybox
																						options={{
																							Carousel: {
																								infinite: false
																							}
																						}}
																					>
																						<a
																							data-fancybox='gallery'
																							href={item.medias[0].url}
																						>
																							<img
																								alt=''
																								src={item.medias[0].url}
																								height='60'
																							/>
																						</a>
																					</Fancybox>
																				)}
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</Table>
													</div>
												</CardBody>
											</Card>
										</TabPane>

										<TabPane tabId='5'>
											<Card>
												<CardBody>
													<Card>
														<CardBody>
															<div className='table-responsive'>
																<Table className='table table-centered table-nowrap mb-0'>
																	<thead className='table-light'>
																		<tr>
																			<th>Araç Plakası</th>
																			<th>Atanma Durumu</th>
																		</tr>
																	</thead>
																	<tbody>
																		{data?.vehicle?.map((item, i) => (
																			<tr key={i}>
																				<td>{item?.plate}</td>
																				<td>
																					{item?.is_assigned
																						? 'Atanmış'
																						: 'Atanmamış'}
																				</td>
																				<td></td>
																			</tr>
																		))}
																	</tbody>
																</Table>
															</div>
														</CardBody>
													</Card>
												</CardBody>
											</Card>
										</TabPane>
									</TabContent>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			)}
		</React.Fragment>
	);
};

export default ShipmentDetail;
