import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
	Row,
	Col,
	Alert,
	Card,
	CardBody,
	Container,
	FormFeedback,
	Input,
	Label,
	Form
} from 'reactstrap';
import { useHistory, useLocation } from 'react-router';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { withRouter, Link } from 'react-router-dom';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// import images
// import profile from "../../assets/images/bg.png";
import logoLight from '../../assets/images/logo-light.png';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';

import { useForgetPasswordMutation } from '../../store/api/auth';
import { toast } from 'react-hot-toast';

const ForgetPasswordPage = (props) => {
	const history = useHistory();
	const [forgetPassword, { data, isLoading, isSuccess, isError }] =
		useForgetPasswordMutation();

	const validation = useFormik({
		enableReinitialize: true,

		initialValues: {
			email: ''
		},
		validationSchema: Yup.object({
			email: Yup.string().required('Please Enter Your Email')
		}),
		onSubmit: (values) => {
			forgetPassword(values);
			setAuth({
				...auth,
				email: values.email
			});
		}
	});

	const [auth, setAuth] = useState({
		email: '',
		password: '',
		password_confirmation: '',
		token: ''
	});

	useEffect(() => {
		if (isSuccess) {
			history.push('/change-password-success');
		}
	}, [isSuccess, history]);

	useEffect(() => {
		if (isError) {
			toast.error('Email not found');
		}
	}, [isError]);

	document.title = 'Reset Password | Yeditepe Go';
	return (
		<ParticlesAuth>
			<div className='auth-page-content'>
				<Container>
					<Row>
						<Col lg={12}>
							<div className='text-center mt-sm-5 mb-4 text-white-50'>
								<div>
									<Link to='/' className='d-inline-block auth-logo'>
										{/* <img src={logoLight} alt='' height='20' /> */}
									</Link>
								</div>
							</div>
						</Col>
					</Row>

					<Row className='justify-content-center'>
						<Col md={8} lg={6} xl={5}>
							<Card className='mt-4'>
								<CardBody className='p-4'>
									<div className='text-center mt-2'>
										<h5 className='text-primary'>Şifremi Unuttum</h5>
										<p className='text-muted'>Şifremi Sıfırla</p>
									</div>

									<Alert
										className='alert-borderless alert-warning text-center mb-2 mx-2'
										role='alert'
									>
										E-posta adresinizi girerek şifrenizi sıfırlayabilirsiniz.
									</Alert>
									<div className='p-2'>
										<Form
											onSubmit={(e) => {
												e.preventDefault();
												validation.handleSubmit();
												return false;
											}}
										>
											<div className='mb-4'>
												<Label className='form-label'>Email</Label>
												<Input
													name='email'
													className='form-control'
													placeholder='Enter email'
													type='email'
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.email || ''}
													invalid={
														validation.touched.email && validation.errors.email
															? true
															: false
													}
												/>
												{validation.touched.email && validation.errors.email ? (
													<FormFeedback type='invalid'>
														<div>{validation.errors.email}</div>
													</FormFeedback>
												) : null}
											</div>

											<div className='text-center mt-4'>
												<button className='btn btn-success w-100' type='submit'>
													Sıfırlama İsteği Gönder
												</button>
											</div>
										</Form>
									</div>
								</CardBody>
							</Card>

							<div className='mt-4 text-center'>
								<p className='mb-0'>
									<Link
										to='/login'
										className='fw-semibold text-primary text-decoration-underline'
									>
										Giriş Yap
									</Link>
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</ParticlesAuth>
	);
};

ForgetPasswordPage.propTypes = {
	history: PropTypes.object
};

export default withRouter(ForgetPasswordPage);
