import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	Col,
	Container,
	Input,
	Label,
	Row,
	Button,
	Form,
	FormFeedback,
	Alert,
	Spinner
} from 'reactstrap';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
//redux
import { useDispatch, useSelector } from 'react-redux';

import { withRouter, Link } from 'react-router-dom';

import { useHistory } from 'react-router-dom';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import logoLight from '../../assets/images/logo-light.png';
import splash from '../../assets/images/login-splash.png';
//Import config

import { useLoginMutation } from '../../store/api/auth';
import { setIsSignedIn } from '../../store/slices/auth/authSlice';

const Login = (props) => {
	const dispatch = useDispatch();
	const error = null;

	const history = useHistory();

	const [userLogin, setUserLogin] = useState([]);

	const [messages, setMessages] = useState('');

	const [login, { data, isLoading, isSuccess, isError }] = useLoginMutation();

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: userLogin.email,
			password: userLogin.password
		},
		validationSchema: Yup.object({
			email: Yup.string().required('Lütfen geçerli bir e-posta adresi giriniz'),
			password: Yup.string().required('Lütfen şifrenizi giriniz')
		})
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { email, password } = validation.values;
		const data = {
			email: email,
			password: password
		};
		await setUserLogin(data);
		/* eslint-disable no-mixed-spaces-and-tabs */
		await login({
			email: validation.values.email,
			password: validation.values.password
		});
	};

	useEffect(() => {
		if (isSuccess) {
			dispatch(
				setIsSignedIn({
					user: {
						name: data.data.user,
						email: data.data.email
					},
					token_type: data.data.token_type,
					expires_in: data.data.expires_in,
					access_token: data.data.access_token,
					menu: data.data.menu
				})
			);
			window.location.href = '/dashboard';
		}
		if (isError) {
			setMessages('Hatalı E-Posta veya Şifre');
		}
	}, [dispatch, isSuccess, isError, data]);

	const [showPassword, setShowPassword] = useState(false);

	document.title = 'Giriş | Yeditepe Go';
	return (
		<React.Fragment>
			<div className='auth-page-wrapper auth-one-bg auth-bg-cover d-flex justify-content-center align-items-center min-vh-100'>
				<div className='bg-overlay'></div>
				<Container>
					<Row>
						<Col lg={12}>
							<Card className='overflow-hidden mb-0'>
								<Row className='g-0'>
									<Col lg={6}>
										<div className='h-100'>
											<img
												className='position-relative h-100 d-flex flex-column auth-image'
												src={splash}
												alt=''
											/>
										</div>
									</Col>

									<Col lg={6}>
										<div className='p-lg-5 p-4 p-lg-5 p-4 h-100 d-flex justify-content-center align-items-center w-100 flex-column'>
											<div className='w-100 mb-4'>
												<h5 className='text-success fw-bold'>Hoşgeldin!</h5>
												<p className='text-muted'>
													Devam edebilmek için lütfen giriş yapınız.
												</p>
											</div>

											{isError && (
												<Alert className='text-center' color='danger'>
													{messages}
												</Alert>
											)}
											<div className='w-100 p-2'>
												<Form
													onSubmit={(e) => {
														e.preventDefault();
														validation.handleSubmit();
														return false;
													}}
													action='#'
												>
													<div className='mb-3'>
														<Label htmlFor='email' className='form-label'>
															E-Posta
														</Label>
														<Input
															name='email'
															className='form-control'
															placeholder='E-Posta Adresiniz'
															type='email'
															onChange={validation.handleChange}
															onBlur={validation.handleBlur}
															value={validation.values.email || ''}
															invalid={
																validation.touched.email &&
																validation.errors.email
																	? true
																	: false
															}
														/>
														{validation.touched.email &&
														validation.errors.email ? (
															<FormFeedback type='invalid'>
																{validation.errors.email}
															</FormFeedback>
														) : null}
													</div>

													<div className='mb-3'>
														<div className='float-end'>
															<Link
																to='/forgot-password'
																className='text-muted'
															>
																Şifremi Unuttum.
															</Link>
														</div>
														<Label
															className='form-label'
															htmlFor='password-input'
														>
															Şifre
														</Label>
														<div className='position-relative auth-pass-inputgroup mb-3 overflow-hidden'>
															<Input
																name='password'
																value={validation.values.password || ''}
																type={showPassword ? 'text' : 'password'}
																className='form-control pe-5'
																placeholder='Şifreniz'
																onChange={validation.handleChange}
																onBlur={validation.handleBlur}
																invalid={
																	validation.touched.password &&
																	validation.errors.password
																		? true
																		: false
																}
															/>
															{validation.touched.password &&
															validation.errors.password ? (
																<FormFeedback type='invalid'>
																	{validation.errors.password}
																</FormFeedback>
															) : null}
															<button
																className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
																type='button'
																id='password-addon'
																onClick={() => {
																	showPassword
																		? setShowPassword(false)
																		: setShowPassword(true);
																}}
															>
																<i className='ri-eye-fill align-middle'></i>
															</button>
														</div>
													</div>

													<div className='form-check'>
														<Input
															className='form-check-input'
															type='checkbox'
															value=''
															id='auth-remember-check'
														/>
														<Label
															className='form-check-label'
															htmlFor='auth-remember-check'
														>
															Beni Hatırla
														</Label>
													</div>

													<div className='mt-4'>
														<Button
															color='success'
															className='btn btn-success w-100'
															type='submit'
															onClick={handleSubmit}
															disabled={isLoading}
														>
															{isLoading ? (
																<Spinner color='light' size='sm' />
															) : (
																'Giriş Yap'
															)}
														</Button>
													</div>
												</Form>
											</div>
										</div>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(Login);
