import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Widget from './Widgets';
import LastShipments from './LastShipments';
import ShipmentsByCountry from './ ShipmentsByCountry';
import { useGetDashboardStatsQuery } from '../../store/api/dashboard';

const Dashboard = () => {
	document.title = 'Dashboard | Yeditepe Go';

	const {
		data: statsData,
		isLoading: isStatsLoading,
		isError: isStatsError,
		isSuccess: isStatsSuccess,
		refetch
	} = useGetDashboardStatsQuery();

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<BreadCrumb title='Dashboard' pageTitle='Dashboards' />
					<Row>
						<Col>
							<div className='h-100'>
								{/* <Section /> */}
								<Row>
									<Widget
										data={isStatsSuccess ? statsData?.data?.widget : null}
									/>
								</Row>
								<Row>
									<Col xl={5}>
										<ShipmentsByCountry
											data={
												isStatsSuccess
													? statsData?.data?.shippingsByCountry
													: []
											}
										/>
									</Col>
									<Col xl={7}>
										<LastShipments
											data={
												isStatsSuccess ? statsData?.data?.lastShippings : []
											}
										/>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Dashboard;
