import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	Row,
	Spinner,
	TabContent,
	TabPane
} from 'reactstrap';
import toast from 'react-hot-toast';

import Select from 'react-select';
import {
	useGetProductCodesQuery,
	useGetMatchedQuestionByIdQuery,
	useGetAllQuestionsQuery,
	useUpdateMatchedQuestionMutation
} from '../../../store/api/questions';
import { useGetAllCapCodesQuery } from '../../../store/api/capCodes';

const EditMatchQuesitonModal = ({ addModal, setAddModal, questionId }) => {
	const handleSubmit = () => {
		// createQuestion(createQuestionData);
	};

	const closeModal = () => {
		setAddModal(false);
		setQuestion([]);
		setSelected([]);
		setProductCode([]);
	};

	const [question, setQuestion] = useState([]);

	const {
		data: questionData,
		isLoading,
		isSuccess: questionDataIsSuccess
	} = useGetMatchedQuestionByIdQuery(questionId, {
		skip: !addModal && !questionId
	});

	const {
		data: productCodes,
		isSuccess: productCodesSuccess,
		isLoading: productCodesLoading
	} = useGetProductCodesQuery(undefined, {
		skip: !addModal
	});

	const [productCode, setProductCode] = useState([]);

	useEffect(() => {
		if (productCodesSuccess) {
			setProductCode(productCodes.data);
		}
	}, [productCodesSuccess]);

	const targetType = [
		{ value: '0', label: 'Sevkiyat' },
		{ value: '1', label: 'Araç' }
	];

	const [selected, setSelected] = useState(null);
	const [checked, setChecked] = useState(false);
	const [targetTypeValue, setTargetTypeValue] = useState(null);
	const [selectedProductCode, setSelectedProductCode] = useState(null);
	const [followUpQuestion, setFollowUpQuestion] = useState({});
	const [falseFollowUpQuestion, setFalseFollowUpQuestion] = useState({});
	const [trueFollowUpQuestion, setTrueFollowUpQuestion] = useState({});
	const [allQuestions, setAllQuestions] = useState([]);
	const [capCodes, setCapCodes] = useState([]);
	const [selectedCapCode, setSelectedCapCode] = useState(null);
	const [followUp, setFollowUp] = useState([]);
	const handleSwitch = (e) => {
		setChecked(e.target.checked);
	};

	useEffect(() => {
		if (questionDataIsSuccess) {
			setQuestion(questionData.data);

			questionData?.data?.follow_up_question_id !== null ||
			questionData?.data?.false_follow_up_question_id !== null ||
			questionData?.data?.true_follow_up_question_id !== null
				? setChecked(true)
				: setChecked(false);

			setTargetTypeValue(questionData?.data?.target_type);
			setSelected({
				value: questionData?.data?.product_code,
				label: questionData?.data?.product_code_string
			});

			setSelectedCapCode({
				value: questionData?.data?.capCodes?.[0]?.id,
				label: questionData?.data?.capCodes?.[0]?.cap_name
			});
		}
	}, [questionDataIsSuccess, questionData, allQuestions]);

	useEffect(() => {
		setFollowUpQuestion({
			value: allQuestions?.filter(
				(item) => item?.id === question?.question?.id
			)[0]?.id,
			label: allQuestions?.filter(
				(item) => item?.id === question?.question?.id
			)[0]?.translation?.name
		});

		setFalseFollowUpQuestion({
			value: allQuestions?.filter(
				(item) => item?.id === question?.false_follow_up_question?.question_id
			)[0]?.id,
			label: allQuestions?.filter(
				(item) => item?.id === question?.false_follow_up_question?.question_id
			)[0]?.translation?.name
		});

		setTrueFollowUpQuestion({
			value: allQuestions?.filter(
				(item) => item?.id === question?.true_follow_up_question?.question_id
			)[0]?.id,
			label: allQuestions?.filter(
				(item) => item?.id === question?.true_follow_up_question?.question_id
			)[0]?.translation?.name
		});
	}, [question, allQuestions]);

	const {
		data: allQuestionsData,
		isSuccess: allQuestionsSuccess,
		isLoading: allQuestionsLoading
	} = useGetAllQuestionsQuery({
		skip: !addModal
	});

	useEffect(() => {
		if (allQuestionsSuccess) {
			setAllQuestions(allQuestionsData.data);
		}
	}, [allQuestionsSuccess]);

	const [
		updateMatchQuestion,
		{ isSuccess: updateMatchQuestionSuccess, error: updateMatchQuestionError }
	] = useUpdateMatchedQuestionMutation();

	const handleUpdate = () => {
		const updateData = {
			question_id: question.question.id,
			product_code_id: selected?.value,
			target_type: targetTypeValue.value,
			true_follow_up_question_id: trueFollowUpQuestion.value,
			false_follow_up_question_id: falseFollowUpQuestion.value,
			follow_up_question_id: followUpQuestion.value,
			capCode: [selectedCapCode?.value]
		};

		updateMatchQuestion({
			id: questionId,
			data: updateData
		});
	};

	useEffect(() => {
		if (updateMatchQuestionSuccess) {
			setAddModal(false);
			setQuestion([]);
			setSelected([]);
			setProductCode([]);
			setTargetTypeValue(null);
			setChecked(false);
			setSelectedProductCode(null);

			toast.success('Soru başarıyla güncellendi.');
		}
	}, [updateMatchQuestionSuccess]);

	const {
		data: capCodesData,
		isLoading: capCodesDataLoading,
		isError: capCodesDataError,
		isSuccess: capCodesDataSuccess
	} = useGetAllCapCodesQuery({
		skip: addModal !== false
	});

	useEffect(() => {
		if (capCodesDataSuccess) {
			setCapCodes(capCodesData?.data);
		}
	}, [capCodesDataSuccess]);

	const handleCapCodeChange = (e) => {
		setSelectedCapCode(e);
	};

	useEffect(() => {
		if (updateMatchQuestionError) {
			toast.error(updateMatchQuestionError?.data.message);
			// console.log();
		}
	}, [updateMatchQuestionError]);

	return (
		<Modal
			id={'editMatchedQuestion'}
			isOpen={addModal}
			toggle={closeModal}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
			role='dialog'
			aria-hidden='true'
			size='lg'
		>
			<ModalHeader toggle={() => setAddModal(false)} className='p-3 bg-white'>
				<span>Soru:</span> {'  '}
				{question?.question?.translation?.name}
			</ModalHeader>
			<ModalBody>
				{isLoading ? (
					<Spinner
						style={{
							width: '3rem',
							height: '3rem'
						}}
					/>
				) : (
					<>
						<h6>Paket Tipi Seçiniz</h6>
						<hr />

						<Row className='mb-5'>
							<Col md='11'>
								<Select
									name='targetType'
									value={targetTypeValue}
									onChange={(value) => setTargetTypeValue(value)}
									options={targetType?.map((item) => ({
										value: item?.value,
										label: item?.label
									}))}
									placeholder='Ürün Kodu Seçiniz'
									isMulti={false}
								/>
							</Col>
						</Row>

						<h6>Ürün Kodu Seçiniz</h6>
						<hr />

						<Row className='mb-3'>
							<Col md='11'>
								<Select
									name='productCode'
									value={selected}
									onChange={(value) => setSelected(value)}
									options={productCode?.map((item) => ({
										value: item?.id,
										label: item?.name
									}))}
									placeholder='Ürün Kodu Seçiniz'
								/>
							</Col>
						</Row>

						<Row className='mb-3'>
							<Col md='11'>
								<Select
									options={capCodes?.map((item) => ({
										value: item?.id,
										label: item?.cap_name
									}))}
									isSearchable
									placeholder='Kap Kodu Seçiniz'
									isClearable
									value={{
										value: selectedCapCode?.value,
										label: selectedCapCode?.label
									}}
									onChange={(capcode) => handleCapCodeChange(capcode)}
								/>
							</Col>
						</Row>

						<Row>
							<Col md={12}>
								<h6>Bağlantılı Soru</h6>
							</Col>
							<Col md={6}>
								<div className='mb-4 mt-4 form-check form-switch mb-2'>
									<Input
										className='form-check-input'
										type='checkbox'
										role='switch'
										id='flexSwitchCheckDefault'
										name='isActive'
										onChange={(e) => handleSwitch(e)}
										checked={checked}
									/>

									<label
										className='form-check-label'
										htmlFor='flexSwitchCheckDefault'
									>
										{checked ? 'Aktif' : 'Pasif'}
									</label>
								</div>
							</Col>
						</Row>

						{checked &&
							selected &&
							question?.question?.question_type?.key !== 'yes/no' && (
								<>
									<div className='pt-2'>
										<Select
											name='questionType'
											value={
												allQuestions?.find(
													(item) => item?.id === followUpQuestion?.value
												)
													? null
													: followUpQuestion
											}
											onChange={(value) => setFollowUpQuestion(value)}
											options={allQuestions?.map((item) => ({
												value: item?.id,
												label: item?.translation?.name
											}))}
											isOptionDisabled={(option) =>
												option?.value === question?.question?.id
											}
											placeholder='Soru Tipi Seçiniz'
											isMulti={false}
										/>
									</div>
								</>
							)}
					</>
				)}

				{checked && question?.question?.question_type?.key === 'yes/no' && (
					<>
						<h6>Doğru Cevap</h6>
						<hr />

						<Select
							name='questionType'
							value={{
								value: trueFollowUpQuestion?.value,
								label: trueFollowUpQuestion?.label
							}}
							onChange={(value) => setTrueFollowUpQuestion(value)}
							options={allQuestions?.map((item) => ({
								value: item?.id,
								label: item?.translation?.name
							}))}
							placeholder='Soru Tipi Seçiniz'
							isMulti={false}
							isOptionDisabled={(option) =>
								option?.value === question?.question?.id
							}
						/>

						<h6 className='mt-3'>Yanlış Cevap</h6>
						<hr />

						<Select
							name='questionType'
							value={{
								value: falseFollowUpQuestion?.value,
								label: falseFollowUpQuestion?.label
							}}
							onChange={(value) => setFalseFollowUpQuestion(value)}
							options={allQuestions?.map((item) => ({
								value: item?.id,
								label: item?.translation?.name
							}))}
							placeholder='Soru Tipi Seçiniz'
							isMulti={false}
						/>
					</>
				)}
			</ModalBody>

			<ModalFooter>
				<Button color='primary' onClick={handleUpdate}>
					Kaydet
				</Button>
				<Button color='secondary' onClick={() => setAddModal(false)}>
					Kapat
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export { EditMatchQuesitonModal };
