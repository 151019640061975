import React, { useState } from 'react';

import { Container } from 'reactstrap';

import BreadCrumb from '../../Components/Common/BreadCrumb';

import {
	Button,
	Col,
	Input,
	Label,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane
} from 'reactstrap';
import classnames from 'classnames';
import Role from './Sections/Role';
import Module from './Sections/Module';
import Permission from './Sections/Permission';

const Roles = () => {
	// State for current active Tab
	const [currentActiveTab, setCurrentActiveTab] = useState('1');

	// Toggle active state for Tab
	const toggle = (tab) => {
		if (currentActiveTab !== tab) setCurrentActiveTab(tab);
	};

	return (
		<>
			<React.Fragment>
				<div className='page-content'>
					<Container fluid>
						<BreadCrumb title='Roller' pageTitle='Yönetim' />

						<div>
							<Role />
						</div>
					</Container>
				</div>
			</React.Fragment>
		</>
	);
};

export default Roles;
