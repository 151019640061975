import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store/index';

export const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<React.Fragment>
				<BrowserRouter basename={process.env.PUBLIC_URL}>
					<App />
				</BrowserRouter>
			</React.Fragment>
		</PersistGate>
	</Provider>
);
