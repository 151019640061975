import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';

const ShipmentChart = () => {
	return (
		<React.Fragment>
			<Card className='card-height-100'>
				<CardHeader>
					<h4 className='card-title mb-0'>Aylara Göre Sevkiyat Durumu</h4>
				</CardHeader>
				<CardBody>
					<LinewithDataLabels dataColors='["--vz-success", "--vz-primary", "--vz-warning"]' />
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

const LinewithDataLabels = ({ dataColors }) => {
	var LinewithDataLabelsColors = getChartColorsArray(dataColors);
	var series = [
		{
			name: 'Aktif',
			data: [16, 17, 22, 12, 23, 46, 55]
		},
		{
			name: 'Onay Bekleyen',
			data: [14, 11, 16, 17, 17, 13, 12]
		},
		{
			name: 'Tamamlanan',
			data: [26, 24, 32, 36, 33, 31, 33]
		}
	];
	var options = {
		chart: {
			height: 380,
			type: 'line',
			zoom: {
				enabled: false
			},
			toolbar: {
				show: false
			}
		},
		colors: LinewithDataLabelsColors,
		dataLabels: {
			enabled: false
		},
		stroke: {
			width: [3, 3],
			curve: 'straight'
		},
		title: {
			text: 'Sevkiyat Durumları',
			align: 'left',
			style: {
				fontWeight: 500
			}
		},
		grid: {
			row: {
				colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
				opacity: 0.2
			},
			borderColor: '#f1f1f1'
		},
		markers: {
			style: 'inverted',
			size: 6
		},
		xaxis: {
			categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
			title: {
				text: ''
			}
		},
		yaxis: {
			title: {
				text: ''
			},
			min: 5,
			max: 40
		},
		legend: {
			position: 'top',
			horizontalAlign: 'right',
			floating: true,
			offsetY: -25,
			offsetX: -5
		},
		responsive: [
			{
				breakpoint: 600,
				options: {
					chart: {
						toolbar: {
							show: false
						}
					},
					legend: {
						show: false
					}
				}
			}
		]
	};
	return (
		<React.Fragment>
			<ReactApexChart
				options={options}
				series={series}
				type='line'
				height='380'
				className='apex-charts'
			/>
		</React.Fragment>
	);
};

const getChartColorsArray = (colors) => {
	colors = JSON.parse(colors);
	return colors.map(function (value) {
		var newValue = value.replace(' ', '');
		if (newValue.indexOf(',') === -1) {
			var color = getComputedStyle(document.documentElement).getPropertyValue(
				newValue
			);

			if (color.indexOf('#') !== -1) color = color.replace(' ', '');
			if (color) return color;
			else return newValue;
		} else {
			var val = value.split(',');
			if (val.length === 2) {
				var rgbaColor = getComputedStyle(
					document.documentElement
				).getPropertyValue(val[0]);
				rgbaColor = 'rgba(' + rgbaColor + ',' + val[1] + ')';
				return rgbaColor;
			} else {
				return newValue;
			}
		}
	});
};

export default ShipmentChart;
