import apiMiddleware from './middleware/ApiMiddleware';

export const generalApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getImageById: builder.query({
			query: ({ id }) => ({
				url: `/medias/get-media-by-id/${id}`,
				method: 'GET'
			})
		})
	})
});
export const { useGetImageByIdQuery } = generalApi;
