import apiMiddleware from './middleware/ApiMiddleware';

export const companyApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getAllCompanies: builder.query({
			query: () => ({
				url: '/companies/get-all'
			}),
			providesTags: 'companies'
		}),

		getCompaniesById: builder.query({
			query: ({ id }) => ({
				url: `/companies/get-by-id/${id}`
			}),
			providesTags: 'companies'
		}),
		getCompaniesByFilter: builder.query({
			query: (filter) => ({
				url: `/companies/get-filtered`,
				params: filter
			})
		}),
		addCompany: builder.mutation({
			query: (data) => ({
				url: '/companies',
				method: 'POST',
				body: data
			}),
			invalidatesTags: 'companies'
		}),
		updateCompany: builder.mutation({
			query: ({ id, data }) => ({
				url: `/companies/${id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: 'companies'
		}),

		deleteCompany: builder.mutation({
			query: (id) => ({
				method: 'DELETE',
				url: `/companies/${id}`
			}),
			invalidatesTags: 'companies'
		}),

		getRolesByCompanyId: builder.query({
			query: (id) => ({
				url: `/roles/get-all-by-company-or-customer-id/${id}`
			}),
			providesTags: 'roles'
		}),

		updateCompany: builder.mutation({
			query: ({ id, data }) => ({
				url: `/companies/${id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: 'companies'
		})
	})
});

export const {
	useGetAllCompaniesQuery,
	useGetCompaniesByIdQuery,
	useGetCompaniesByFilterQuery,
	useAddCompanyMutation,
	useUpdateCompanyMutation,
	useDeleteCompanyMutation,

	useGetRolesByCompanyIdQuery
} = companyApi;
