import React, { useEffect, useState } from 'react';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Spinner
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
	useGetLanguageQuery,
	useSetLanguageMutation
} from '../../store/api/languages';
import {
	changeLanguage,
	setIsSignedIn,
	setMenu
} from '../../store/slices/auth/authSlice';
import { toast } from 'react-hot-toast';

const LanguageDropdown = () => {
	const dispatch = useDispatch();
	const { data: languagesData, isLoading, error } = useGetLanguageQuery();

	const findLanguageCodeFromId = (id) => {
		const language = languagesData?.data?.find((lang) => lang?.id === id);
		return language?.code;
	};

	const { menuLang } = useSelector((state) => state.authSlice);

	const [lang, setLang] = useState([]);

	const [selectedLang, setSelectedLang] = useState();

	useEffect(() => {
		setSelectedLang(findLanguageCodeFromId(menuLang));
	}, [menuLang, languagesData]);

	const [
		languages,
		{
			data: langData,
			isLoading: langLoading,
			isSuccess: langSucces,
			isError: langError
		}
	] = useSetLanguageMutation();

	const changeLanguageAction = (data) => {
		languages({
			language_id: data.id
		});
		dispatch(changeLanguage(data.id));
		setLang(data);
	};

	useEffect(() => {
		if (langSucces) {
			localStorage.setItem('lang', lang.code);
			setSelectedLang(lang.code);
			dispatch(
				setMenu({
					menu: langData.data.menu
				})
			);
		}

		if (langError) {
			toast.error('Dil değiştirirken bir hata oluştu');
		}
	}, [dispatch, langData, langError, langSucces, langLoading, lang]);

	const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
	const toggleLanguageDropdown = () => {
		setIsLanguageDropdown(!isLanguageDropdown);
	};

	return (
		<React.Fragment>
			<Dropdown
				isOpen={isLanguageDropdown}
				toggle={toggleLanguageDropdown}
				className='topbar-head-dropdown header-item'
			>
				<DropdownToggle
					className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none'
					tag='button'
				>
					{isLoading ? (
						<Spinner color='primary' />
					) : (
						<img
							src={`/assets/images/flags/${selectedLang}.svg`}
							alt='Header Language'
							height='20'
							className='rounded'
						/>
					)}
				</DropdownToggle>
				<DropdownMenu className='notify-item language'>
					{languagesData ? (
						languagesData?.data.map((language, index) => (
							<DropdownItem
								key={index}
								onClick={() => changeLanguageAction(language)}
								className={`notify-item language py-2 ${
									selectedLang === language.id ? 'active' : ''
								}`}
							>
								<img
									src={`/assets/images/flags/${language.code}.svg`}
									alt='Header Language'
									height='20'
									className='rounded'
								/>
								<span className='align-middle ms-1'>{language.name}</span>
							</DropdownItem>
						))
					) : (
						<Spinner color='primary' />
					)}
				</DropdownMenu>
			</Dropdown>

			{langLoading && (
				<div
					className='d-flex justify-content-center align-items-center'
					style={{
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						width: '100vw',
						height: '100vh',
						position: 'fixed',
						top: 0,
						left: 0,
						zIndex: 1003
					}}
				>
					<Spinner style={{ width: '3rem', height: '3rem' }} color='primary' />
				</div>
			)}
		</React.Fragment>
	);
};

export default LanguageDropdown;
