// Import Images
import avatar1 from '../../../assets/images/users/avatar-1.jpg';

const shipmentWidgets = [
	{
		id: 1,
		label: 'Toplam Onay Bekleyen Sevkiyat',
		icon: 'bx bxs-plane-alt',
		counter: '197',
		date: '',
		decimals: 0,
		suffix: '',
		prefix: ''
	},
	{
		id: 2,
		label: 'Aktif Sevkiyat',
		icon: 'bx bxs-plane-take-off',
		counter: '489.4',
		date: '',
		decimals: 0,
		suffix: '',
		prefix: ''
	},
	{
		id: 3,
		label: 'İlk Sevkiyat Tarihi',
		icon: 'bx bx-calendar',
		counter: '',
		date: '04/11/2020',
		decimals: 0,
		suffix: '',
		prefix: ''
	},
	{
		id: 4,
		label: 'Son Sevkiyat Tarihi',
		icon: 'bx bx-calendar',
		counter: '',
		date: '26/05/2022',
		decimals: 0,
		prefix: '',
		separator: '',
		suffix: ''
	},
	{
		id: 5,
		label: 'Yük Ağırlığı',
		icon: 'bx bx-calculator',
		counter: '2659',
		date: '',
		decimals: 0,
		separator: ',',
		suffix: ' KG',
		prefix: ''
	}
];

const pendingShipments = [
	{
		id: 1,
		img: avatar1,
		company: 'Türk Hava Yolları',
		name: 'FEDARM',
		product: '#235-46894260',
		amount: 'ADB/IAH',
		vendor: '04 Ekm 2022',
		status: 'Paid',
		statusClass: 'success',
		votes: '61'
	},
	{
		id: 2,
		img: avatar1,
		company: 'Lufthansa',
		name: 'Ocean Fresh Fish',
		product: '#235-46859293',
		amount: 'ADB/KWI',
		vendor: '06 Kas 2022',
		status: 'Pending',
		statusClass: 'warning',
		votes: '61'
	},
	{
		id: 3,
		img: avatar1,
		company: 'Pegasus',
		name: 'Kılıç Balıkçılık',
		product: '#235-46893825',
		amount: 'ADB/DXB',
		vendor: '04 Eyl 2022',
		status: 'Paid',
		statusClass: 'success',
		votes: '89'
	},
	{
		id: 4,
		img: avatar1,
		company: 'Türk Hava Yolları',
		name: 'Pragmatech',
		product: '#235-46894260',
		amount: 'ADB/KWI',
		vendor: '04 Oca 2022',
		status: 'Unpaid',
		statusClass: 'danger',
		votes: '47'
	},
	{
		id: 5,
		img: avatar1,
		company: 'Pegasus',
		name: 'Nono Company',
		product: '#235-46859293',
		amount: 'ADB/IAH',
		vendor: '04 Mar 2022',
		status: 'Paid',
		statusClass: 'success',
		votes: '161'
	}
];

export { shipmentWidgets, pendingShipments };
