import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
	useDeleteCompanyMutation,
	useGetCompaniesByFilterQuery,
	useAddCompanyMutation,
	useUpdateCompanyMutation
} from '../../store/api/companies';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import DeleteModal from '../../Components/Common/DeleteModal';
import { toast } from 'react-hot-toast';
import AddCompanyModal from '../../Components/ui/modals/AddCompanyModal';
import TableComponent from '../../Components/ui/table/Table';
const List2 = () => {
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [limit, setLimit] = useState(10);
	const [modal, setModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [switchStatus, setSwitchStatus] = useState(true);
	const [dataId, setDataId] = useState(null);
	const [company, setCompany] = useState(null);
	const [addModal, setAddModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const imageUploader = useRef(null);
	const [visibility, setVisibility] = useState(false);
	const [debounceSearch, setDebounceSearch] = useState('');
	const [file, setFile] = useState(null);
	const [selectedFile, setSelectedFile] = useState();
	const [preview, setPreview] = useState();

	useEffect(() => {
		if (!selectedFile) {
			setPreview(undefined);
			return;
		}
	}, [selectedFile]);

	// Şirket Resmi Upload
	const handleFile = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		if (reader !== undefined && file !== undefined) {
			reader.onloadend = () => {
				setFile(file);
				setSelectedFile(reader.result);
				setPreview(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	//Pagnation
	const [pagination, setPagination] = useState({
		currentPage: 1,
		hasNextPage: false,
		hasPreviousPage: false,
		total: 0,
		totalPages: 0
	});

	const {
		data: dataArray,
		isLoading,
		isFetching,
		isSuccess,
		refetch
	} = useGetCompaniesByFilterQuery({
		search: search ? search : undefined,
		page: page,
		limit: limit
	});

	const [
		addCompany,
		{ isLoading: isAddLoading, isSuccess: isAddSucces, isError: isAddError }
	] = useAddCompanyMutation();

	const handlePageChange = (data) => {
		setPage(data);
	};

	const [
		deleteCompany,
		{
			isLoading: isDeleteLoading,
			isSuccess: isDeleteSuccess,
			isError: isDeleteError
		}
	] = useDeleteCompanyMutation();

	const [
		updateCompany,
		{
			isLoading: isUpdateLoading,
			isError: isUpdateError,
			isSuccess: isUpdateSuccess
		}
	] = useUpdateCompanyMutation();

	//Memo Data from api
	const memoData = React.useMemo(() => {
		if (isSuccess || isDeleteSuccess) {
			setData(dataArray.data.data);
			setPagination({
				total: dataArray.data.total,
				currentPage: dataArray.data.current_page,
				totalPages: dataArray.data.last_page,
				hasNextPage: dataArray.data.next_page_url,
				hasPreviousPage: dataArray.data.prev_page_url ? true : false
			});
		}

		return data;
	}, [isSuccess, dataArray, data, isDeleteSuccess]);

	const toggle = () => {
		if (modal) {
			setModal(false);
		} else {
			setModal(true);
		}
	};

	const handleDeleteEvent = () => {
		deleteCompany(dataId);
		setDeleteModal(false);
		refetch();
		toggle();
	};

	const openDeleteModal = (id) => {
		setDataId(id);
		setDeleteModal(true);
	};

	const validation = useFormik({
		enableReinitialize: true,

		initialValues: {
			name: (company && company.name) || '',
			code: (company && company.code) || '',
			short_name: (company && company.short_name) || '',
			description: (company && company.description) || ''
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Lütfen şirket adı giriniz'),
			code: Yup.string().required('Lütfen şirket kodu giriniz'),
			short_name: Yup.string().required('Lütfen şirket kısa kodu giriniz')
		}),
		onSubmit: (values) => {
			if (isEdit) {
				const newTodo = {
					name: values.name,
					status: switchStatus === false ? '0' : '1',
					short_name: values.short_name,
					description: values.description,
					code: values.code,
					media: selectedFile
				};

				updateCompany({
					data: newTodo,
					id: company.id
				});
			} else if (!isEdit) {
				const newTodo = {
					name: values.name,
					status: switchStatus === false ? '0' : '1',
					short_name: values.short_name,
					description: values.description,
					code: values.code,
					media: selectedFile
				};

				addCompany(newTodo);
			}
		}
	});

	// Toast Notifications Start
	useEffect(() => {
		isAddLoading ? toast.loading('Şirket ekleniyor...') : toast.dismiss();

		if (isAddSucces) {
			toast.success('Şirket başarıyla eklendi');
			refetch();
			setAddModal(false);
			setPreview(null);
		}
		if (isAddError) {
			toast.error('Şirket eklenirken bir hata oluştu');
		}
	}, [isAddLoading, isAddSucces, isAddError, isUpdateLoading, refetch]);

	useEffect(() => {
		isUpdateLoading
			? toast.loading('Şirket güncelleniyor...')
			: toast.dismiss();

		if (isUpdateSuccess) {
			toast.success('Şirket başarıyla güncellendi');
			refetch();
			setAddModal(false);
			setPreview(null);
			validation.resetForm();
			toggle();
		}
		if (isUpdateError) {
			toast.error('Şirket güncellenirken bir hata oluştu');
		}
	}, [isUpdateLoading, isUpdateSuccess, isUpdateError, refetch]);

	useEffect(() => {
		isDeleteLoading ? toast.loading('Şirket siliniyor...') : toast.dismiss();

		if (isDeleteSuccess) {
			toast.success('Şirket başarıyla silindi');
			refetch();
		}
		if (isDeleteError) {
			toast.error('Şirket silinirken bir hata oluştu');
		}
	}, [isDeleteLoading, isDeleteSuccess, isDeleteError, refetch]);

	const handleEdit = (arg) => {
		setCompany({
			id: company.id,
			name: company.name,
			code: company.code,
			status: switchStatus,
			description: company.description,
			short_name: company.short_name,
			media: company.media
		});

		setIsEdit(true);
		setAddModal(true);
		setPreview(null);
	};

	const handleAdd = () => {
		setCompany('');
		setAddModal(!addModal);
		setIsEdit(false);
		toggle();
	};

	const columnData = [
		{
			id: 1,
			name: 'Kod',
			value: 'code'
		},
		{
			id: 2,
			name: 'Şirket Adı',
			value: 'name'
		},
		{
			id: 3,
			name: 'Kısa Kod',
			value: 'short_name'
		},
		{
			id: 4,
			name: 'Durumu',
			value: 'status'
		},
		{
			id: 5,
			name: 'Eylem',
			value: 'actions'
		}
	];

	return (
		<>
			<TableComponent
				data={memoData}
				handleEdit={handleEdit}
				openDeleteModal={openDeleteModal}
				isLoading={isLoading}
				isFetching={isFetching}
				columnData={columnData}
				filter='true'
				handleAdd={handleAdd}
				limit={limit}
				handlePageChange={handlePageChange}
				setPage={setPage}
				page={page}
				pagination={pagination}
				setLimit={setLimit}
				search={search}
				setSearch={setSearch}
				addData={true}
				setDebounceSearch={setDebounceSearch}
			/>

			<DeleteModal
				show={deleteModal}
				onDeleteClick={handleDeleteEvent}
				onCloseClick={() => setDeleteModal(false)}
				dataId={dataId}
				loading={isDeleteLoading}
			/>

			<AddCompanyModal
				switchStatus={switchStatus}
				validation={validation}
				addModal={addModal}
				isEdit={isEdit}
				visibility={visibility}
				setSwitchStatus={setSwitchStatus}
				imageUploader={imageUploader}
				setAddModal={setAddModal}
				setVisibility={setVisibility}
				preview={preview}
				handleFile={handleFile}
				id='createTask'
				dataId={company}
			/>
		</>
	);
};

export default List2;
