import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Container, Row, Spinner } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import TableComponent from '../../Components/ui/table/Table';
import { useGetAllTransactionsCodeQuery } from '../../store/api/transactionsCode';
import { useHistory } from 'react-router-dom';
// import List from './List';

const TransactionsCode = () => {
	document.title = 'Ürün Kodları | Yeditepe Go';
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [data, setData] = useState([]);
	const [debounceSearch, setDebounceSearch] = useState('');
	const [pagination, setPagination] = useState({
		currentPage: 1,
		hasNextPage: false,
		hasPreviousPage: false,
		total: 0,
		totalPages: 0
	});

	const {
		data: countriesData,
		isLoading,
		isFetching,
		isSuccess,
		refetch
	} = useGetAllTransactionsCodeQuery({
		search: debounceSearch ? debounceSearch : undefined,
		page: page === 0 ? 1 : page,
		limit: limit
	});

	const columnData = [
		{
			id: 2,
			name: 'Kodu',
			value: 'name'
		},
		{
			id: 1,
			name: 'Eylem',
			value: 'actions'
		}
	];

	const history = useHistory();

	const handleGoToDetail = (item) => {
		const id = item.id;
		history.push(`/transactions-codes/${id}`);
	};

	const memoData = React.useMemo(() => {
		if (isSuccess) {
			setData(countriesData.data.data);
			setPagination({
				total: countriesData.data.total,
				currentPage: countriesData.data.current_page,
				totalPages: countriesData.data.last_page,
				hasNextPage: countriesData.data.next_page_url,
				hasPreviousPage: countriesData.data.prev_page_url ? true : false
			});
		}

		return data;
	}, [isSuccess, countriesData, data]);

	const handlePageChange = (data) => {
		setPage(data);
	};

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<BreadCrumb title='İşlem Kodları' pageTitle='Data Havuzu' />

					<TableComponent
						columnData={columnData}
						data={memoData}
						isLoading={isLoading}
						pagination={pagination}
						limit={limit}
						setLimit={setLimit}
						setPage={setPage}
						setSearch={setSearch}
						search={search}
						filter={true}
						refetch={refetch}
						setDebounceSearch={setDebounceSearch}
						setPagination={setPagination}
						handlePageChange={handlePageChange}
						page={page}
						showStatus={true}
						handleGoToDetail={handleGoToDetail}
						hasDetail={true}
					/>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default TransactionsCode;
