import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Badge,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Nav,
	NavItem,
	NavLink,
	Row,
	Spinner,
	TabContent,
	Table,
	TabPane
} from 'reactstrap';
import classnames from 'classnames';
import {
	useGetAllTransactionsCodeQuery,
	useGetTransactionCodeByIdQuery,
	useGetAirShipmentsFromTransactionCodeQuery,
	useGetMatchedQuestionFromProductCodeAndTargetTypeQuery
} from '../../../store/api/transactionsCode';
//Images
import detailBg from '../../../assets/images/detail-bg.jpg';
import thy from '../../../assets/images/logos/thy.png';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
	useDeleteMatchedQuestionMutation,
	useReOrderMatchedQuestionsMutation
} from '../../../store/api/questions';
import { EditMatchQuesitonModal } from '../../../Components/ui/modals/EditMatchQuestion';
import { toast } from 'react-hot-toast';
import AddQuestionModal from '../../../Components/ui/modals/AddQuestionModal';
import { AddQuestionFromPool } from '../../../Components/ui/modals/AddQuestionFromPool';
import DeleteModal from '../../../Components/Common/DeleteModal';
import moment from 'moment';

const TransactionsCodeDetail = () => {
	const [transactionCode, setTransactionCode] = useState([]);
	const [targetType, setTargetType] = React.useState('0');
	const defaultId = window.location.pathname.split('/')[2];
	const [id, setId] = useState(defaultId);
	document.title = `${id.label} Sorusuna Ait Detaylar| Yeditepe Go`;
	const [activeTab, setActiveTab] = React.useState('0');
	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);

			tab !== 2 && setTargetType(tab);
		}
	};

	const {
		data: transactionCodes,
		isSuccess: transactionCodesSuccess,
		isFetching
	} = useGetAllTransactionsCodeQuery({
		page: 1,
		limit: 1000
	});

	const [deleteMatchedQuestionAction, { isLoading: isLoadingDelete }] =
		useDeleteMatchedQuestionMutation();

	useEffect(() => {
		if (transactionCodesSuccess) {
			setTransactionCode(transactionCodes.data.data);
		}
	}),
		[transactionCodesSuccess];

	const { data: transactionCodeData, isSuccess: isSuccessTransactionCode } =
		useGetTransactionCodeByIdQuery(Object.keys(id).includes('id') ? id.id : id);

	useEffect(() => {
		if (isSuccessTransactionCode) {
			setId({
				id: transactionCodeData.data.id,
				label: transactionCodeData.data.name
			});
		}
	}, [isSuccessTransactionCode]);

	const {
		data: airShipmentsData,
		isLoading: isLoadingAirShipments,
		isSuccess: isSuccessAirShipments,
		refetch: refetchAirShipments
	} = useGetAirShipmentsFromTransactionCodeQuery(
		Object.keys(id).includes('id') ? id.id : id
	);

	const [airShipments, setAirShipments] = useState([]);

	useEffect(() => {
		if (isSuccessAirShipments) {
			setAirShipments(airShipmentsData.data.data);
		}
	}, [isSuccessAirShipments]);

	const {
		data: matchedQuestion,
		isLoading: isLoadingMatchedQuestion,
		isSuccess: isSuccessMatchedQuestion,
		isError: isErrorMatchedQuestion
	} = useGetMatchedQuestionFromProductCodeAndTargetTypeQuery(
		{
			productCode: Object.keys(id).includes('id') ? id.id : id,
			targetType: activeTab
		},
		{
			keepPreviousData: true
		}
	);

	const [
		reOrder,
		{
			data: reOrderData,
			isLoading: isLoadingReOrder,
			isSuccess: isSuccessReOrder,
			isError: isErrorReOrder
		}
	] = useReOrderMatchedQuestionsMutation();

	useEffect(() => {
		if (isLoadingReOrder) {
			toast.loading('Sıralama güncelleniyor');
		} else {
			toast.dismiss();
		}
	}, [isLoadingReOrder]);

	useEffect(() => {
		if (isSuccessReOrder) {
			toast.success('Sıralama başarıyla güncellendi');
		}

		if (isErrorReOrder) {
			toast.error('Sıralama güncellenemedi');
		}
	}, [isSuccessReOrder, isErrorReOrder]);

	const [matchedQuestions, setMatchedQuestions] = useState([]);

	useEffect(() => {
		setMatchedQuestions(matchedQuestion?.data.data);
	}, [
		isSuccessMatchedQuestion,
		activeTab,
		isErrorMatchedQuestion,
		matchedQuestion
	]);

	const handleDragEnd = (result) => {
		if (!result.destination) return;
		const items = Array.from(matchedQuestions);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setMatchedQuestions(items);
		reOrder(items.map((item) => item.id));
	};

	const [questionId, setQuestionId] = useState('');

	const [addModal, setAddModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const editMatchedQuestion = (id) => {
		setQuestionId(id);
		setAddModal((prevState) => !prevState);
	};

	const deleteMatchedQuestion = (id) => {
		setQuestionId(id);
		setDeleteModal((prevState) => !prevState);
	};

	const [addQuestionModal, setAddQuestionModal] = useState(false);
	const [showAddQuestionModalFromPool, setShowAddQuestionModalFromPool] =
		useState(false);

	const handleOpenAddQuestionModal = () => {
		setAddQuestionModal((prevState) => !prevState);
	};

	const handleOpenAddQuestionFromPoolModal = () => {
		setShowAddQuestionModalFromPool((prevState) => !prevState);
	};

	const onDeleteClick = () => {
		deleteMatchedQuestionAction(questionId);
		setDeleteModal((prevState) => !prevState);
	};

	return (
		<React.Fragment>
			{isLoadingMatchedQuestion ? (
				<div className='page-content w-100 h-100 d-flex align-items-center justify-content-center'>
					<Container fluid>
						<Spinner color='primary' />
					</Container>
				</div>
			) : (
				<div className='page-content'>
					<Container fluid>
						<div className='profile-foreground position-relative mx-n4 mt-n4'>
							<div className='profile-wid-bg'>
								<img src={detailBg} alt='' className='profile-wid-img' />
							</div>
						</div>
						<div className='pt-4 mb-4 mb-lg-3 pb-lg-4'>
							<Row className='g-4'>
								<Col>
									<div className='p-2'>
										<h3 className='text-white mb-1'>
											{id.label} Türündeki Sorulara Ait Detaylar
										</h3>
									</div>
								</Col>

								<Col xs={12} lg={3} className=' order-last order-lg-0'>
									<Row className='text text-white-50 text-center'>
										<Col lg={6} xs={6}></Col>
										{activeTab <= 1 ? (
											<Col lg={6} xs={4}>
												<div className='p-2'>
													<h4 className='text-white mb-1'>
														{matchedQuestions?.length}
													</h4>
													<p className='fs-14 mb-0'>Adet</p>
												</div>
											</Col>
										) : null}
									</Row>
								</Col>
							</Row>
						</div>

						<Row>
							<Col lg={12}>
								<div>
									<div className='d-flex'>
										<Nav
											pills
											className='animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1'
											role='tablist'
										>
											<NavItem>
												<NavLink
													className={classnames(
														{ active: activeTab === '0' },
														'fs-14 cursor-pointer'
													)}
													onClick={() => {
														toggleTab('0');
													}}
												>
													<i className='ri-airplay-fill d-inline-block d-md-none'></i>{' '}
													<span className='d-none d-md-inline-block'>
														Sevkiyat Tipindeki Sorular
													</span>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames(
														{ active: activeTab === '1' },
														'fs-14 cursor-pointer'
													)}
													onClick={() => {
														toggleTab('1');
													}}
												>
													<i className='ri-list-unordered d-inline-block d-md-none'></i>{' '}
													<span className='d-none d-md-inline-block'>
														Araç Tipindeki Sorular
													</span>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames(
														{ active: activeTab === '2' },
														'fs-14 cursor-pointer'
													)}
													onClick={() => {
														toggleTab('2');
													}}
												>
													<i className='ri-price-tag-line d-inline-block d-md-none'></i>{' '}
													<span className='d-none d-md-inline-block'>
														Sevkiyat Detayları
													</span>
												</NavLink>
											</NavItem>
										</Nav>

										<div className='d-flex align-items-center'>
											<Button
												color='secondary'
												className='btn-md d-flex align-items-center'
												onClick={() => {
													handleOpenAddQuestionModal();
												}}
											>
												<i className='ri-add-line me-1'></i> Yeni Soru Ekle
											</Button>

											<Button
												color='success'
												className='btn-md ms-2 d-flex align-items-center'
												onClick={() => {
													handleOpenAddQuestionFromPoolModal();
												}}
											>
												<i className='ri-add-line me-1'></i> Havuzdan Soru
												Eşleştir
											</Button>
										</div>
									</div>

									<TabContent activeTab={activeTab} className='pt-4'>
										<EditMatchQuesitonModal
											addModal={addModal}
											setAddModal={setAddModal}
											questionId={questionId}
										/>

										<TabPane tabId='0'>
											<CustomTable
												data={matchedQuestions}
												handleDragEnd={handleDragEnd}
												editMatchedQuestion={editMatchedQuestion}
												deleteMatchedQuestion={deleteMatchedQuestion}
											/>
										</TabPane>

										<TabPane tabId='1'>
											<EditMatchQuesitonModal
												addModal={addModal}
												setAddModal={setAddModal}
												questionId={questionId}
											/>

											<CustomTable
												data={matchedQuestions}
												handleDragEnd={handleDragEnd}
												editMatchedQuestion={editMatchedQuestion}
												deleteMatchedQuestion={deleteMatchedQuestion}
											/>
										</TabPane>
										<TabPane tabId='2'>
											<Card>
												<CardBody>
													<Table className='table table-centered table-nowrap mb-0'>
														<thead className='table-head'>
															<tr>
																<th>Firma</th>
																<th>İthalatçı</th>
																<th>HAWB</th>
																<th>Kalkış / Varış Yeri</th>
																<th>Kalkış Zamanı</th>
																<th>Durumu</th>
																<th>Aksiyon</th>
															</tr>
														</thead>
														<tbody>
															{airShipments.map((shipment, index) => (
																<tr key={index}>
																	<td>
																		<img
																			src={
																				'../../../assets/images/logos/' +
																				shipment?.carrier.num_code +
																				'.svg'
																			}
																			alt=''
																			height='24'
																			style={{ borderRadius: '50%' }}
																		/>
																	</td>
																	<td>{shipment?.shipper_detail.name}</td>
																	<td>{shipment?.mawb_code}</td>
																	<td>
																		{shipment?.port_departure} /{' '}
																		{shipment?.port_arrival}
																	</td>
																	<td>
																		{moment(shipment?.option_date).format(
																			'DD.MM.YYYY'
																		)}
																	</td>
																	<td>
																		{shipment?.status === 0 ? (
																			<Badge color='success' pill>
																				Aktif
																			</Badge>
																		) : shipment?.status === 1 ? (
																			<Badge color='warning' pill>
																				Beklemede
																			</Badge>
																		) : shipment?.status === 2 ? (
																			<Badge color='danger' pill>
																				İptal
																			</Badge>
																		) : shipment?.status === 3 ? (
																			<Badge color='primary' pill>
																				Tamamlandı
																			</Badge>
																		) : shipment?.status === 4 ? (
																			<Badge color='secondary' pill>
																				İade
																			</Badge>
																		) : null}
																	</td>
																	<td>
																		<Link
																			to={`/shipments/detail/${shipment?.id}`}
																		>
																			<Button color='primary' size='sm'>
																				Detay
																			</Button>
																		</Link>
																	</td>
																</tr>
															))}
														</tbody>
													</Table>
												</CardBody>
											</Card>
										</TabPane>
									</TabContent>

									<AddQuestionModal
										addModal={addQuestionModal}
										setAddModal={setAddQuestionModal}
									/>

									<DeleteModal
										show={deleteModal}
										setDeleteModal={setDeleteModal}
										onDeleteClick={onDeleteClick}
									/>

									<AddQuestionFromPool
										addModal={showAddQuestionModalFromPool}
										setAddModal={setShowAddQuestionModalFromPool}
										dataId={Object.keys(id).includes('id') ? id.id : id}
										targetType={targetType}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			)}
		</React.Fragment>
	);
};

const CustomTable = ({
	data,
	handleDragEnd,
	editMatchedQuestion,
	deleteMatchedQuestion
}) => {
	return (
		<Row>
			<Col xxl={12}>
				<Card>
					<CardBody>
						<div className='table-responsive'>
							<DragDropContext onDragEnd={handleDragEnd}>
								<Table className='table table-centered table-nowrap mb-0'>
									<thead className='table-head'>
										<tr>
											<th></th>
											<th>Soru Adı</th>
											<th>Soru Türü</th>
											<th>Fotoğraf Zorunluluğu</th>
											<th>Maksimum Değer</th>
											<th>Minimum Değer</th>
											<th>Merkez Onayı</th>
											<th>Aksiyon</th>
										</tr>
									</thead>
									<Droppable droppableId='droppable-1'>
										{(provider) => (
											<tbody
												className='text-capitalize'
												ref={provider.innerRef}
												{...provider.droppableProps}
											>
												{data?.map((questions, index) => (
													<Draggable
														key={questions.id}
														draggableId={questions.id}
														index={index}
													>
														{(provider) => (
															<tr
																{...provider.draggableProps}
																ref={provider.innerRef}
															>
																<td {...provider.dragHandleProps}> = </td>
																<td>{questions?.question?.translation.name}</td>
																<td>
																	{questions?.question?.question_type_string}
																</td>

																<td>
																	{questions?.question?.image_required_string}
																</td>

																{questions?.question?.max_value !== null &&
																questions?.question?.min_value !== null ? (
																	<>
																		<td>{questions?.question?.max_value}</td>
																		<td>{questions?.question?.min_value}</td>
																	</>
																) : (
																	<>
																		<td></td>
																		<td></td>
																	</>
																)}

																<td>
																	{
																		questions?.question
																			?.headquarters_approval_string
																	}
																</td>

																<td className='d-flex align-middle justify-content-center items-center gap-2'>
																	<Button
																		color='primary'
																		onClick={() => {
																			editMatchedQuestion(questions?.id);
																		}}
																		className='d-flex align-middle justify-content-center items-center gap-2'
																	>
																		<i className='ri-pencil-line  mx-1'></i>
																		Düzenle
																	</Button>

																	<Button
																		color='danger'
																		onClick={() => {
																			deleteMatchedQuestion(questions?.id);
																		}}
																		className='d-flex align-middle justify-content-center items-center gap-2'
																	>
																		<i className='ri-delete-bin-6-line  mx-1'></i>
																		Sil
																	</Button>
																</td>
															</tr>
														)}
													</Draggable>
												))}
												{provider.placeholder}
											</tbody>
										)}
									</Droppable>
								</Table>
							</DragDropContext>
						</div>
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};

export { TransactionsCodeDetail };
