import apiMiddleware from './middleware/ApiMiddleware';

export const airportsApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getAllAirports: builder.query({
			query: (filter) => ({
				url: '/air-ports/get-filtered',
				params: filter
			})
		}),
		getAirportsByCountry: builder.query({
			query: (country) => ({
				url: `/air-ports/get-by-country/${country}`
			})
		})
	})
});

export const { useGetAllAirportsQuery, useGetAirportsByCountryQuery } =
	airportsApi;
