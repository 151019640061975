import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Select from 'react-select';
import {
	useGetProductCodesQuery,
	useGetQuestionByIdQuery,
	useGetMatchedQuestionByIdQuery
} from '../../../store/api/questions';

const MatchedQuestionModal = ({
	addModal,
	isEdit,
	dataId,
	setAddModal,
	data
}) => {
	const [questionId, setQuestionId] = useState();

	useEffect(() => {
		if (data) {
			setQuestionId(data.id);
		}
	}, [data]);

	const { data: question, isSuccess: questionSuccess } =
		useGetQuestionByIdQuery(questionId, {
			skip: !dataId && !addModal && !isEdit && questionId === undefined
		});

	const { data: matchedQuestions, isSuccess: matchedQuestionsSuccess } =
		useGetMatchedQuestionByIdQuery(questionId, {
			skip: questionId === undefined
		});

	const [matchedQuestion, setMatchedQuestion] = useState([]);

	useEffect(() => {
		if (matchedQuestionsSuccess) {
			setMatchedQuestion(matchedQuestions.data);
		}
	}, [matchedQuestionsSuccess]);

	const [questionData, setQuestionData] = useState({});

	useEffect(() => {
		if (questionSuccess) {
			setQuestionData(question);
		}
	}, [questionSuccess]);

	const closeModal = () => {
		setAddModal((prevState) => !prevState);
	};

	const [productCode, setProductCode] = useState([]);

	const {
		data: productCodes,
		isLoading: productCodesLoading,
		isSuccess: productCodesSuccess
	} = useGetProductCodesQuery({
		skip: !dataId
	});

	return (
		<Modal
			id={dataId ? dataId : 'addQuestionModal'}
			isOpen={addModal}
			toggle={closeModal}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
			role='dialog'
			aria-hidden='true'
			size='xl'
		>
			<ModalHeader toggle={() => setAddModal(false)} className='p-3 bg-white'>
				<span>Soru:</span> {questionData?.translation?.name}
			</ModalHeader>
			<ModalBody>
				<div className='table-responsive'>
					<table className='table table-hover table-striped table-bordered'>
						<thead>
							<tr>
								<th scope='col'>Ürün Kodu</th>
								<th scope='col'>Sevkitat Türü</th>
								<th scope='col'>Soru Tipi</th>
								<th scope='col'>Doğru Takip Sorusu</th>
								<th scope='col'>Yanlış Takip Sorusu</th>
								<th scope='col'>Takip Sorusu</th>
								<th scope='col'>İşlemler</th>
							</tr>

							{matchedQuestionsSuccess &&
								matchedQuestion?.map((item, index) => (
									<tr key={index}>
										<td scope='col'>
											<Select
												className='react-select'
												classNamePrefix='react-select'
												name='product_code'
												value={item?.product_code}
												options={productCodes.data}
												getOptionLabel={(option) => option?.label}
												getOptionValue={(option) => option?.value}
												onChange={(value) => {
													setProductCode(value);
												}}
											/>
										</td>
										<td scope='col'>{item?.target_type?.label}</td>
										<td scope='col'>{item?.question_type_string}</td>
										<td scope='col'>
											{item?.true_follow_up_question_id?.true_question_string}
										</td>
										<td scope='col'>
											{item?.false_follow_up_question_id?.false_question_string}
										</td>
										<td scope='col'>
											{item?.follow_up_question_id?.follow_question_string}
										</td>
										<td scope='col' className='d-flex gap-2'>
											<Button color='primary' size='sm'>
												Düzenle
											</Button>

											<Button color='danger' size='sm'>
												Sil
											</Button>
										</td>
									</tr>
								))}
						</thead>
						<tbody></tbody>
					</table>
				</div>
			</ModalBody>

			<ModalFooter>
				<Button color='secondary' onClick={() => setAddModal(false)}>
					Kapat
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export { MatchedQuestionModal };
