import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import List from './List';
import Widgets from './Widgets';

const DashboardCrm = () => {
	document.title = 'Onay Bekleyen Sevkiyatlar | Yeditepe Go';
	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<BreadCrumb
						title='Onay Bekleyen Sevkiyatlar'
						pageTitle='Sevkiyatlar'
					/>
					<Row>
						<Widgets />
					</Row>
					<Row>
						<List />
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default DashboardCrm;
