import apiMiddleware from './middleware/ApiMiddleware';

export const authApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (data) => ({
				url: 'auth/login',
				method: 'POST',
				body: data
			})
		}),

		logout: builder.mutation({
			query: () => ({
				url: 'logout',
				method: 'POST'
			})
		}),

		forgetPassword: builder.mutation({
			query: (data) => ({
				url: 'auth/password/forgot',
				method: 'POST',
				body: data
			}),

			headers: {
				Accept: 'application/json'
			}
		}),

		passwordSlugVerification: builder.mutation({
			query: (data) => ({
				url: `/auth/change-password/${data}`,
				method: 'GET'
			})
		}),

		resetPassword: builder.mutation({
			query: ({ ...patch }) => ({
				url: 'auth/password/reset',
				method: 'POST',
				body: patch,
				headers: {
					Accept: 'application/json'
				}
			})
		}),

		refreshToken: builder.mutation({
			query: ({ ...patch }) => ({
				url: 'auth/refresh',
				method: 'POST',
				body: patch
			}),
			invalidatesTags: ['Auth']
		})
	})
});

export const {
	useLoginMutation,
	useLogoutMutation,
	useForgetPasswordMutation,
	usePasswordSlugVerificationMutation,
	useResetPasswordMutation,
	useRefreshTokenMutation
} = authApi;
