import React, { useEffect, useState } from 'react';

import { Container } from 'reactstrap';
import {
	useGetAllPermissionFilteredQuery,
	useDeletePermissionMutation
} from '../../../store/api/roles';

import DeleteModal from '../../../Components/Common/DeleteModal';
import TableComponent from '../../../Components/ui/table/Table';
import AddRoleModal from '../../../Components/ui/modals/AddRoleModal';
import EditRoleModal from '../../../Components/ui/modals/EditRoleModal';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import AddPermissionModal from '../../../Components/ui/modals/AddPermissionModal';

const Role = ({ active }) => {
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [limit, setLimit] = useState(10);
	const [modal, setModal] = useState(false);
	const [dataId, setDataId] = useState(null);
	const [company, setCompany] = useState(null);
	const [addModal, setAddModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [debounceSearch, setDebounceSearch] = useState('');
	const [deleteModal, setDeleteModal] = useState(false);

	//Pagnation
	const [pagination, setPagination] = useState({
		currentPage: 1,
		hasNextPage: false,
		hasPreviousPage: false,
		total: 0,
		totalPages: 0
	});

	const {
		data: dataArray,
		isLoading,
		isFetching,
		isSuccess
	} = useGetAllPermissionFilteredQuery(
		{
			search: search ? search : undefined,
			page: page,
			limit: limit
		},
		{
			skip: !active
		}
	);

	const [deletePermission, { isLoading: isDeleteLoading }] =
		useDeletePermissionMutation({
			onSuccess: () => {
				toast.success('İzin başarıyla silindi');
			},
			onError: () => {
				toast.error('İzin silinirken bir hata oluştu');
			}
		});

	const handlePageChange = (data) => {
		setPage(data);
	};

	useEffect(() => {
		if (isSuccess) {
			setData(dataArray.data.data);
			setPagination({
				total: dataArray.data.total,
				currentPage: dataArray.data.current_page,
				totalPages: dataArray.data.last_page,
				hasNextPage: dataArray.data.next_page_url,
				hasPreviousPage: dataArray.data.prev_page_url ? true : false
			});
		}
	}, [dataArray, isSuccess]);

	const toggle = () => {
		if (modal) {
			setModal(false);
		} else {
			setModal(true);
		}
	};

	const openDeleteModal = (id) => {
		setDataId(id);

		setDeleteModal(true);
	};

	const handleAdd = () => {
		setCompany('');
		setAddModal(!addModal);
		setIsEdit(false);
		toggle();
	};

	const handleDeleteEvent = () => {
		deletePermission(dataId);
		setDeleteModal(false);
		toggle();
	};

	const columnData = [
		{
			id: 2,
			name: 'Adı',
			value: 'translation'
		},

		{
			id: 3,
			name: 'Oluşturma Tarihi',
			value: 'created_at'
		},
		{
			id: 4,
			name: 'Düzenle',
			value: 'actions'
		}
	];

	const handleEdit = (id) => {
		setDataId(id);
		setIsEdit(true);
		setAddModal(true);
	};

	return (
		<>
			<TableComponent
				data={data}
				isLoading={isLoading}
				isFetching={isFetching}
				columnData={columnData}
				filter='true'
				handleAdd={handleAdd}
				handleEdit={handleEdit}
				limit={limit}
				handlePageChange={handlePageChange}
				setPage={setPage}
				page={page}
				pagination={pagination}
				setLimit={setLimit}
				search={search}
				setSearch={setSearch}
				addData={true}
				setDebounceSearch={setDebounceSearch}
				openDeleteModal={openDeleteModal}
				addButtonText='Rol Ekle'
			/>

			<DeleteModal
				show={deleteModal}
				onDeleteClick={handleDeleteEvent}
				onCloseClick={() => setDeleteModal(false)}
				dataId={dataId}
				isLoading={isDeleteLoading}
			/>

			<AddPermissionModal
				addModal={addModal}
				setAddModal={setAddModal}
				dataId={dataId}
				isEdit={isEdit}
				handleEdit={handleEdit}
				toggle={toggle}
			/>

			{/* <EditRoleModal
				modal={modal}
				toggle={toggle}
				isEdit={isEdit}
				dataId={dataId}
				addModal={editModal}
				setAddModal={setEditModal}
			/> */}
		</>
	);
};

export default Role;
