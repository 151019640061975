import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	ModalFooter
} from 'reactstrap';

import Select from 'react-select';
import {
	useAddRoleMutation,
	useGetAllModulesQuery
} from '../../../store/api/roles';
import { toast } from 'react-hot-toast';
import { useGetAllCompaniesQuery } from '../../../store/api/companies';
import LanguagesTab from '../../Common/MultiLanguageTab';

export const AddRoleModal = ({ addModal, setAddModal, isEdit, dataId }) => {
	const [checked, setChecked] = useState(true);
	const [selectedPermissions, setSelectedPermissions] = useState([]);

	const [roles, setRoles] = useState({
		translations: [],
		company_id: null,
		customer_id: null
	});

	const {
		data: companies,
		isSuccess: isCompaniesSuccess,
		refetch
	} = useGetAllCompaniesQuery(undefined, {
		skip: !addModal
	});

	const companyOptions = companies?.data?.map((company) => ({
		value: company.id,
		label: company.name
	}));

	const [
		createRole,
		{
			isSuccess: isAddRoleSuccess,
			isLoading: isAddRoleLoading,
			isError: isAddRoleError,
			error: addRoleError
		}
	] = useAddRoleMutation();

	const handleFormSubmit = (e) => {
		e.preventDefault();

		createRole({
			...roles,
			status: checked === true ? 1 : 0,
			modules: selectedPermissions
		});
	};

	// Toast Notifications Start
	useEffect(() => {
		isAddRoleLoading ? toast.loading('Rol ekleniyor...') : toast.dismiss();

		if (isAddRoleSuccess) {
			toast.success('Rol başarıyla eklendi');
			refetch();
			setAddModal(false);
		}
		if (isAddRoleError) {
			toast.error(addRoleError?.data?.message);
		}
	}, [
		isAddRoleLoading,
		isAddRoleSuccess,
		isAddRoleError,
		refetch,
		setAddModal
	]);

	const handleSwitch = (e) => {
		setChecked(e.target.checked);
	};

	const { data: moduleData, isSuccess: moduleDataIsSuccess } =
		useGetAllModulesQuery(undefined, {
			skip: !addModal
		});

	const [roleModule, setRoleModule] = useState([]);

	useEffect(() => {
		if (moduleDataIsSuccess) {
			setRoleModule(moduleData.data);
		}
	}, [moduleDataIsSuccess, moduleData]);

	const handleRoleChange = (e, language, name) => {
		setRoles({
			...roles,
			translations: {
				...roles.translations,
				[language.id]: {
					...roles.translations[language.id],
					[name]: e.target.value
				}
			}
		});
	};

	return (
		<Modal
			id='RoleModal'
			isOpen={addModal}
			toggle={() => setAddModal((prevState) => !prevState)}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
			size='xl'
		>
			<ModalHeader toggle={() => setAddModal(false)} className='p-3 bg-white'>
				{isEdit ? 'Rolü Düzenle' : 'Rol Oluştur'}
			</ModalHeader>
			<ModalBody>
				<LanguagesTab
					roles={roles}
					setRoles={setRoles}
					handleRoleChange={handleRoleChange}
					type='Rol'
				/>
				<Form onSubmit={(e) => handleFormSubmit(e)}>
					<Row>
						<Col md={6} className='mb-4'>
							<label>Şirket</label>
							<Select
								placeholder='Şirket Seçiniz'
								onChange={(value, removedValue) => {
									if (removedValue.action === 'clear') {
										setRoles({
											...roles,
											company_id: null
										});
									}

									setRoles({
										...roles,
										company_id: value.value
									});
								}}
								value={
									companyOptions?.find(
										(company) => company.value === roles.company_id
									) || null
								}
								options={companyOptions}
								isDisabled={roles.customer_id ? true : false}
								isClearable
							/>
						</Col>

						<Col md={6} className='mb-4'>
							<label>Müşteri</label>
							<Select
								placeholder='Müşteri Seçiniz'
								onChange={(value, removedValue) => {
									if (removedValue.action === 'clear') {
										setRoles({
											...roles,
											customer_id: null
										});
									}

									setRoles({
										...roles,
										customer_id: value.value
									});
								}}
								value={
									companyOptions?.find(
										(company) => company.value === roles.customer_id
									) || null
								}
								options={companyOptions}
								isDisabled={roles.company_id ? true : false}
								isClearable
							/>
						</Col>

						<hr className='mb-3' />

						<Col md={12} className='mb-4'>
							<SelectModuleAndRole
								roles={roles}
								setRoles={setRoles}
								roleModules={roleModule}
								selectedPermissions={selectedPermissions}
								setSelectedPermissions={setSelectedPermissions}
							/>
						</Col>

						<hr />
						<Col md={6}>
							<label>Durumu</label>
							<div className='mb-4 form-check form-switch mb-2'>
								<Input
									className='form-check-input'
									type='checkbox'
									role='switch'
									id='flexSwitchCheckDefault'
									name='isActive'
									onChange={(e) => handleSwitch(e)}
									checked={checked}
								/>

								<label
									className='form-check-label'
									htmlFor='flexSwitchCheckDefault'
								>
									{checked ? 'Aktif' : 'Pasif'}
								</label>
							</div>
						</Col>
					</Row>

					<ModalFooter className='p-3 bg-white'>
						<Button color='primary' type='submit'>
							{isEdit ? 'Düzenle' : 'Oluştur'}
						</Button>
					</ModalFooter>
				</Form>
			</ModalBody>
		</Modal>
	);
};

const SelectModuleAndRole = ({
	roleModules,
	selectedPermissions,
	setSelectedPermissions
}) => {
	const prepareModule = roleModules?.map((item) => {
		const permissions = item?.permissions?.map((role) => {
			return {
				label: role?.translation?.name,
				value: role?.translation?.permission_id
			};
		});

		// add an option to select all permissions for this module
		const allPermissionsOption = {
			label: 'Hepsini Seç',
			value: 'all'
		};

		return {
			moduleId: item?.value,
			key: item?.key,
			permissions: [allPermissionsOption, ...permissions], // add the all option to the beginning of the array
			name: item?.translation?.name
		};
	});

	const handleSelectedPermission = (e, moduleId) => {
		let selectedValues = [];

		// check if the "hepsini seç" option is selected
		if (e.some((option) => option.value === 'all')) {
			// select all permissions for this module
			selectedValues = prepareModule
				.find((item) => item.moduleId === moduleId)
				.permissions.filter((option) => option.value !== 'all')
				.map((option) => option.value);
		} else {
			selectedValues = e.map((item) => item.value);
		}

		setSelectedPermissions({
			...selectedPermissions,
			[moduleId]: selectedValues
		});
	};

	return (
		<Row>
			{prepareModule?.map((item, key) => {
				return (
					<Col md={4} className='mb-4' key={key}>
						<label>{item.name}</label>
						<Select
							placeholder='Rol Seçiniz'
							onChange={(e) => handleSelectedPermission(e, item.moduleId)}
							isClearable={true}
							isMulti
							options={item.permissions}
						/>
					</Col>
				);
			})}
		</Row>
	);
};

export default AddRoleModal;
