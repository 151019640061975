import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col } from 'reactstrap';

import {
	useGetAllVehiclesQuery,
	useGetCompletedVehiclesQuery,
	useGetVehicleByFilteredQuery
} from '../../../store/api/vehicle';
import TableComponent from '../../../Components/ui/table/Table';

const List = () => {
	const [data, setData] = React.useState([]);
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [pagination, setPagination] = useState({
		total: 0,
		currentPage: 1,
		totalPages: 0,
		hasNextPage: false,
		hasPreviousPage: false
	});

	const {
		data: list,
		isLoading,
		isFetching,
		isSuccess
	} = useGetAllVehiclesQuery({
		search: search ? search : undefined,
		page: page,
		limit: limit
	});

	const columnData = [
		{
			id: 1,
			name: 'Plaka',
			value: 'plate'
		},
		{
			id: 2,
			name: 'Bağlı Sevkiyat',
			value: 'shipment_detail'
		}
	];

	const handlePageChange = (data) => {
		setPage(data);
	};

	useEffect(() => {
		if (isSuccess) {
			setData(list.data.data);
			setPagination({
				total: list.data.total,
				currentPage: list.data.current_page,
				totalPages: list.data.last_page,
				hasNextPage: list.data.next_page_url,
				hasPreviousPage: list.data.prev_page_url ? true : false
			});
		}
	}, [isSuccess, data]);

	return (
		<Col xl={12}>
			<Card className='card-height-100'>
				{isLoading ? (
					<CardBody className='h-100'>
						<div className='spinner-border text-primary' role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					</CardBody>
				) : data.length > 0 ? (
					<CardBody className='h-100'>
						<TableComponent
							data={data}
							isLoading={isLoading}
							isFetching={isFetching}
							columnData={columnData}
							filter='true'
							// handleAdd={handleAdd}
							// handleEdit={handleEdit}
							limit={limit}
							handlePageChange={handlePageChange}
							setPage={setPage}
							page={page}
							pagination={pagination}
							setLimit={setLimit}
							search={search}
							setSearch={setSearch}
							// addData={true}
							// setDebounceSearch={setDebounceSearch}
						/>
					</CardBody>
				) : (
					<CardBody className='h-100'>
						<Col>
							<div className='text-center'>
								<div className='mb-4'>
									<i className='ri-file-list-3-line text-primary display-4' />
								</div>
								<h5>Hiçbir kayıt bulunamadı</h5>
								<p className='text-muted'>
									{list?.data?.length === 0 && 'Liste boş'}
								</p>
							</div>
						</Col>
					</CardBody>
				)}
			</Card>

			<React.Fragment></React.Fragment>
		</Col>
	);
};

export default List;
