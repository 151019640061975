import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { dashboardWidgets } from './data';

const Widgets = ({ data }) => {
	return (
		<React.Fragment>
			{dashboardWidgets.map((item, key) => (
				<Col xl={3} md={6} key={key}>
					<Card className={'card-animate card-height-100'}>
						<CardBody>
							<div className='d-flex justify-content-between'>
								<div>
									<p className={'fw-medium mb-0 text-muted'}>{item.label}</p>
									<h2
										className={
											'mt-4 ff-secondary fw-semibold ' + item.counterClass
										}
									>
										{data != null && (
											<>
												{data[item.variable] ? data[item.variable] : 0}
												{item.subCounter.map((item, key) => (
													<span className='counter-value' key={key}>
														{/* <CountUp
															start={0}
															separator={item.separator}
															end={
																data[item.variable] != undefined
																	? data[item.variable]
																	: 0
															}
															duration={2}
														/> */}
													</span>
												))}
											</>
										)}
									</h2>
								</div>
								<div>
									<div className={'avatar-sm flex-shrink-0'}>
										<span
											className={
												'avatar-title rounded-circle fs-2 shadow ' +
												item.iconBgClass
											}
										>
											<i className={item.iconClass}></i>
										</span>
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</Col>
			))}
		</React.Fragment>
	);
};

export default Widgets;
