import React, { useEffect, useState } from 'react';
import { Container, Row, Spinner } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableComponent from '../../../Components/ui/table/Table';
import { useGetTrackerQuery } from '../../../store/api/tracker';
// import List from './List';

const SystemLogs = () => {
	document.title = 'Sistem Logları | Yeditepe Go';
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [data, setData] = useState([]);
	const [debounceSearch, setDebounceSearch] = useState('');
	const [pagination, setPagination] = useState({
		currentPage: 1,
		hasNextPage: false,
		hasPreviousPage: false,
		total: 0,
		totalPages: 0
	});

	const {
		data: trackerData,
		isLoading,
		isFetching,
		isSuccess,
		refetch
	} = useGetTrackerQuery({
		search: debounceSearch ? debounceSearch : undefined,
		page: page === 0 ? 1 : page,
		limit: limit
	});

	const columnData = [
		{
			id: 1,
			name: 'İp Adresi',
			value: 'ip'
		},
		{
			id: 2,
			name: 'Yapılan İstek',
			value: 'url'
		},

		{
			id: 3,
			name: 'İstek Tarihi',
			value: 'created_at'
		},
		{
			id: 4,
			name: 'Cookie Id',
			value: 'cookie_id'
		},
		{
			id: 5,
			name: 'Session Id',
			value: 'session_id'
		}
	];

	const memoData = React.useMemo(() => {
		if (isSuccess) {
			setData(trackerData.data.data);
			setPagination({
				total: trackerData.data.total,
				currentPage: trackerData.data.current_page,
				totalPages: trackerData.data.last_page,
				hasNextPage: trackerData.data.next_page_url,
				hasPreviousPage: trackerData.data.prev_page_url ? true : false
			});
		}

		return data;
	}, [isSuccess, trackerData, data]);

	const handlePageChange = (data) => {
		setPage(data);
	};

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<BreadCrumb title='Sistem Logları' pageTitle='Yönetim' />
					{isLoading || isFetching ? (
						<div className='text-center flex'>
							<Spinner color='primary' />
						</div>
					) : (
						<TableComponent
							columnData={columnData}
							data={memoData}
							isLoading={isLoading}
							pagination={pagination}
							limit={limit}
							setLimit={setLimit}
							setPage={setPage}
							setSearch={setSearch}
							search={search}
							filter={true}
							refetch={refetch}
							setDebounceSearch={setDebounceSearch}
							setPagination={setPagination}
							handlePageChange={handlePageChange}
							page={page}
							totalPages={pagination.totalPages}
						/>
					)}
				</Container>
			</div>
		</React.Fragment>
	);
};

export default SystemLogs;
