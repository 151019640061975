import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useGetAllQuestionsQuery } from '../../../store/api/questions';
import { useGetProductCodesQuery } from '../../../store/api/questions';
import Select from 'react-select';

const MatchedQuestion = () => {
	const { data } = useGetAllQuestionsQuery();

	const { data: productCodes, isSuccess: productCodesSuccess } =
		useGetProductCodesQuery({
			skip: !data
		});

	const [productCode, setProductCode] = useState(null);

	useEffect(() => {
		if (productCodesSuccess) {
			setProductCode(productCodes.data);
		}
	}, [productCodesSuccess]);

	const targetType = [
		{ value: '0', label: 'Sevkiyat' },
		{ value: '1', label: 'Araç' }
	];

	const [targetTypeValue, setTargetTypeValue] = useState(targetType);

	return (
		<>
			<React.Fragment>
				<div className='page-content'>
					<Container fluid>
						<BreadCrumb title='Soru Listesi' pageTitle='Sorular' />
						<table
							className='table table-centered table-nowrap table-hover'
							style={{ marginTop: 20 }}
						>
							<thead>
								<tr>
									<th scope='col'>Soru Adı</th>
									<th scope='col'>Ürün Kodu</th>
									<th scope='col'>Paket Tipi</th>
									<th scope='col'>Eylem</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, key) => (
									<SelectComponent
										key={key}
										item={item}
										options={productCodes?.data}
										value={productCode}
										onChange={setProductCode}
										productCode={productCode}
										targetTypeValue={targetTypeValue}
										targetType={targetType}
										setTargetTypeValue={setTargetTypeValue}
										productCodes={productCodes}
									/>
								))}
							</tbody>
						</table>
					</Container>
				</div>
			</React.Fragment>
		</>
	);
};

const SelectComponent = ({
	item,
	targetTypeValue,
	targetType,
	setTargetTypeValue,
	productCodes,
	key
}) => {
	return (
		<tr key={key}>
			<td>{item.translation.name}</td>
			<td>
				<Select
					onChange={(e) => setProductCode(e)}
					options={productCodes?.data}
					getOptionLabel={(option) => option.name}
					getOptionValue={(option) => option.id}
					placeholder='Ürün Kodu Seçiniz'
					isSearchable
				/>
			</td>
			<td>
				<Select
					value={targetTypeValue}
					onChange={(e) => setTargetTypeValue(e)}
					options={targetType}
					placeholder='Paket Tipi Seçiniz'
					isSearchable
				/>
			</td>
			<td>
				<div className='d-flex w-100 '>
					<button type='button w-100 ' className='btn btn-primary btn-sm'>
						Kaydet
					</button>
				</div>
			</td>
		</tr>
	);
};

export default MatchedQuestion;
