import apiMiddleware from './middleware/ApiMiddleware';

export const seaPortsApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getAllSeaports: builder.query({
			query: (filter) => ({
				url: '/sea-ports/get-filtered',
				params: filter
			})
		})
	})
});

export const { useGetAllSeaportsQuery } = seaPortsApi;
