import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logOutState, setRefreshToken } from '../../slices/auth/authSlice';

//const apiUrl = 'https://api.yeditepe.dev/';
//const localUrl = 'http://127.0.0.1:8000/';
const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
	baseUrl: apiUrl,
	prepareHeaders: (headers, { getState }) => {
		const accessToken = getState().authSlice.access_token;
		const token_type = getState().authSlice.token_type;

		if (accessToken) {
			headers.set('authorization', `${token_type} ${accessToken}`);
			headers.set('Accept', 'application/json');
		}

		return headers;
	}
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);

	if (result?.error?.status === 401) {
		const { data: refreshResult } = await baseQuery(
			{ url: 'auth/refresh', method: 'POST' },
			api,
			extraOptions
		);

		if (refreshResult) {
			api.dispatch(setRefreshToken(refreshResult.data));
			result = await baseQuery(args, api, extraOptions);
		} else {
			api.dispatch(logOutState());
		}
	}

	return result;
};

const apiMiddleware = createApi({
	baseQuery: baseQueryWithReauth,
	reducerPath: 'api',
	tagTypes: [
		'Shipment',
		'Auth',
		'Languages',
		'Customer',
		'Companies',
		'Roles',
		'Permission',
		'TransactionsCode'
	],
	endpoints: () => ({})
});

export default apiMiddleware;
