import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { logOutState } from '../../store/slices/auth/authSlice';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { useLogoutMutation } from '../../store/api/auth';

const Logout = () => {
	const dispatch = useDispatch();

	const [logout, { isError, isSuccess, data, isLoading }] = useLogoutMutation();

	const accesToken = useSelector((state) => state.authSlice.access_token);

	useEffect(() => {
		logout({ accesToken });
	}, [logout, accesToken]);

	if (isSuccess) {
		dispatch(logOutState());
		return <Redirect to='/login' />;
	}

	if (!accesToken) {
		return <Redirect to='/login' />;
	}

	return <></>;
};

Logout.propTypes = {
	history: PropTypes.object
};

export default withRouter(Logout);
