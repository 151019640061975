import React from 'react';
import { FormFeedback } from 'reactstrap';

export const FormInput = ({
	validation,
	placeholder,
	name,
	text,
	type,
	id
}) => {
	return (
		<>
			<label htmlFor='name-title-input' className='form-label'>
				{text}
			</label>
			<input
				id={id}
				type={type}
				className='form-control'
				placeholder={placeholder}
				name={name}
				onChange={validation.handleChange}
				onBlur={validation.handleBlur}
				value={validation.values[name]}
			/>
			{validation.touched.code && validation.errors.code ? (
				<FormFeedback type='invalid'>{validation.errors.code}</FormFeedback>
			) : null}
		</>
	);
};

export default FormInput;
