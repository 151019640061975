import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import TableComponent from '../../Components/ui/table/Table';

const columnData = [
	{
		id: 1,
		name: 'Taşıyıcı',
		value: 'carrier.num_code'
	},
	{
		id: 3,
		name: 'MAWB',
		value: 'mawb_code'
	},
	{
		id: 2,
		name: 'HAWB',
		value: 'hawb_code'
	},
	{
		id: 4,
		name: 'Firma Adı',
		value: 'company.name'
	},
	{
		id: 6,
		name: 'Kalkış Yeri',
		value: 'port_departure'
	},
	{
		id: 7,
		name: 'Varış Yeri',
		value: 'port_arrival'
	},
	{
		id: 9,
		name: 'Durumu',
		value: 'status'
	}
];

const LastShipments = ({ data }) => {
	const [lastShipments, setLastShipments] = useState([]);
	const [search, setSearch] = useState('');

	useEffect(() => {
		if (data.length > 0) {
			setLastShipments(data);
		}
	}, [data]);

	return (
		<>
			<Card className='card-height-100'>
				<CardHeader className='align-items-center d-flex'>
					<h4 className='card-title mb-0 flex-grow-1'>Son Sevkiyatlar</h4>
					<div className='flex-shrink-0'>
						{/* {role?.includes('company.edit') && ( */}
						{/* <button type='button' className='btn btn-soft-info btn-sm'>
							<i className='ri-file-list-3-line align-middle'></i> Generate
							Report
						</button> */}
						{/* )} */}
					</div>
				</CardHeader>

				<CardBody>
					<TableComponent
						columnData={columnData}
						data={lastShipments}
						filter={true}
						setSearch={setSearch}
						search={search}
					/>
				</CardBody>
			</Card>
		</>
	);
};

export default LastShipments;
