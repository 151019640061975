import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	layoutType: 'vertical',
	leftSidebarType: 'dark',
	layoutModeType: 'light',
	layoutWidthType: 'lg',
	layoutPositionType: 'fixed',
	topbarThemeType: 'dark',
	leftsidbarSizeType: 'lg',
	leftSidebarViewType: 'default',
	leftSidebarImageType: 'none',
	preloader: 'disable'
};

export const layoutSlice = createSlice({
	name: 'layout',
	initialState,
	reducers: {
		changeLayout: (state, action) => {
			state.layoutType = action.payload;
		},
		changeSidebarTheme: (state, action) => {
			state.leftSidebarType = action.payload;
			document.documentElement.setAttribute('data-sidebar', 'dark');
			document.documentElement.setAttribute('data-layout-mode', action.payload);
		},
		changeLayoutMode: (state, action) => {
			state.layoutModeType = action.payload;
		},
		changeLayoutWidth: (state, action) => {
			state.layoutWidthType = action.payload;
		},
		changeLayoutPosition: (state, action) => {
			state.layoutPositionType = action.payload;
		},
		changeTopbarTheme: (state, action) => {
			state.topbarThemeType = action.payload;
			document.documentElement.setAttribute('data-topbar', action.payload);
		},
		changeLeftsidebarSizeType: (state, action) => {
			state.leftsidbarSizeType = action.payload;
			document.documentElement.setAttribute('data-layout', 'vertical');
			switch (action.payload) {
				case 'lg':
					document.documentElement.setAttribute('data-sidebar-size', 'lg');
					break;
				case 'md':
					document.documentElement.setAttribute('data-sidebar-size', 'md');
					break;
				case 'sm':
					document.documentElement.setAttribute('data-sidebar-size', 'sm');
					break;
				case 'sm-hover':
					document.documentElement.setAttribute(
						'data-sidebar-size',
						'sm-hover'
					);
					break;
				default:
					document.documentElement.setAttribute('data-sidebar-size', 'lg');
			}
		},
		changeLeftsidebarViewType: (state, action) => {
			state.leftSidebarViewType = action.payload;
		},
		changeSidebarImageType: (state, action) => {
			state.leftSidebarImageType = action.payload;
		},
		changePreloader: (state, action) => {
			state.preloader = action.payload;
		}
	}
});

export const {
	changeLayout,
	changeSidebarTheme,
	changeLayoutMode,
	changeLayoutWidth,
	changeLayoutPosition,
	changeTopbarTheme,
	changeLeftsidebarSizeType,
	changeLeftsidebarViewType,
	changeSidebarImageType,
	changePreloader
} = layoutSlice.actions;

export const layoutType = (state) => state.layoutType;
export const leftSidebarType = (state) => state.leftSidebarType;
export const layoutModeType = (state) => state.layoutModeType;
export const layoutWidthType = (state) => state.layoutWidthType;
export const layoutPositionType = (state) => state.layoutPositionType;
export const topbarThemeType = (state) => state.topbarThemeType;
export const leftsidbarSizeType = (state) => state.leftsidbarSizeType;
export const leftSidebarViewType = (state) => state.leftSidebarViewType;
export const leftSidebarImageType = (state) => state.leftSidebarImageType;
export const preloader = (state) => state.preloader;

export default layoutSlice.reducer;
