import avatar1 from '../../assets/images/users/avatar-1.jpg';

const dashboardWidgets = [
	{
		id: 1,
		label: 'Aktif Sevkiyatlar',
		percentage: '16.24 %',
		iconClass: 'bx bxs-plane-take-off',
		iconBgClass: '',
		variable: 'totalShipment',
		subCounter: [
			{
				id: 1,
				counter: '124',
				prevCounter: '117'
			}
		]
	},
	{
		id: 2,
		label: 'Aktif Araçlar',
		percentage: '3.96 %',
		iconClass: 'bx bxs-truck',
		iconBgClass: 'bg-danger',
		variable: 'totalVehicle',
		subCounter: [
			{
				id: 1,
				counter: '248',
				prevCounter: '256'
			}
		]
	},
	{
		id: 3,
		label: 'Personeller',
		percentage: '0.24 %',
		iconClass: 'bx bxs-user-badge',
		iconBgClass: 'bg-warning',
		variable: 'totalUser',
		subCounter: [
			{
				id: 1,
				counter: '42',
				prevCounter: '44'
			}
		]
	},
	{
		id: 4,
		label: 'Müşteriler',
		percentage: '2 %',
		iconClass: 'bx bxs-buildings',
		iconBgClass: 'bg-success',
		variable: 'totalCustomer',
		subCounter: [
			{
				id: 1,
				counter: '1698',
				prevCounter: '1690'
			}
		]
	}
];

const lastShipments = [
	{
		id: 1,
		img: avatar1,
		company: 'Türk Airlines',
		importer: 'FEDARM',
		billOfLading: '#235-46894260',
		fromTo: 'ADB/IAH',
		depDate: '06 Kas 2022',
		status: 2
	},
	{
		id: 2,
		img: avatar1,
		company: 'Turkish Airlines',
		importer: 'Pragmatech',
		billOfLading: '#235-46859293',
		fromTo: 'ADB/KWI',
		depDate: '04 Nis 2022',
		status: 1
	},
	{
		id: 3,
		img: avatar1,
		company: 'Pegasus',
		importer: 'Nono Company',
		billOfLading: '#235-46893825',
		fromTo: 'ADB/DXB',
		depDate: '04 May 2022',
		status: 3
	},
	{
		id: 4,
		img: avatar1,
		company: 'Pegasus',
		importer: 'Kılıç Balıkçılık',
		billOfLading: '#235-46894260',
		fromTo: 'ADB/KWI',
		depDate: '04 Haz 2022',
		status: 1
	},
	{
		id: 5,
		img: avatar1,
		company: 'Turkish Airlines',
		importer: 'Ocean Fresh Fish',
		billOfLading: '#235-46859362',
		fromTo: 'ADB/IAH',
		depDate: '04 Tem 2022',
		status: 3
	}
];

export { dashboardWidgets, lastShipments };
