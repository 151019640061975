import React from 'react';
import { Toaster } from 'react-hot-toast';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes/index';

function App() {
	return (
		<React.Fragment>
			<Route />
			<Toaster
				position='bottom-right'
				containerStyle={{ zIndex: '99999999999999999999999' }}
			/>
		</React.Fragment>
	);
}

export default App;
