import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';

const ShipmentsByCountry = ({ data }) => {
	const [values, setValues] = useState([]);
	const [countries, setCountries] = useState([]);

	useEffect(() => {
		const valueRaw = [];
		const countryRaw = [];

		if (data.length > 0) {
			for (const d of data) {
				countryRaw.push(d.name);
				valueRaw.push(d.count);
			}
		}

		setValues(valueRaw);
		setCountries(countryRaw);
	}, [data]);

	return (
		<Card className='card-height-100'>
			<CardHeader className='align-items-center d-flex'>
				<h4 className='card-title mb-0 flex-grow-1'>
					En Çok Sevkiyat Yapılan Ülkeler
				</h4>
				{/* <div>
					<Button
						color='secondary'
						size='sm'
						className='btn-soft-secondary me-1 shadow-none'
					>
						ALL
					</Button>
					<Button
						color='primary'
						size='sm'
						className='btn-soft-primary me-1 shadow-none'
					>
						1M
					</Button>
					<Button
						color='secondary'
						size='sm'
						className='btn-soft-secondary shadow-none'
					>
						6M
					</Button>
				</div> */}
			</CardHeader>
			<CardBody className='p-0'>
				<div>
					<div id='countries_charts' className='apex-charts' dir='ltr'>
						{/* Sessions by Countries */}
						<SessionsByCountriesCharts
							values={values}
							countries={countries}
							dataColors='["--vz-info", "--vz-info", "--vz-info", "--vz-info", "--vz-danger", "--vz-info", "--vz-info", "--vz-info", "--vz-info", "--vz-info"]'
						/>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

const getChartColorsArray = (colors) => {
	colors = JSON.parse(colors);
	return colors.map(function (value) {
		var newValue = value.replace(' ', '');
		if (newValue.indexOf(',') === -1) {
			var color = getComputedStyle(document.documentElement).getPropertyValue(
				newValue
			);

			if (color.indexOf('#') !== -1) color = color.replace(' ', '');
			if (color) return color;
			else return newValue;
		} else {
			var val = value.split(',');
			if (val.length === 2) {
				var rgbaColor = getComputedStyle(
					document.documentElement
				).getPropertyValue(val[0]);
				rgbaColor = 'rgba(' + rgbaColor + ',' + val[1] + ')';
				return rgbaColor;
			} else {
				return newValue;
			}
		}
	});
};

const SessionsByCountriesCharts = ({ dataColors, values, countries }) => {
	var barchartCountriesColors = getChartColorsArray(dataColors);
	const series = [
		{
			data: values,
			name: 'Sessions'
		}
	];
	var options = {
		chart: {
			type: 'bar',
			height: 436,
			toolbar: {
				show: false
			}
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: true,
				distributed: true,
				dataLabels: {
					position: 'top'
				}
			}
		},
		colors: barchartCountriesColors,
		dataLabels: {
			enabled: true,
			offsetX: 32,
			style: {
				fontSize: '12px',
				fontWeight: 400,
				colors: ['#adb5bd']
			}
		},

		legend: {
			show: false
		},
		grid: {
			show: false
		},
		xaxis: {
			categories: countries
		}
	};
	return (
		<React.Fragment>
			<ReactApexChart
				options={options}
				series={series}
				type='bar'
				height='436'
				className='apex-charts'
			/>
		</React.Fragment>
	);
};

export default ShipmentsByCountry;
