import React from 'react';
import { shipmentWidgets } from './data';
import CountUp from 'react-countup';

const Widgets = () => {
	return (
		<React.Fragment>
			<div className='col-xl-12'>
				<div className='card crm-widget'>
					<div className='card-body p-0'>
						<div className='row row-cols-xxl-4 row-cols-md-3 row-cols-1 g-0'>
							{shipmentWidgets.map((widget, index) => (
								<div className='col' key={index}>
									<div className='py-4 px-3'>
										<h5 className='text-muted text-uppercase fs-13'>
											{widget.label}
										</h5>
										<div className='d-flex align-items-center'>
											<div className='flex-shrink-0'>
												<i
													className={widget.icon + ' display-6 text-muted'}
												></i>
											</div>
											<div className='flex-grow-1 ms-3'>
												<h4 className='mb-0'>
													<span className='counter-value' data-target='197'>
														{widget.date !== '' ? (
															widget.date
														) : (
															<CountUp
																start={0}
																prefix={widget.prefix}
																suffix={widget.suffix}
																separator={widget.separator}
																end={widget.counter}
																decimals={widget.decimals}
																duration={2}
															/>
														)}
													</span>
												</h4>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Widgets;
