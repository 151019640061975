import apiMiddleware from './middleware/ApiMiddleware';

export const activityLogApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getAllQuestions: builder.query({
			query: () => ({
				url: '/questions/get-all'
			}),
			providesTags: ['Question']
		}),

		getFilteredQuestions: builder.query({
			query: (filter) => ({
				url: '/questions/get-filtered',
				params: filter
			}),
			providesTags: ['Question', 'Languages']
		}),

		updateQuestion: builder.mutation({
			query: ({ id, data }) => ({
				url: `/questions/${id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['Question']
		}),
		deleteQuestion: builder.mutation({
			query: (id) => ({
				url: `/questions/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Question']
		}),
		questionTypes: builder.query({
			query: () => ({
				url: '/question-types/get-all'
			}),
			providesTags: ['Question']
		}),
		saveQuestion: builder.mutation({
			query: (data) => ({
				url: '/questions',
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['Question']
		}),
		getProductCodes: builder.query({
			query: () => ({
				url: '/product-codes/get-all'
			})
		}),

		getQuestionById: builder.query({
			query: (id) => ({
				url: `/questions/get-by-id/${id}`
			})
		}),

		//Matched Questions

		saveMatchedQuestion: builder.mutation({
			query: (data) => ({
				url: '/matched-questions',
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['Question']
		}),

		updateMatchedQuestion: builder.mutation({
			query: ({ id, data }) => ({
				url: `/matched-questions/${id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['Question']
		}),

		getMatchedQuestionById: builder.query({
			query: (id) => ({
				url: `/matched-questions/get-matched-question-by-question-id/${id}`
			})
		}),

		reOrderMatchedQuestions: builder.mutation({
			query: (data) => ({
				url: '/matched-questions/update-sorting',
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['Question']
		}),

		wantsToBeDelete: builder.mutation({
			query: (id) => ({
				url: `/questions/wants-to-be-delete/${id}`,
				method: 'POST'
			}),
			invalidatesTags: ['Question']
		}),

		deleteMatchedQuestion: builder.mutation({
			query: (id) => ({
				url: `/matched-questions/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Question']
		})
	})
});

export const {
	useGetAllQuestionsQuery,
	useGetFilteredQuestionsQuery,
	useGetQuestionByIdQuery,
	useUpdateQuestionMutation,
	useDeleteQuestionMutation,
	useQuestionTypesQuery,
	useSaveQuestionMutation,

	useGetProductCodesQuery,

	//Matched Questions
	useSaveMatchedQuestionMutation,
	useGetMatchedQuestionByIdQuery,
	useUpdateMatchedQuestionMutation,

	useReOrderMatchedQuestionsMutation,

	useWantsToBeDeleteMutation,

	useDeleteMatchedQuestionMutation
} = activityLogApi;
