import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Grid, _ } from 'gridjs-react';
import { useHistory } from 'react-router';
import { useGetShipmentsQuery } from '../../../store/api/shipments';

const List = () => {
	const history = useHistory();
	const { data: list } = useGetShipmentsQuery();
	return (
		<Col xl={12}>
			<Card className='card-height-100'>
				<CardBody>
					<Grid
						data={list.data}
						columns={[
							{
								name: 'Bildirim',
								minWidth: '220px',
								width: '220px',
								formatter: (cell) => _(list.data[0].name)
							},
							{
								name: 'Gönderen Kullanıcı',
								minWidth: '150px',
								width: '150px',
								formatter: (cell, row) =>
									_(
										<>
											<span
												className='cursor-pointer text-primary'
												onClick={() => {
													history.push(list.data[0].name);
												}}
											>
												{list.data[0].name}
											</span>
										</>
									)
							},
							{
								name: 'Tarih & Saat',
								minWidth: '150px',
								width: '150px',
								formatter: (cell) => _(list.data[0].name)
							}
						]}
						sort={false}
						// PAGINATION

						pagination={{
							enabled: true,
							limit: 5
							// server: {
							// 	url: (prev, page, limit) =>
							// 		`${prev}?limit=${limit}&offset=${page * limit}`
							// }
						}}
						// server={{
						// 	url: 'https://pokeapi.co/api/v2/pokemon',
						// 	then: (data) =>
						// 		data.results.map((pokemon) => [pokemon.name, pokemon.url]),
						// 	total: (data) => data.count
						// }}
						search={
							{
								// server: {
								// 	url: (prev, keyword) => `${prev}?search=${keyword}`
								// }
							}
						}

						//SEARCH
						// search={{
						// 	server: {
						// 		url: (prev, keyword) => `${prev}?search=${keyword}`
						// 	}
						// }}
						// server={{
						// 	url: 'https://swapi.dev/api/films/',
						// 	then: (data) =>
						// 		data.results.map((movie) => [
						// 			movie.title,
						// 			movie.director,
						// 			movie.producer
						// 		]),
						// 	total: (data) => data.count
						// }}
						// pagination={{
						// 	enabled: true,
						// 	limit: 1,
						// 	server: {
						// 		url: (prev, page, limit) =>
						// 			`${prev}?limit=${limit}&offset=${page * limit}`
						// 	}
						// }}
					/>
					{/* <div className='table-responsive table-card'>
						<table className='table table-borderless table-centered align-middle table-nowrap mb-0'>
							<thead className='text-muted table-light'>
								<tr>
									<th scope='col'>Firma</th>
									<th scope='col'>İthalatçı</th>
									<th scope='col'>HAWB</th>
									<th scope='col'>Kalkış / Varış</th>
									<th scope='col'>Kalkış Zamanı</th>
									<th scope='col'></th>
								</tr>
							</thead>
							<tbody>
								{(activeShipments || []).map((item, key) => (
									<tr key={key}>
										<td>
											<Link to='/company' className='text-primary fw-medium'>
												<div className='flex-shrink-0 me-2'>
													<img
														src={item.img}
														alt=''
														className='avatar-xs rounded-circle me-2 shadow'
													/>
												</div>
											</Link>
										</td>
										<td>
											<div className='d-flex align-items-center'>
												<div className='flex-grow-1'>
													<Link
														to='/dashboard'
														className='text-primary fw-medium'
													>
														{item.name}
													</Link>
												</div>
											</div>
										</td>
										<td>{item.product}</td>
										<td>
											<span>{item.amount}</span>
										</td>
										<td>{item.vendor}</td>
										<td>
											<Button color='light' className='btn-icon'>
												<i className='ri-eye-line' />
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div> */}
				</CardBody>
			</Card>

			<React.Fragment></React.Fragment>
		</Col>
	);
};

export default List;
