import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// Logger (is Dev only)

//Api Middleware
import apiMiddleware from './api/middleware/ApiMiddleware';

//Api Reducers
import { authApi } from './api/auth';
import { languageApi } from './api/languages';
import { shipmentApi } from './api/shipments';
import { companyApi } from './api/companies';
import { countriesApi } from './api/countries';
import { citiesApi } from './api/cities';
import { trackerApi } from './api/tracker';
import { systemNotificationsApi } from './api/systemNotifications';
import { airportsApi } from './api/airports';
import { seaPortsApi } from './api/seaports';
import { shippingParametersApi } from './api/shippingParameters';
import { transactionsCodeApi } from './api/transactionsCode';
import { carrierApi } from './api/carrier';
import { capCodesApi } from './api/capCodes';
import { rolesApi } from './api/roles';
import { usersApi } from './api/user';
import { generalApi } from './api/general';
import { vehicleApi } from './api/vehicle';
import { dashboardApi } from './api/dashboard';
import { addressApi } from './api/address';

//Slice Reducers
import layoutSlice from './slices/theme/theme';
import authSlice from './slices/auth/authSlice';
import companiesSlice from './slices/companies/companiesSlice';
import shipmentsSlices from './slices/shipments/shipmentsSlices';
import customersSlices from './slices/customers/customersSlice';

const reducers = combineReducers({
	[authApi.reducerPath]: authApi.reducer,
	[languageApi.reducerPath]: languageApi.reducer,
	[shipmentApi.reducerPath]: shipmentApi.reducer,
	[companyApi.reducerPath]: companyApi.reducer,
	[countriesApi.reducerPath]: countriesApi.reducer,
	[citiesApi.reducerPath]: citiesApi.reducer,
	[trackerApi.reducerPath]: trackerApi.reducer,
	[systemNotificationsApi.reducerPath]: systemNotificationsApi.reducer,
	[airportsApi.reducerPath]: airportsApi.reducer,
	[seaPortsApi.reducerPath]: seaPortsApi.reducer,
	[shippingParametersApi.reducerPath]: shippingParametersApi.reducer,
	[transactionsCodeApi.reducerPath]: transactionsCodeApi.reducer,
	[carrierApi.reducerPath]: carrierApi.reducer,
	[capCodesApi.reducerPath]: capCodesApi.reducer,
	[rolesApi.reducerPath]: rolesApi.reducer,
	[usersApi.reducerPath]: usersApi.reducer,
	[generalApi.reducerPath]: generalApi.reducer,
	[vehicleApi.reducerPath]: vehicleApi.reducer,
	[dashboardApi.reducerPath]: dashboardApi.reducer,
	[addressApi.reducerPath]: addressApi.reducer,

	layoutSlice: layoutSlice,
	authSlice: authSlice,
	companiesSlice: companiesSlice,
	shipmentsSlices: shipmentsSlices,
	customersSlices: customersSlices
});

const persistConfig = {
	key: 'yeditepego',
	storage,
	blacklist: [shipmentApi.reducerPath]
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
	devTools: true,

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		}).concat(thunk, apiMiddleware.middleware)
});

export default store;
