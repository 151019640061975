import apiMiddleware from './middleware/ApiMiddleware';

export const vehicleApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getVehiclesById: builder.query({
			query: (id) => ({
				url: `/vehicles/get-by-id/${id}`
			})
		}),

		getVehicleByFiltered: builder.query({
			query: (filter) => ({
				url: '/vehicles/get-filtered',
				params: filter
			})
		}),

		getAssignedVehicles: builder.query({
			query: (filter) => ({
				url: '/vehicles/get-assigned-active-vehicle-filtered',
				params: filter
			})
		}),
		getCompletedVehicles: builder.query({
			query: (filter) => ({
				url: '/vehicles/get-assigned-completed-vehicle-filtered',
				params: filter
			})
		}),

		getAllVehicles: builder.query({
			query: (filter) => ({
				url: '/vehicles/get-filtered',
				params: filter
			})
		})
	})
});

export const {
	useGetVehiclesByIdQuery,
	useGetVehicleByFilteredQuery,
	useGetAllVehiclesQuery,
	useGetAssignedVehiclesQuery,
	useGetCompletedVehiclesQuery
} = vehicleApi;
