import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

//import Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

//import actions

import {
	changeLayout,
	changeLayoutPosition,
	changeLeftsidebarViewType,
	changeSidebarImageType,
	changeLeftsidebarSizeType,
	changeSidebarTheme,
	changeLayoutMode,
	changeTopbarTheme
} from '../store/slices/theme/theme';

//redux
import { useSelector, useDispatch } from 'react-redux';

const Layout = (props) => {
	const dispatch = useDispatch();
	const {
		layoutType,
		leftSidebarType,
		layoutModeType,
		layoutPositionType,
		topbarThemeType,
		leftsidbarSizeType,
		leftSidebarViewType,
		leftSidebarImageType
	} = useSelector((state) => ({
		layoutType: state.layoutSlice.layoutType,
		leftSidebarType: state.layoutSlice.leftSidebarType,
		layoutModeType: state.layoutSlice.layoutModeType,
		layoutPositionType: state.layoutSlice.layoutPositionType,
		topbarThemeType: state.layoutSlice.topbarThemeType,
		leftsidbarSizeType: state.layoutSlice.leftsidbarSizeType,
		leftSidebarViewType: state.layoutSlice.leftSidebarViewType,
		leftSidebarImageType: state.layoutSlice.leftSidebarImageType
	}));

	/*
    layout settings
    */
	useEffect(() => {
		if (
			layoutType ||
			leftSidebarType ||
			layoutModeType ||
			layoutPositionType ||
			topbarThemeType ||
			leftsidbarSizeType ||
			leftSidebarViewType ||
			leftSidebarImageType
		) {
			dispatch(changeLeftsidebarViewType(leftSidebarViewType));
			dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
			dispatch(changeSidebarTheme(leftSidebarType));
			dispatch(changeLayoutMode(layoutModeType));
			dispatch(changeLayoutPosition(layoutPositionType));
			dispatch(changeTopbarTheme(topbarThemeType));
			dispatch(changeLayout(layoutType));
			dispatch(changeSidebarImageType(leftSidebarImageType));
		}
	}, [
		layoutType,
		leftSidebarType,
		layoutModeType,
		layoutPositionType,
		topbarThemeType,
		leftsidbarSizeType,
		leftSidebarViewType,
		leftSidebarImageType,
		dispatch
	]);
	/*
    call dark/light mode
    */
	const onChangeLayoutMode = (value) => {
		if (changeLayoutMode) {
			dispatch(changeLayoutMode(value));
		}
	};

	const [headerClass, setHeaderClass] = useState('');
	// class add remove in header
	useEffect(() => {
		window.addEventListener('scroll', scrollNavigation, true);
	});
	function scrollNavigation() {
		var scrollup = document.documentElement.scrollTop;
		if (scrollup > 50) {
			setHeaderClass('topbar-shadow');
		} else {
			setHeaderClass('');
		}
	}

	return (
		<React.Fragment>
			<div id='layout-wrapper'>
				<Header
					headerClass='test'
					layoutModeType='vertical'
					onChangeLayoutMode={onChangeLayoutMode}
				/>
				<Sidebar layoutType={layoutType} />
				<div className='main-content'>
					{props.children}
					<Footer />
				</div>
			</div>
		</React.Fragment>
	);
};

// Layout.propTypes = {
// 	children: PropTypes.object
// };

export default withRouter(Layout);
