import apiMiddleware from './middleware/ApiMiddleware';

export const rolesApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		createPermission: builder.mutation({
			query: (data) => ({
				url: '/permissions',
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['Permission']
		}),

		getAllPermissionFiltered: builder.query({
			query: (filter) => ({
				url: '/permissions/get-filtered',
				params: filter
			}),
			providesTags: ['Permission', 'Languages']
		}),

		getAllPermissions: builder.query({
			query: () => ({
				url: '/permissions/get-all'
			}),
			providesTags: ['Permission']
		}),

		getPermissionsById: builder.query({
			query: ({ id }) => ({
				url: `/permissions/get-by-id/${id}`
			}),
			providesTags: ['Permission']
		}),
		setPermissionStatus: builder.mutation({
			query: ({ id, data }) => ({
				url: `/permissions/${id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['Permission']
		}),

		deletePermission: builder.mutation({
			query: (id) => ({
				method: 'DELETE',
				url: `/permissions/${id}`
			}),
			invalidatesTags: ['Permission']
		}),

		//Create Module

		createModule: builder.mutation({
			query: (data) => ({
				url: '/modules',
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['Roles']
		}),

		getAllModules: builder.query({
			query: () => ({
				url: '/modules/get-all'
			}),
			providesTags: ['Roles']
		}),

		getModulesFiltered: builder.query({
			query: (filter) => ({
				url: '/modules/get-filtered',
				params: filter
			}),
			providesTags: ['Roles']
		}),

		getModulesById: builder.query({
			query: ({ id }) => ({
				url: `/modules/get-by-id/${id}`
			}),
			providesTags: ['Roles']
		}),

		setModuleStatus: builder.mutation({
			query: ({ id, data }) => ({
				url: `/modules/${id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['Roles']
		}),

		deleteModule: builder.mutation({
			query: (id) => ({
				method: 'DELETE',
				url: `/modules/${id}`
			}),
			invalidatesTags: ['Roles']
		}),

		getAllRole: builder.query({
			query: () => ({
				url: '/roles/get-all'
			}),
			providesTags: ['Roles']
		}),

		getAllRoles: builder.query({
			query: (filter) => ({
				url: '/roles/get-filtered',
				params: filter
			}),
			providesTags: ['Roles']
		}),

		getRolesById: builder.query({
			query: (id) => ({
				url: `/roles/get-by-id/${id}`
			}),
			providesTags: ['Roles']
		}),

		setRoleStatus: builder.mutation({
			query: ({ id, data }) => ({
				url: `/roles/${id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['Roles']
		}),

		addRole: builder.mutation({
			query: (data) => ({
				url: '/roles',
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['Roles']
		}),
		deleteRole: builder.mutation({
			query: (id) => ({
				method: 'DELETE',
				url: `/roles/${id}`
			}),
			invalidatesTags: ['Roles']
		})
	})
});

export const {
	useGetAllRolesQuery,
	useDeleteRoleMutation,
	useSetRoleStatusMutation,
	useAddRoleMutation,
	useGetRolesByIdQuery,

	useGetAllModulesQuery,
	useDeleteModuleMutation,
	useSetModuleStatusMutation,
	useCreateModuleMutation,
	useGetModulesByIdQuery,
	useGetModulesFilteredQuery,

	useGetAllPermissionsQuery,
	useGetAllPermissionFilteredQuery,
	useDeletePermissionMutation,
	useSetPermissionStatusMutation,
	useCreatePermissionMutation,
	useGetPermissionsByIdQuery,

	useGetAllRoleQuery
} = rolesApi;
