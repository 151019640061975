import apiMiddleware from './middleware/ApiMiddleware';

export const systemNotificationsApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getSystemNotifications: builder.query({
			query: (filter) => ({
				url: '/notifications/get-filtered',
				params: filter
			})
		})
	})
});

export const { useGetSystemNotificationsQuery } = systemNotificationsApi;
