// Import Images
import avatar1 from '../../../assets/images/users/avatar-1.jpg';

const bestSellingProducts = [
	{
		id: 1,
		img: avatar1,
		company: 'Pragmatech',
		shipment: '99',
		lastShipment: '11 Kas 2021'
	},
	{
		id: 2,
		img: avatar1,
		company: 'Nono Company',
		shipment: '86',
		lastShipment: '13 May 2022'
	},
	{
		id: 3,
		img: avatar1,
		company: 'FEDARM',
		shipment: '64',
		lastShipment: '04 Ara 2022'
	},
	{
		id: 4,
		img: avatar1,
		company: 'Kılıç Balıkçılık',
		shipment: '53',
		lastShipment: '12 Mar 2022'
	},
	{
		id: 5,
		img: avatar1,
		company: 'Ocean Fresh Fish',
		shipment: '33',
		lastShipment: '31 Agu 2021'
	},
	{
		id: 6,
		img: avatar1,
		company: 'Nike',
		shipment: '20',
		lastShipment: '10 Haz 2022'
	}
];
export { bestSellingProducts };
