import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import { staffsWidgets, data2 } from './data';
import { Grid, _ } from 'gridjs-react';
import { setCustomersCount } from '../../../store/slices/customers/customersSlice';
import { useHistory } from 'react-router';
import { useGetCustomerByFilteredQuery } from '../../../store/api/customer';
import TableComponent from '../../../Components/ui/table/Table';
import { useDispatch } from 'react-redux';

const List = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [data, setData] = useState([]);
	const [flatData, setFlatData] = useState([]);
	const [debounceSearch, setDebounceSearch] = useState('');
	const [pagination, setPagination] = useState({
		currentPage: 1,
		hasNextPage: false,
		hasPreviousPage: false,
		total: 0,
		totalPages: 0
	});

	const {
		data: customerData,
		isLoading,
		isSuccess,
		refetch
	} = useGetCustomerByFilteredQuery({
		search: debounceSearch ? debounceSearch : undefined,
		page: page === 0 ? 1 : page,
		limit: limit
	});

	const handlePageChange = (data) => {
		setPage(data);
	};

	useEffect(() => {
		if (isSuccess) {
			const flat = Object.values(customerData?.data.data)
				.map((item) => item)
				.flatMap((item) => item);
			setFlatData(flat);
		}
	}, [isSuccess, customerData]);

	useEffect(() => {
		if (isSuccess) {
			setData(flatData);
			setPagination({
				total: customerData.data.total,
				currentPage: customerData.data.current_page,
				totalPages: customerData.data.last_page,
				hasNextPage: customerData.data.next_page_url,
				hasPreviousPage: customerData.data.prev_page_url ? true : false
			});

			dispatch(
				setCustomersCount({
					count: customerData.data.total,
					staffs: customerData.data.data.staffs
						? customerData.data.data.staffs.length
						: 0,
					shipments: customerData.data.data.air_shipments
						? customerData.data.data.air_shipments.length
						: 0
				})
			);
		}

		// 	// 	return data;
	}, [isSuccess, customerData, data]);

	const columnData = [
		{
			id: 1,
			name: 'Müşteri',
			value: 'name'
		},
		{
			id: 2,
			name: 'Adres',
			value: 'address'
		},
		{
			id: 3,
			name: 'Telefon',
			value: 'phone'
		},
		{
			id: 4,
			name: 'Tarih',
			value: 'created_at'
		},
		{
			id: 5,
			name: 'Detaylar',
			value: 'details',
			detailLink: '/customer/detail'
		}
	];

	return (
		<Col xl={12}>
			<Card className='card-height-100'>
				<CardBody>
					{isLoading ? (
						<Spinner
							style={{
								width: '3rem',
								height: '3rem'
							}}
						/>
					) : (
						<>
							<TableComponent
								columnData={columnData}
								data={data}
								isLoading={isLoading}
								pagination={pagination}
								limit={limit}
								setLimit={setLimit}
								setPage={setPage}
								setSearch={setSearch}
								search={search}
								filter={true}
								refetch={refetch}
								setDebounceSearch={setDebounceSearch}
								setPagination={setPagination}
								handlePageChange={handlePageChange}
								page={page}
							/>
							{data.length == 0 && (
								<Row>
									<Col>
										<div className='text-center'>
											<div className='mb-4'>
												<i className='ri-file-list-3-line text-primary display-4' />
											</div>
											<h5>Hiçbir kayıt bulunamadı</h5>
											<p className='text-muted'>
												{data?.length === 0 && 'Liste boş'}
											</p>
										</div>
									</Col>
								</Row>
							)}
						</>
					)}
				</CardBody>
			</Card>

			<React.Fragment></React.Fragment>
		</Col>
	);
};

export default List;
