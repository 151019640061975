import React from 'react';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';

const Widgets = () => {
	const { count, staffs, shipments } = useSelector(
		(state) => state.customersSlices
	);

	const customersWidgets = [
		{
			id: 1,
			label: 'Şirket Sayısı',
			icon: 'bx bx-buildings',
			counter: count,
			date: '',
			decimals: 0,
			suffix: '',
			prefix: ''
		},
		{
			id: 2,
			label: 'Personel Sayısı',
			icon: 'bx bx-user',
			counter: staffs,
			date: '',
			decimals: 0,
			suffix: '',
			prefix: ''
		},
		{
			id: 3,
			label: 'Sevkiyat',
			icon: 'bx bxs-plane-take-off',
			counter: shipments,
			date: '',
			decimals: 0,
			suffix: '',
			prefix: ''
		}
	];

	return (
		<React.Fragment>
			<div className='col-xl-12'>
				<div className='card crm-widget'>
					<div className='card-body p-0'>
						<div className='row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0'>
							<div className='col' key={1}>
								<div className='py-4 px-3'>
									<h5 className='text-muted text-uppercase fs-13'>
										Şirket Sayısı
									</h5>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<i className={'bx bx-buildings display-6 text-muted'}></i>
										</div>
										<div className='flex-grow-1 ms-3'>
											<h4 className='mb-0'>
												<span className='counter-value' data-target='197'>
													<CountUp
														start={0}
														prefix={''}
														suffix={''}
														separator={''}
														end={count}
														decimals={''}
														duration={2}
													/>
												</span>
											</h4>
										</div>
									</div>
								</div>
							</div>
							<div className='col' key={1}>
								<div className='py-4 px-3'>
									<h5 className='text-muted text-uppercase fs-13'>
										Personel Sayısı
									</h5>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<i className={'bx bx-user display-6 text-muted'}></i>
										</div>
										<div className='flex-grow-1 ms-3'>
											<h4 className='mb-0'>
												<span className='counter-value' data-target='197'>
													<CountUp
														start={0}
														prefix={''}
														suffix={''}
														separator={''}
														end={staffs}
														decimals={''}
														duration={2}
													/>
												</span>
											</h4>
										</div>
									</div>
								</div>
							</div>
							<div className='col' key={1}>
								<div className='py-4 px-3'>
									<h5 className='text-muted text-uppercase fs-13'>
										Sevkiyatlar
									</h5>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<i
												className={'bx bxs-plane-take-off display-6 text-muted'}
											></i>
										</div>
										<div className='flex-grow-1 ms-3'>
											<h4 className='mb-0'>
												<span className='counter-value' data-target='197'>
													<CountUp
														start={0}
														prefix={''}
														suffix={''}
														separator={''}
														end={shipments}
														decimals={''}
														duration={2}
													/>
												</span>
											</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Widgets;
