import React, { useEffect, useState } from 'react';
import { Container, Row, Spinner } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableComponent from '../../../Components/ui/table/Table';
import { useGetAllActivitiesQuery } from '../../../store/api/activityLog';
// import List from './List';

const ActivityLog = () => {
	document.title = 'Aktivite Logları | Yeditepe Go';
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [data, setData] = useState([]);
	const [debounceSearch, setDebounceSearch] = useState('');
	const [pagination, setPagination] = useState({
		currentPage: 1,
		hasNextPage: false,
		hasPreviousPage: false,
		total: 0,
		totalPages: 0
	});

	const {
		data: activityData,
		isLoading,
		isFetching,
		isSuccess,
		refetch
	} = useGetAllActivitiesQuery({
		search: debounceSearch ? debounceSearch : undefined,
		page: page === 0 ? 1 : page,
		limit: limit
	});

	const columnData = [
		{
			id: 4,
			name: 'Kullanıcı ID',
			value: 'user_id'
		},
		{
			id: 1,
			name: 'Yapılan İşlem',
			value: 'model'
		},
		{
			id: 2,
			name: 'Eylem',
			value: 'action'
		},
		{
			id: 3,
			name: 'Tarih',
			value: 'created_at'
		},
		{
			id: 5,
			name: 'Görüntüle',
			value: 'view'
		}
	];

	const memoData = React.useMemo(() => {
		if (isSuccess) {
			setData(activityData.data.data);
			setPagination({
				total: activityData.data.total,
				currentPage: activityData.data.current_page,
				totalPages: activityData.data.last_page,
				hasNextPage: activityData.data.next_page_url,
				hasPreviousPage: activityData.data.prev_page_url ? true : false
			});
		}

		return data;
	}, [isSuccess, activityData, data]);

	const handlePageChange = (data) => {
		setPage(data);
	};

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<BreadCrumb title='Aktivite Logları' pageTitle='Sistem' />
					{isLoading || isFetching ? (
						<div className='text-center flex'>
							<Spinner color='primary' />
						</div>
					) : (
						<TableComponent
							columnData={columnData}
							data={memoData}
							isLoading={isLoading}
							pagination={pagination}
							limit={limit}
							setLimit={setLimit}
							setPage={setPage}
							setSearch={setSearch}
							search={search}
							filter={true}
							refetch={refetch}
							setDebounceSearch={setDebounceSearch}
							setPagination={setPagination}
							handlePageChange={handlePageChange}
							page={page}
							totalPages={pagination.totalPages}
						/>
					)}
				</Container>
			</div>
		</React.Fragment>
	);
};

export default ActivityLog;
