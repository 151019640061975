import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	Row,
	Spinner,
	TabContent,
	TabPane
} from 'reactstrap';
import toast from 'react-hot-toast';

import Select from 'react-select';
import {
	useGetAllQuestionsQuery,
	useGetProductCodesQuery,
	useGetQuestionByIdQuery,
	useSaveMatchedQuestionMutation
} from '../../../store/api/questions';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useGetAllCapCodesQuery } from '../../../store/api/capCodes';

const MatchQuestionModal = ({ addModal, setAddModal, data }) => {
	const [questionData, setQuestionData] = useState({});
	const [checked, setChecked] = useState(true);
	const [questionsData, setQuestionsData] = useState([]);
	const [trueFollowUp, setTrueFollowUp] = useState();
	const [falseFollowUp, setFalseFollowUp] = useState();
	const [followUp, setFollowUp] = useState();
	const [productCode, setProductCode] = useState(null);
	const [targetTypeValue, setTargetTypeValue] = useState([]);
	const [capCode, setCapCode] = useState([]);
	const [selectedCapCode, setSelectedCapCode] = useState([]);
	const [selected, setSelected] = useState([]);
	const [activeTab, setActiveTab] = useState(0);

	const [
		createQuestion,
		{
			isSuccess: createSuccess,
			isError: createError,
			isLoading: createLoading,
			error
		}
	] = useSaveMatchedQuestionMutation();

	const { data: question, isSuccess: questionSuccess } =
		useGetQuestionByIdQuery(data, {
			skip: !data && !addModal
		});

	const {
		data: questions,
		isLoading: questionsIsLoading,
		isError: questionsError,
		isSuccess: questionsSuccess
	} = useGetAllQuestionsQuery({
		skip: !data && !addModal
	});

	const {
		data: productCodes,
		isSuccess: productCodesSuccess,
		isLoading
	} = useGetProductCodesQuery({
		skip: !data && !addModal
	});

	useEffect(() => {
		if (productCodesSuccess) {
			setProductCode(productCodes.data);
		}
	}, [productCodesSuccess]);

	useEffect(() => {
		if (createSuccess) {
			closeModal();
			toast.success('Soru Başarıyla Eşlendi');
		}
	}, [createSuccess]);

	useEffect(() => {
		if (questionSuccess) {
			setQuestionData(question.data);
		}
	}, [questionSuccess, question]);

	useEffect(() => {
		if (questionsSuccess) {
			setQuestionsData(questions.data);
		}
	}, [questionsSuccess]);

	const targetType = [
		{ value: '0', label: 'Sevkiyat' },
		{ value: '1', label: 'Araç' }
	];

	const handleSelected = (e) => {
		setSelected(e);
	};

	const handlePaste = (event) => {
		const pastedData = event.clipboardData.getData('text');
		const dataArray = pastedData.split(',').map((item) => item.trim());
		dataArray.map((item) => {
			productCode.map((product) => {
				if (item === product.name) {
					setSelected((prevState) => {
						if (prevState) {
							return [
								...prevState,

								{
									value: product.id,
									label: product.name
								}
							];
						} else {
							return [
								{
									value: product.id,
									label: product.name
								}
							];
						}
					});
				}
			});
		});
	};

	useEffect(() => {
		if (createError) {
			toast.error(
				<div>
					<p>{error?.data?.message}</p>
					<p>
						<b>Ürün Kodu :</b>
						{error?.data?.data?.productCode} <br />
						<b>Soru :</b>
						{error?.data?.data?.question}
					</p>
				</div>
			);
		}
	}, [createError, error]);

	const closeModal = () => {
		setAddModal(false);

		// remove all data
		setQuestionData({});
		setChecked(true);

		setTrueFollowUp();
		setFalseFollowUp();
		setFollowUp();
		setProductCode(null);
		setTargetTypeValue([]);
		setSelected([]);
	};

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const followUpQuestions = (e, item, questionType) => {
		const { value, label } = e;

		setFollowUp({
			itemValue: item.value,
			questionType,
			value,
			label
		});
	};

	const handleTrueFollowUp = (e, item, questionType) => {
		const { value, label } = e;

		setTrueFollowUp({
			itemValue: item.value,
			questionType,
			value,
			label
		});
	};

	const handleFalseFollowUp = (e, item, questionType) => {
		const { value, label } = e;
		setFalseFollowUp({
			itemValue: item.value,
			questionType,
			value,
			label
		});
	};

	const {
		data: capCodesData,
		isLoading: capCodesDataLoading,
		isError: capCodesDataError,
		isSuccess: capCodesDataSuccess
	} = useGetAllCapCodesQuery({
		skip: !data && addModal !== false
	});

	useEffect(() => {
		if (capCodesDataSuccess) {
			setCapCode(capCodesData.data);
		}
	}, [capCodesDataSuccess]);

	const handleCapCode = (capCodeSelected, index, questionType) => {
		const createData = {
			itemValue: questionType.value,
			cap_code_id: capCodeSelected?.map((item) => item.value)
		};

		setSelectedCapCode((prevState) => [...prevState, createData]);
	};

	const createQuestionData = {
		question_id: questionData.id,
		product_code: selected?.map((item) => item?.value),
		target_type: targetTypeValue.value,
		matchedQuestions: [trueFollowUp, falseFollowUp, followUp],
		capCode: selectedCapCode
	};

	const handleSubmit = () => {
		if (!selected) {
			toast.error('Ürün Kodu Seçiniz');
			return;
		}

		if (!targetTypeValue.value) {
			toast.error('Paket Tipi Seçiniz');
			return;
		}

		if (!selected) {
			toast.error('Soru Seçiniz');
			return;
		}

		createQuestion(createQuestionData);
	};

	return (
		<Modal
			id={data ? data : 'addQuestionModal'}
			isOpen={addModal}
			toggle={closeModal}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
			role='dialog'
			aria-hidden='true'
			size='lg'
		>
			<ModalHeader toggle={() => setAddModal(false)} className='p-3 bg-white'>
				<span>Soru:</span> {questionData?.translation?.name}
			</ModalHeader>
			<ModalBody onPaste={handlePaste}>
				{isLoading ? (
					<Spinner
						style={{
							width: '3rem',
							height: '3rem'
						}}
					/>
				) : (
					<>
						<h6>Paket Tipi Seçiniz</h6>
						<hr />

						<Row className='mb-5'>
							<Col md='11'>
								<Select
									name='targetType'
									value={targetTypeValue}
									onChange={(value) => setTargetTypeValue(value)}
									options={targetType?.map((item) => ({
										value: item?.value,
										label: item?.label
									}))}
									placeholder='Ürün Kodu Seçiniz'
									isMulti={false}
								/>
							</Col>
						</Row>

						<h6>Ürün Kodu Seçiniz</h6>
						<hr />

						<Row className='mb-5'>
							<Col md='11'>
								<Select
									name='productCode'
									value={selected}
									onChange={(value) => {
										handleSelected(value);
									}}
									options={productCode?.map((item) => ({
										value: item?.id,
										label: item?.name
									}))}
									placeholder='Ürün Kodu Seçiniz'
									isMulti
								/>

								<Alert
									color='info'
									className='mt-3'
									style={{ fontSize: '12px' }}
								>
									Virgül ile ayrılan ürün kodlarını yapıştırabilirsiniz. <br />
									<strong className='mt-1'>
										Örneğin: GC, LIV, DG, PER, SWP,FUAR, CHT, HDL, AVI
									</strong>
								</Alert>
							</Col>
						</Row>

						{selected && (
							<>
								<div className='px-2 pt-2'>
									<Nav className='nav-tabs dropdown-tabs nav-tabs-custom gap-5'>
										{selected?.map((item, index) => (
											<NavItem key={index}>
												<NavLink
													className={classNames({
														'border-primary nav-link-active':
															activeTab === index
													})}
													onClick={() => {
														toggleTab(index);
													}}
													to='#'
												>
													{item?.label}
												</NavLink>
											</NavItem>
										))}
									</Nav>
								</div>

								<TabContent activeTab={activeTab}>
									{selected?.map((item, index) =>
										questionData?.question_type?.key === 'yes/no' ? (
											<TabPane tabId={index} key={index} className='mt-4'>
												<Row className='mb-5'>
													<Col md='11'>
														<Select
															options={capCode?.map((item) => ({
																value: item?.id,
																label: item?.cap_name
															}))}
															isSearchable={true}
															placeholder='Kap Kodu Seçiniz'
															isClearable
															isMulti
															onChange={(capcode) =>
																handleCapCode(capcode, index, item)
															}
														/>
													</Col>

													<TrueFalseQuestion
														questionsData={questionsData}
														setQuestionsData={setQuestionsData}
														index={index}
														item={item}
														trueFollowUp={trueFollowUp}
														setTrueFollowUp={setTrueFollowUp}
														falseFollowUp={falseFollowUp}
														setFalseFollowUp={setFalseFollowUp}
														handleFalseFollowUp={handleFalseFollowUp}
														handleTrueFollowUp={handleTrueFollowUp}
													/>
												</Row>
											</TabPane>
										) : (
											<TabPane tabId={index} key={index}>
												<Row className='mt-3'>
													<Col md='11'>
														<Select
															options={capCode?.map((item) => ({
																value: item?.id,
																label: item?.cap_name
															}))}
															isSearchable={true}
															placeholder='Kap Kodu Seçiniz'
															isClearable
															onChange={(capcode) =>
																handleCapCode(capcode, index, item)
															}
															isMulti
														/>
													</Col>
												</Row>

												<SelectQuestion
													questionsData={questionsData}
													setQuestionsData={setQuestionsData}
													index={index}
													item={item}
													followUpQuestions={followUpQuestions}
													followUp={followUp}
												/>
											</TabPane>
										)
									)}
								</TabContent>
							</>
						)}
					</>
				)}
			</ModalBody>

			<ModalFooter>
				<Button color='primary' onClick={handleSubmit} disabled={createLoading}>
					Kaydet
				</Button>
				<Button color='secondary' onClick={() => setAddModal(false)}>
					Kapat
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const TrueFalseQuestion = ({
	questionsData,
	item,
	trueFollowUp,
	setTrueFollowUp,
	falseFollowUp,
	setFalseFollowUp,
	handleFalseFollowUp,
	handleTrueFollowUp
}) => {
	const [checked, setChecked] = useState(false);

	const handleSwitch = (e) => {
		setChecked(e.target.checked);
		if (!e.target.checked) {
			setFalseFollowUp({});
			setTrueFollowUp({});
		}
	};

	return (
		<>
			<Row className='mt-3'>
				<Col md={6}>
					<div className='mb-4 mt-4 form-check form-switch mb-2'>
						<Input
							className='form-check-input'
							type='checkbox'
							role='switch'
							id='flexSwitchCheckDefault'
							name='isActive'
							onChange={(e) => handleSwitch(e)}
							checked={checked}
						/>

						<label
							className='form-check-label'
							htmlFor='flexSwitchCheckDefault'
						>
							{checked ? 'Bağlantılı soru var' : 'Bağlantılı soru yok'}
						</label>
					</div>
				</Col>
			</Row>
			{checked && (
				<Row className='mb-5'>
					<Col md='11'>
						<Label>
							<h6> Doğru Cevap İçin Soru Seçiniz</h6>
						</Label>
						<Select
							name='trueFollowUpQuestion'
							value={trueFollowUp}
							options={questionsData?.map((item) => ({
								value: item?.id,
								label: item?.translation?.name
							}))}
							onChange={(value) =>
								handleTrueFollowUp(value, item, 'trueFollowUpQuestion')
							}
							placeholder='Doğru Cevap İçin Soru Seçiniz'
							isMulti={false}
							isOptionDisabled={(option) => option.value === item?.id}
						/>
					</Col>

					<Col md='11' className='mt-4'>
						<Label>
							<h6>Yanlış Cevap İçin Soru Seçiniz</h6>
						</Label>

						<Select
							name='falseFollowUpQuestion'
							value={falseFollowUp}
							options={questionsData?.map((item) => ({
								value: item?.id,
								label: item?.translation?.name
							}))}
							onChange={(value) =>
								handleFalseFollowUp(value, item, 'falseFollowUpQuestion')
							}
							placeholder='Yanlış Cevap İçin Soru Seçiniz'
							isMulti={false}
							isOptionDisabled={(option) => option.value === item?.id}
						/>
					</Col>
				</Row>
			)}
		</>
	);
};

const SelectQuestion = ({
	questionsData,
	item,
	followUpQuestions,
	followUp
}) => {
	const [checked, setChecked] = useState(false);

	const handleSwitch = (e) => {
		setChecked(e.target.checked);
	};

	return (
		<>
			<Row className='mt-3'>
				<Col md={6}>
					<div className='mb-4 mt-4 form-check form-switch mb-2'>
						<Input
							className='form-check-input'
							type='checkbox'
							role='switch'
							id='flexSwitchCheckDefault'
							name='isActive'
							onChange={(e) => handleSwitch(e)}
							checked={checked}
						/>

						<label
							className='form-check-label'
							htmlFor='flexSwitchCheckDefault'
						>
							{checked ? 'Bağlantılı soru var' : 'Bağlantılı soru yok'}
						</label>
					</div>
				</Col>
			</Row>
			{checked && (
				<Row>
					<Col sm='12' className='mt-2'>
						<Select
							options={questionsData?.map((item) => ({
								value: item?.id,
								label: item?.translation?.name
							}))}
							placeholder='Bağlantılı Soru Seçiniz'
							value={{
								value: followUp?.value,
								label: followUp?.label
							}}
							onChange={(value) =>
								followUpQuestions(value, item, 'followUpQuestion')
							}
							isOptionDisabled={(option) => option.value === item?.id}
						/>
					</Col>
				</Row>
			)}
		</>
	);
};
export { MatchQuestionModal };
