import React, { useState } from 'react';
import {
	Button,
	Col,
	Input,
	Label,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
	Container
} from 'reactstrap';

import BreadCrumb from '../../Components/Common/BreadCrumb';
import TableComponent from '../../Components/ui/table/Table';
import {
	useGetAllLanguagesQuery,
	useSetLanguagesStatusMutation
} from '../../store/api/languages';

const Languages = () => {
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [data, setData] = useState([]);
	const [debounceSearch, setDebounceSearch] = useState('');
	const [pagination, setPagination] = useState({
		currentPage: 1,
		hasNextPage: false,
		hasPreviousPage: false,
		total: 0,
		totalPages: 0
	});

	const {
		data: languageData,
		isLoading,
		isFetching,
		isSuccess,
		refetch
	} = useGetAllLanguagesQuery({
		search: debounceSearch ? debounceSearch : undefined,
		page: page,
		limit: limit
	});

	const columnData = [
		{
			id: 1,
			name: 'Dil Adı',
			value: 'name'
		},
		{
			id: 2,
			name: 'Dil Kodu',
			value: 'code'
		},
		{
			id: 3,
			name: 'Durumu',
			value: 'status'
		}
	];

	const memoData = React.useMemo(() => {
		if (isSuccess) {
			setData(languageData.data.data);
			setPagination({
				total: languageData.data.total,
				currentPage: languageData.data.current_page,
				totalPages: languageData.data.last_page
			});
		}

		return data;
	}, [isSuccess, languageData, data]);

	const [status] = useSetLanguagesStatusMutation();

	const handlePageChange = (data) => {
		setPage(data);
	};

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<BreadCrumb title='Diller' pageTitle='Sistem' />
					<Row>
						<Col lg={12}>
							<TableComponent
								columnData={columnData}
								data={memoData}
								isLoading={isLoading}
								pagination={pagination}
								limit={limit}
								setLimit={setLimit}
								setPage={setPage}
								setSearch={setSearch}
								search={search}
								filter={true}
								refetch={refetch}
								setDebounceSearch={setDebounceSearch}
								setPagination={setPagination}
								handlePageChange={handlePageChange}
								page={page}
								showStatus={true}
								status={status}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Languages;
