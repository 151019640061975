import React, { useState } from 'react';
import { Container, Spinner } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import TableComponent from '../../Components/ui/table/Table';
import {
	useGetAllCountriesQuery,
	useChangeStatusMutation
} from '../../store/api/countries';

const Countries = () => {
	document.title = 'Ülkeler | Yeditepe Go';

	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [data, setData] = useState([]);
	const [debounceSearch, setDebounceSearch] = useState('');
	const [pagination, setPagination] = useState({
		currentPage: 1,
		hasNextPage: false,
		hasPreviousPage: false,
		total: 0,
		totalPages: 0
	});

	const {
		data: countriesData,
		isLoading,
		isFetching,
		isSuccess,
		refetch
	} = useGetAllCountriesQuery({
		search: debounceSearch ? debounceSearch : undefined,
		page: page,
		limit: limit
	});

	const columnData = [
		{
			id: 1,
			name: 'Kod',
			value: 'code'
		},
		{
			id: 2,
			name: 'Ülke Adı',
			value: 'name_tr'
		},

		{
			id: 3,
			name: 'Durumu',
			value: 'status'
		},
		{
			id: 4,
			name: 'Şehirler',
			value: 'cities'
		}
	];

	const [
		status,
		{
			data: statusData,
			isLoading: statusLoading,
			isSuccess: statusSuccess,
			isError: statusError
		}
	] = useChangeStatusMutation();

	const memoData = React.useMemo(() => {
		if (isSuccess) {
			setData(countriesData.data.data);
			setPagination({
				total: countriesData.data.total,
				currentPage: countriesData.data.current_page,
				totalPages: countriesData.data.total,
				hasNextPage: countriesData.data.next_page_url,
				hasPreviousPage: countriesData.data.prev_page_url ? true : false
			});
		}

		return data;
	}, [isSuccess, countriesData, data]);

	const handlePageChange = (data) => {
		setPage(data);
	};

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<BreadCrumb title='Ülkeler' pageTitle='Sistem' />
					{isLoading || isFetching ? (
						<div className='text-center flex'>
							<Spinner color='primary' />
						</div>
					) : (
						<TableComponent
							columnData={columnData}
							data={memoData}
							isLoading={isLoading}
							pagination={pagination}
							limit={limit}
							setLimit={setLimit}
							setPage={setPage}
							setSearch={setSearch}
							search={search}
							filter={true}
							status={status}
							statusData={statusData}
							statusLoading={statusLoading}
							statusSuccess={statusSuccess}
							statusError={statusError}
							refetch={refetch}
							setDebounceSearch={setDebounceSearch}
							setPagination={setPagination}
							handlePageChange={handlePageChange}
							page={page}
							totalPages={pagination.totalPages}
							showStatus={true}
						/>
					)}
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Countries;
