import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import {
	useGetAllQuestionsQuery,
	useSaveMatchedQuestionMutation
} from '../../../store/api/questions';

const AddQuestionFromPool = ({
	addModal,
	isEdit,
	dataId,
	setAddModal,
	targetType
}) => {
	const closeModal = () => {
		setAddModal(false);
	};

	const {
		data: allQuestions,
		isSuccess: allQuestionsSuccess,
		isLoading: allQuestionsLoading
	} = useGetAllQuestionsQuery(undefined, { skip: !addModal });

	const [checked, setChecked] = useState([]);
	const handleCheckboxes = (e) => {
		const { name, checked } = e.target;

		setChecked((prev) => {
			if (checked) {
				return [...prev, name];
			} else {
				return prev.filter((item) => item !== name);
			}
		});
	};

	const [addMatchQuestion, { isSuccess: addMatchQuestionSuccess }] =
		useSaveMatchedQuestionMutation();

	useEffect(() => {
		if (addMatchQuestionSuccess) {
			toast.success('Soru eklendi');
			setAddModal(false);
		}
	}, [addMatchQuestionSuccess]);

	const handleAddMatchedQuestion = () => {
		checked.map((question) => {
			addMatchQuestion({
				question_id: question,
				product_code: [dataId],
				target_type: targetType,
				matchedQuestions: [null, null, null],
				capCode: []
			});
		});
	};

	return (
		<Modal
			id={dataId ? dataId : 'addQuestionModal'}
			isOpen={addModal}
			toggle={closeModal}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
		>
			<ModalHeader toggle={() => setAddModal(false)} className='p-3 bg-white'>
				{isEdit ? 'Soru Düzenle' : 'Soru Ekle'}
			</ModalHeader>
			<ModalBody>
				<Table className='align-items-center table-flush' responsive hover>
					<thead
						className='thead-light'
						style={{
							display: 'table',
							width: '100%',
							tableLayout: 'fixed'
						}}
					>
						<tr>
							<th scope='col'>Soru</th>
						</tr>
					</thead>
					<tbody
						style={{
							display: 'block',
							maxHeight: '300px',
							overflowY: 'auto',
							overflowX: 'hidden'
						}}
					>
						{allQuestionsSuccess &&
							allQuestions?.data?.map((question) => (
								<tr key={question.id} className='table-row w-100'>
									<td>
										<input
											type='checkbox'
											name={question.id}
											checked={checked[question.id]}
											onChange={handleCheckboxes}
											id={question.id}
										/>
									</td>
									<td htmlFor={question.id} className='w-100'>
										<label htmlFor={question.id}>
											{question.translation.name}
										</label>
									</td>
								</tr>
							))}
					</tbody>
				</Table>
			</ModalBody>
			<ModalFooter
				className='p-3 bg-white'
				style={{
					display: 'flex',
					justifyContent: 'flex-end'
				}}
			>
				<button
					className='btn btn-primary'
					onClick={() => {
						handleAddMatchedQuestion();
					}}
				>
					Ekle
				</button>
			</ModalFooter>
		</Modal>
	);
};

export { AddQuestionFromPool };
