import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Spinner } from 'reactstrap';
import TableComponent from '../../../Components/ui/table/Table';

import { useGetShipmentsQuery } from '../../../store/api/shipments';
import { setCompaniesCount } from '../../../store/slices/shipments/shipmentsSlices';
import { useDispatch } from 'react-redux';
import ShowChildShipments from '../../../Components/ui/modals/ShowChildShipments';

const List = () => {
	document.title = 'Aktif Sevkiyatlar | Yeditepe Go';
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [data, setData] = useState([]);
	const [debounceSearch, setDebounceSearch] = useState('');
	const [pagination, setPagination] = useState({
		currentPage: 1,
		hasNextPage: false,
		hasPreviousPage: false,
		total: 0,
		totalPages: 0
	});

	const {
		data: shipmentData,
		isLoading,
		isSuccess,
		refetch
	} = useGetShipmentsQuery({
		search: debounceSearch ? debounceSearch : undefined,
		page: page === 0 ? 1 : page,
		limit: limit
	});

	useEffect(() => {
		if (debounceSearch) {
			setPage(1);
		} else {
			refetch();
		}
	}, [debounceSearch]);

	const columnData = [
		{
			id: 1,
			name: 'Taşıyıcı',
			value: 'carrier.num_code'
		},
		{
			id: 3,
			name: 'MAWB',
			value: 'mawb_code'
		},
		{
			id: 2,
			name: 'HAWB',
			value: 'hawb_code'
		},
		{
			id: 4,
			name: 'Firma Adı',
			value: 'company.name'
		},
		{
			id: 5,
			name: 'Ürün Türü',
			value: 'product_code.name'
		},
		{
			id: 6,
			name: 'Kalkış Yeri',
			value: 'port_departure'
		},
		{
			id: 7,
			name: 'Varış Yeri',
			value: 'port_arrival'
		},
		{
			id: 8,
			name: 'Tarih',
			value: 'option_date'
		},
		{
			id: 9,
			name: 'Detaylar',
			value: 'details',
			detailLink: '/shipments/detail'
		}
	];

	const [flatData, setFlatData] = useState([]);

	const [addModal, setAddModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [visibility, setVisibility] = useState(false);
	const [id, setId] = useState(null);

	const handleShowModal = (id) => {
		setAddModal(!addModal);
		setVisibility(!visibility);
		setId(id);
	};

	useEffect(() => {
		if (isSuccess) {
			const flat = Object.values(shipmentData?.data.data)
				.map((item) => item)
				.flatMap((item) => item);
			setFlatData(flat);
		}
	}, [isSuccess, shipmentData]);

	const dispatch = useDispatch();
	const [weight, setWeight] = useState(0);

	useEffect(() => {
		if (isSuccess) {
			setData(flatData);
			setPagination({
				total: shipmentData.data.total,
				currentPage: shipmentData.data.current_page,
				totalPages: shipmentData.data.last_page,
				hasNextPage: shipmentData.data.next_page_url,
				hasPreviousPage: shipmentData.data.prev_page_url ? true : false
			});

			data.map((item, key) => {
				setWeight(weight + Number(item.chargeable_weight));
			});

			dispatch(
				setCompaniesCount({
					count: shipmentData.data.total,
					weight: weight
				})
			);
		}

		// 	// 	return data;
	}, [isSuccess, shipmentData, data]);

	const handlePageChange = (data) => {
		setPage(data);
	};

	return (
		<Col xl={12}>
			<Card className='card-height-100'>
				<CardBody>
					{isLoading ? (
						<Spinner
							style={{
								width: '3rem',
								height: '3rem'
							}}
						/>
					) : (
						<TableComponent
							columnData={columnData}
							data={data}
							isLoading={isLoading}
							pagination={pagination}
							limit={limit}
							setLimit={setLimit}
							setPage={setPage}
							setSearch={setSearch}
							search={search}
							filter={true}
							refetch={refetch}
							setDebounceSearch={setDebounceSearch}
							setPagination={setPagination}
							handlePageChange={handlePageChange}
							page={page}
							handleShowModal={handleShowModal}
						/>
					)}
				</CardBody>
			</Card>

			<React.Fragment>
				<ShowChildShipments
					addModal={addModal}
					isEdit={isEdit}
					visibility={visibility}
					setAddModal={setAddModal}
					id={id}
				/>
			</React.Fragment>
		</Col>
	);
};

export default List;
