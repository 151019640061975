import apiMiddleware from './middleware/ApiMiddleware';

export const trackerApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getTracker: builder.query({
			query: (filter) => ({
				url: '/trackers/get-filtered',
				params: filter
			})
		})
	})
});

export const { useGetTrackerQuery } = trackerApi;
