import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import List from './List';

const DashboardCrm = () => {
	document.title = 'Firmalar | Yeditepe Go';
	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<BreadCrumb title='Firmalar' pageTitle='Yönetim' />
					<List />
				</Container>
			</div>
		</React.Fragment>
	);
};

export default DashboardCrm;
