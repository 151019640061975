import apiMiddleware from './middleware/ApiMiddleware';

export const shipmentApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getShipments: builder.query({
			query: (filter) => ({
				url: '/air-shipments/get-filtered-active',
				params: filter
			})
		}),
		getShipmentDetail: builder.query({
			query: (id) => ({
				url: `air-shipments/get-by-id/${id}`
			}),
			providesTags: ['Languages']
		}),
		getComplatedShipments: builder.query({
			query: (filter) => ({
				url: '/air-shipments/get-filtered-completed',
				params: filter
			})
		}),
		getPendingShipments: builder.query({
			query: (filter) => ({
				url: `/air-shipments/get-filtered-pending/`,
				params: filter
			})
		}),
		getShipmentsByCustomer: builder.query({
			query: (data) => ({
				url: `/air-shipments/get-filtered-by-customer/${data.customer}`,
				params: data.filter
			})
		}),
		getShipmentDetailByMawb: builder.query({
			query: (mawb) => ({
				url: `/air-shipments/get-by-mawbcode-list/${mawb}`
			})
		}),
		regenerateAirShipmentPdf: builder.query({
			query: (shipmentId) => ({
				url: `/air-shipments/regenerate-pdf/${shipmentId}`
			})
		})
	})
});

export const {
	useGetShipmentsQuery,
	useGetShipmentDetailQuery,
	useGetShipmentDetailByMawbQuery,
	useGetComplatedShipmentsQuery,
	useGetPendingShipmentsQuery,
	useGetShipmentsByCustomerQuery,
	useRegenerateAirShipmentPdfQuery
} = shipmentApi;
