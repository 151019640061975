import React from 'react';
import { Link } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap';
import { bestSellingProducts } from './data';

const CompaniesByShipments = () => {
	return (
		<React.Fragment>
			<Card className='card-height-100'>
				<CardHeader className='align-items-center d-flex'>
					<h4 className='card-title mb-0 flex-grow-1'>
						Sevkiyat Miktarlarına Göre Firmalar
					</h4>
					<div className='flex-shrink-0'>
						<UncontrolledDropdown
							className='card-header-dropdown'
							direction='start'
						>
							<DropdownToggle tag='a' className='text-reset' role='button'>
								<span className='fw-semibold text-uppercase fs-12'>
									Sort by:{' '}
								</span>
								<span className='text-muted'>
									Today<i className='mdi mdi-chevron-down ms-1'></i>
								</span>
							</DropdownToggle>
							<DropdownMenu className='dropdown-menu-end'>
								<DropdownItem>Today</DropdownItem>
								<DropdownItem>Yesterday</DropdownItem>
								<DropdownItem>Last 7 Days</DropdownItem>
								<DropdownItem>Last 30 Days</DropdownItem>
								<DropdownItem>This Month</DropdownItem>
								<DropdownItem>Last Month</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</div>
				</CardHeader>
				<CardBody>
					<div className='table-responsive table-card'>
						<table className='table align-middle table-borderless table-centered table-nowrap mb-0'>
							<thead className='text-muted table-light'>
								<tr>
									<th scope='col' style={{ width: '62' }}>
										Firma
									</th>
									<th scope='col'>Sevkiyat Miktarı</th>
									<th scope='col'>Son Sevkiyat Tarihi</th>
								</tr>
							</thead>
							<tbody>
								{(bestSellingProducts || []).map((item, index) => (
									<tr key={index}>
										<td>
											<Link to='company'>
												<img
													src={item.img}
													alt=''
													className='avatar-xs me-2 rounded-circle'
												/>
												{item.company}
											</Link>
										</td>
										<td>{item.shipment}</td>
										<td>{item.lastShipment}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default CompaniesByShipments;
