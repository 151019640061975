import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	Row,
	Spinner,
	TabContent,
	TabPane
} from 'reactstrap';
import toast from 'react-hot-toast';

import Select from 'react-select';
import { useGetAllCountriesQuery } from '../../../store/api/countries';
import { useGetAirportsByCountryQuery } from '../../../store/api/airports';
import {
	useGetAddressByUserQuery,
	useUpdateOrCreateUserAddressMutation
} from '../../../store/api/address';

const UserAirportModal = ({ show, onCloseClick, userId, userName }) => {
	const [selectedCountry, setSelectedCountry] = useState();
	const [selectedAirport, setSelectedAirport] = useState();
	const [countryList, setCountryList] = useState([]);
	const [airportList, setAirportList] = useState([]);

	const {
		data: userAddress,
		isLoading: userAddressLoading,
		isSuccess: userAddressIsSuccess
	} = useGetAddressByUserQuery(userId, { skip: !userId });

	const {
		data: countriesData,
		isLoading: countriesDataLoading,
		isSuccess: countriesDataIsSuccess
	} = useGetAllCountriesQuery({
		search: undefined,
		page: 1,
		limit: 300
	});

	const {
		data: airPortsData,
		isLoading: airPortsDataLoading,
		isSuccess: airPortsDataIsSuccess
	} = useGetAirportsByCountryQuery(selectedCountry?.value, {
		skip: !selectedCountry
	});

	useEffect(() => {
		if (!userAddressLoading && userAddress) {
			if (userAddress?.data != null) {
				if (countryList.length > 0) {
					const findCountry = countryList.find(
						(cl) => cl.value == userAddress?.data?.country_id
					);
					if (findCountry) {
						setSelectedCountry(findCountry);
					}
				}
				if (airportList.length > 0) {
					const findPort = airportList.find(
						(apl) => apl.value == userAddress?.data?.port_id
					);
					if (findPort) {
						setSelectedAirport(findPort);
					}
				}
			}
		}
	}, [
		userAddressLoading,
		userAddress,
		countryList,
		airportList,
		selectedCountry
	]);

	const [
		updateOrCreateUserAddress,
		{ isError: isUpdateError, isSuccess: isUpdateSuccess }
	] = useUpdateOrCreateUserAddressMutation();

	useEffect(() => {
		const list = [];
		if (!countriesDataLoading && countriesData) {
			for (const country of countriesData.data.data) {
				list.push({
					value: country.id,
					label: country.name_tr
				});
			}

			setCountryList(list);
		}
	}, [countriesData, countriesDataLoading]);

	useEffect(() => {
		const list = [];
		if (!airPortsDataLoading && airPortsData) {
			for (const airport of airPortsData.data) {
				list.push({
					value: airport.id,
					label: airport.name
				});
			}

			setAirportList(list);
		}
	}, [airPortsData, airPortsDataLoading]);

	useEffect(() => {
		if (isUpdateError) {
			toast.error(
				'Kullanıcı havaalanı ayarları kaydedilirken bir sorun oluştu.'
			);
		}

		if (isUpdateSuccess) {
			toast.success('Kullanıcı havaalanı ayarları başarıyla kaydedilmiştir.');
			onCloseClick();
		}
	}, [isUpdateSuccess, isUpdateError]);

	const handleUpdate = () => {
		if (selectedCountry == undefined || selectedCountry == null) {
			toast.error('Lütfen ülke seçiniz');
		} else if (selectedAirport == undefined || selectedAirport == null) {
			toast.error('Lütfen havaalanı seçiniz');
		} else {
			const data = {
				user_id: userId,
				port_id: selectedAirport.value
			};
			updateOrCreateUserAddress(data);
		}
	};

	const handleModalClose = () => {
		setAirportList([]);
		setSelectedCountry();
		setSelectedAirport();
		onCloseClick();
	};

	return (
		<Modal
			id={'editMatchedQuestion'}
			isOpen={show}
			toggle={onCloseClick}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
			role='dialog'
			aria-hidden='true'
			size='lg'
		>
			<ModalHeader toggle={handleModalClose} className='p-3 bg-white'>
				<span>Havalimanı Ayarları ({userName})</span>
			</ModalHeader>
			<ModalBody>
				{countriesDataLoading ? (
					<Spinner
						style={{
							width: '3rem',
							height: '3rem'
						}}
					/>
				) : (
					<>
						<h6>Ülke Seçiniz</h6>
						<hr />

						<Row className='mb-3'>
							<Col md='12'>
								<Select
									name='country'
									value={selectedCountry}
									onChange={(v) => setSelectedCountry(v)}
									options={countryList}
									placeholder='Ülke Seçiniz'
									isMulti={false}
								/>
							</Col>
						</Row>

						{selectedCountry && airportList.length > 0 && (
							<>
								<h6>Havaalanı Seçiniz</h6>
								<hr />

								<Row className='mb-3'>
									<Col md='12'>
										<Select
											name='airport'
											value={selectedAirport}
											onChange={(v) => setSelectedAirport(v)}
											options={airportList}
											placeholder='Havaalanı Seçiniz'
											isMulti={false}
										/>
									</Col>
								</Row>
							</>
						)}
					</>
				)}
			</ModalBody>

			<ModalFooter>
				<Button color='primary' onClick={handleUpdate}>
					Kaydet
				</Button>
				<Button color='secondary' onClick={handleModalClose}>
					Kapat
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export { UserAirportModal };
