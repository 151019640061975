import apiMiddleware from './middleware/ApiMiddleware';

export const transactionsCodeApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getAllTransactionsCode: builder.query({
			query: (filter) => ({
				url: '/product-codes/get-filtered',
				params: filter
			}),
			providesTags: ['TransactionsCode']
		}),
		getTransactionCodeById: builder.query({
			query: (id) => ({
				url: `/product-codes/get-by-id/${id}`
			}),
			providesTags: ['TransactionsCode']
		}),
		getAirShipmentsFromTransactionCode: builder.query({
			query: (id) => ({
				url: `/air-shipments/get-filtered-by-product-code/${id}`
			}),
			providesTags: ['TransactionsCode']
		}),
		getMatchedQuestionFromProductCodeAndTargetType: builder.query({
			query: ({ productCode, targetType }) => ({
				url: `/matched-questions/get-filtered-by-product-code/${productCode}/${targetType}`
			}),
			providesTags: ['TransactionsCode']
		})
	})
});

export const {
	useGetAllTransactionsCodeQuery,
	useGetTransactionCodeByIdQuery,
	useGetAirShipmentsFromTransactionCodeQuery,
	useGetMatchedQuestionFromProductCodeAndTargetTypeQuery
} = transactionsCodeApi;
