import React, { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	ModalFooter,
	Spinner
} from 'reactstrap';

import Select from 'react-select';

import { useGetAllCustomerQuery } from '../../../store/api/customer';
import { useGetLanguageQuery } from '../../../store/api/languages';
import { toast } from 'react-hot-toast';
import {
	useGetAllCompaniesQuery,
	useGetRolesByCompanyIdQuery
} from '../../../store/api/companies';

import {
	useAddUsersMutation,
	useCreateRolebackUserMutation,
	useGetUsersByIdQuery,
	useUpdateUserMutation
} from '../../../store/api/user';
import DeleteModal from '../../Common/DeleteModal';

export const AddUserModal = ({ addModal, setAddModal, isEdit, dataId }) => {
	const { data: languageData, isSuccess: isLanguageSuccess } =
		useGetLanguageQuery();

	const [checked, setChecked] = useState(true);

	const [memberDetail, setMemberDetail] = useState({
		status: 1,
		email: '',
		password: '',
		password_confirmation: '',
		company_id: '',
		customer_id: '',
		role_id: '',
		language_id: '',
		first_name: '',
		last_name: '',
		language_label: '',
		status: checked ? 1 : 0
	});
	const {
		data: companies,
		isSuccess: isCompaniesSuccess,
		refetch
	} = useGetAllCompaniesQuery();

	const { data: customers, isSuccess: isCustomersSuccess } =
		useGetAllCustomerQuery({
			skip: !addModal
		});

	const {
		data: rolesData,
		isSuccess: isRolesSuccess,
		isFetching: isRoleFetching,
		isLoading: isRoleLoading,
		refetch: refetchRoles
	} = useGetRolesByCompanyIdQuery(
		memberDetail.company_id
			? memberDetail.company_id
			: memberDetail.customer_id,
		{
			skip:
				!memberDetail?.company_id === null &&
				!memberDetail?.customer_id === null
		}
	);

	useEffect(() => {
		refetchRoles();
	}, [memberDetail?.company_id, memberDetail?.customer_id]);

	const [getAllRole, setGetAllRole] = useState([]);

	const [
		createUser,
		{
			isSuccess: isCreateUserSuccess,
			isLoading: isCreateUserLoading,
			isError: isCreateUserError,
			error: createUserError
		}
	] = useAddUsersMutation();

	useEffect(() => {
		if (isRolesSuccess) {
			setGetAllRole(rolesData.data);
		}
	}, [isRolesSuccess, rolesData]);

	const companyOptions = companies?.data?.map((company) => ({
		value: company.id,
		label: company.name
	}));

	const customerOptions = customers?.data?.map((customer) => ({
		value: customer.id,
		label: customer.name
	}));

	const [
		updateUser,
		{
			isSuccess: isUpdateUserSuccess,
			isError: isUpdateError,
			error: updateUserError,
			isLoading: isUpdateUserLoading
		}
	] = useUpdateUserMutation();

	useEffect(() => {
		if (isUpdateUserSuccess) {
			toast.success('User updated successfully');
			setAddModal(false);
		}
	}, [isUpdateUserSuccess]);

	useEffect(() => {
		if (isUpdateError) {
			toast.error(updateUserError?.data?.message);
		}
	}, [isUpdateError, updateUserError]);

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (memberDetail.first_name === '') {
			toast.error('First Name is required');
			return;
		}

		if (memberDetail.last_name === '') {
			toast.error('Last Name is required');
			return;
		}

		if (memberDetail.email === '') {
			toast.error('Email is required');
			return;
		}
		if (
			!isEdit &&
			memberDetail.password !== memberDetail.password_confirmation
		) {
			toast.error('Password and Confirm Password must be same');
			return;
		}

		if (!isEdit && memberDetail.password.length < 6) {
			toast.error('Password must be at least 6 characters');
			return;
		}

		if (!isEdit && memberDetail.password_confirmation.length < 6) {
			toast.error('Confirm Password must be at least 6 characters');
			return;
		}

		if (!memberDetail.company_id === '' || !memberDetail.customer_id === '') {
			toast.error('Company or customer  is required');
			return;
		}

		if (memberDetail.role_id === '') {
			toast.error('Role is required');
			return;
		}

		if (memberDetail.language_id === '') {
			toast.error('Language is required');
			return;
		}

		if (isEdit) {
			updateUser({
				id: dataId?.id,
				data: {
					...memberDetail,
					status: checked ? 1 : 0
				}
			});
		} else {
			createUser({
				...memberDetail,
				status: checked ? 1 : 0
			});
		}
	};

	const handleSwitch = (e) => {
		setChecked(e.target.checked);
	};

	const {
		data: initialUser,
		isSuccess: isInitialUserSuccess,
		isLoading: isInitialUserLoading
	} = useGetUsersByIdQuery(dataId?.id, {
		skip: !isEdit || !dataId?.id
	});

	useEffect(() => {
		if (isEdit) {
			setMemberDetail({
				...memberDetail,
				first_name: initialUser?.data?.profile?.first_name,
				last_name: initialUser?.data?.profile?.last_name,
				email: initialUser?.data?.email,
				company_id: initialUser?.data?.role?.company?.id,
				customer_id: initialUser?.data?.role?.customer?.id,
				role_id: initialUser?.data?.role?.translation?.role_id,
				language_id: initialUser?.data?.profile?.language_id,
				status: initialUser?.data?.role?.status
			});
		}
	}, [isEdit, dataId, initialUser]);

	useEffect(() => {
		if (isCreateUserError) {
			toast.error(createUserError?.data?.message);
		}
	}, [isCreateUserError, createUserError]);

	const [rolebackUserModal, setRolebackUserModal] = useState(false);
	const [rolebackUserId, setRolebackUserId] = useState(null);

	useEffect(() => {
		if (createUserError?.status === 406) {
			setRolebackUserModal(true);
			setRolebackUserId(createUserError.data.data.id);
		}
	}, [isCreateUserError, createUserError]);

	useEffect(() => {
		if (isCreateUserSuccess) {
			toast.success('User created successfully');
			setAddModal(false);
			setMemberDetail({});
		}
	}, [isCreateUserSuccess]);

	const [
		createRoleback,
		{
			isLoading: isCreateRolebackLoading,
			isSuccess: isCreateRolebackSuccess,
			isError: isCreateRolebackError,
			error: isCreateRolebackErrorMsg
		}
	] = useCreateRolebackUserMutation();

	const handleRoleBackUser = () => {
		createRoleback(rolebackUserId);
		setRolebackUserModal(false);
	};

	useEffect(() => {
		if (isCreateRolebackSuccess) {
			toast.success('Kullanıcı başarıyla geri alındı');
			setAddModal(false);
		}
	}, [isCreateRolebackSuccess]);

	return (
		<>
			<Modal
				id='RoleModal'
				isOpen={addModal}
				toggle={() => setAddModal((prevState) => !prevState)}
				modalClassName='zoomIn'
				centered
				tabIndex='-1'
				keyboard={true}
				size='lg'
			>
				<ModalHeader toggle={() => setAddModal(false)} className='p-3 bg-white'>
					{isEdit ? 'Kullanıcı Düzenle' : 'Kullanıcı Oluştur'}
				</ModalHeader>
				<ModalBody>
					{isEdit && isInitialUserLoading && (
						<div className='d-flex justify-content-center'>
							<Spinner color='primary' />
						</div>
					)}
					{isEdit && isInitialUserSuccess ? (
						<CreateUserForm
							isEdit={isEdit}
							memberDetail={memberDetail}
							companyOptions={companyOptions}
							customerOptions={customerOptions}
							setMemberDetail={setMemberDetail}
							getAllRole={getAllRole}
							handleFormSubmit={handleFormSubmit}
							handleSwitch={handleSwitch}
							checked={checked}
							setChecked={setChecked}
							languageData={languageData}
						/>
					) : (
						<CreateUserForm
							isEdit={false}
							memberDetail={memberDetail}
							companyOptions={companyOptions}
							customerOptions={customerOptions}
							setMemberDetail={setMemberDetail}
							getAllRole={getAllRole}
							handleFormSubmit={handleFormSubmit}
							handleSwitch={handleSwitch}
							checked={checked}
							setChecked={setChecked}
							languageData={languageData}
						/>
					)}
				</ModalBody>
			</Modal>

			<Modal
				id='roleBack'
				isOpen={rolebackUserModal}
				toggle={() => setRolebackUserModal((prevState) => !prevState)}
				modalClassName='zoomIn'
				centered
				tabIndex='-1'
				keyboard={true}
				size='lg'
			>
				<ModalHeader
					toggle={() => setRolebackUserModal(false)}
					className='p-3 bg-white'
				>
					Kullanıcıyı Geri Getir
				</ModalHeader>
				<ModalBody>
					<Row className='g-4'>
						<Col md={12}>
							<Row>
								<Col md={12} className='text-center'>
									<h4>Kullanıcı daha önce silinmiş.</h4>
									<p>Silinen kullanıcıyı geri getirmek istiyor musunuz?</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</ModalBody>

				<ModalFooter>
					<Button
						color='primary'
						onClick={() => handleRoleBackUser()}
						disabled={isCreateRolebackLoading}
					>
						{isCreateRolebackLoading ? (
							<Spinner color='light' size='sm' />
						) : (
							'Geri Getir'
						)}
					</Button>
					<Button
						color='secondary'
						onClick={() => setRolebackUserModal(false)}
						disabled={isCreateRolebackLoading}
					>
						İptal
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

const CreateUserForm = ({
	isEdit,
	memberDetail,
	companyOptions,
	setMemberDetail,
	getAllRole,
	handleFormSubmit,
	handleSwitch,
	checked,
	languageData
}) => {
	return (
		<Form onSubmit={(e) => handleFormSubmit(e)}>
			<Row className='g-4'>
				<Col md={6}>
					<label>Ad</label>
					<Input
						type='text'
						name='first_name'
						placeholder='Ad'
						value={memberDetail?.first_name || ''}
						onChange={(e) => {
							setMemberDetail({
								...memberDetail,
								first_name: e.target.value
							});
						}}
					/>
				</Col>
				<Col md={6}>
					<label>Soyad</label>
					<Input
						type='text'
						name='last_name'
						placeholder='soyad'
						value={memberDetail?.last_name || ''}
						onChange={(e) => {
							setMemberDetail({
								...memberDetail,
								last_name: e.target.value
							});
						}}
					/>
				</Col>
				<Col md={12}>
					<label>E-Posta</label>
					<Input
						type='email'
						name='email'
						placeholder='E-Posta'
						value={memberDetail?.email || ''}
						onChange={(e) => {
							setMemberDetail({
								...memberDetail,
								email: e.target.value
							});
						}}
					/>
				</Col>
				<Col md={6}>
					<label>Şifre</label>
					<Input
						type='password'
						name='password'
						placeholder='Şifre'
						value={memberDetail?.password}
						onChange={(e) => {
							setMemberDetail({
								...memberDetail,
								password: e.target.value
							});
						}}
					/>
					<small className='text-danger'>
						Şifre en az 8 karakter uzunluğunda olmalıdır!
					</small>
				</Col>
				<Col md={6}>
					<label>Şifre(Yeniden)</label>
					<Input
						type='password'
						name='password_confirmation'
						placeholder='Şifre(Yeniden)'
						value={memberDetail?.password_confirmation}
						onChange={(e) => {
							setMemberDetail({
								...memberDetail,
								password_confirmation: e.target.value
							});
						}}
					/>
					<small className='text-danger'>
						Şifre en az 8 karakter uzunluğunda olmalıdır!
					</small>
				</Col>
				<Col md={6}>
					<label>Şirket</label>
					<Select
						placeholder='Şirket Seçiniz'
						onChange={(value, removedValue) => {
							if (removedValue.action === 'clear') {
								setMemberDetail({
									...memberDetail,
									company_id: ''
								});
							}

							setMemberDetail({
								...memberDetail,
								company_id: value.value
							});
						}}
						value={
							!isEdit
								? companyOptions?.find(
										(company) => company?.value === memberDetail.company_id
								  ) || null
								: companyOptions?.find(
										(company) => company?.value === memberDetail.company_id
								  ) || null
						}
						options={companyOptions}
						isDisabled={memberDetail.customer_id ? true : false}
						isClearable
					/>
				</Col>

				<Col md={6}>
					<label>Müşteri</label>
					<Select
						placeholder='Müşteri Seçiniz'
						label='Müşteri'
						onChange={(value, removedValue) => {
							if (removedValue.action === 'clear') {
								setMemberDetail({
									...memberDetail,
									company_id: ''
								});
							}

							setMemberDetail({
								...memberDetail,
								customer_id: value.value
							});
						}}
						value={companyOptions?.find(
							(company) => company?.value === memberDetail?.customer_id
						)}
						options={companyOptions}
						isDisabled={memberDetail.company_id ? true : false}
						isClearable
					/>
				</Col>

				<Col md={6}>
					<label>Role</label>
					<Select
						placeholder='Yetkileri Seçiniz'
						options={getAllRole?.map((item) => ({
							value: item?.translation?.role_id,
							label: item?.translation?.name
						}))}
						onChange={(e) => {
							setMemberDetail({
								...memberDetail,
								role_id: e.value
							});
						}}
						value={
							getAllRole?.find(
								(role) => role?.translation?.role_id === memberDetail?.role_id
							)
								? {
										label: getAllRole?.find(
											(role) =>
												role?.translation?.role_id === memberDetail?.role_id
										).translation?.name,
										value: getAllRole?.find(
											(role) =>
												role?.translation?.role_id === memberDetail?.role_id
										).translation?.role_id
								  }
								: null
						}
						isClearable={true}
					/>
				</Col>

				<Col md={6}>
					<label>Dil Seçiniz</label>

					<Select
						placeholder='Dilleri Seçiniz'
						options={
							languageData?.data.map((item) => ({
								label: item.name,
								value: item.id
							})) || []
						}
						value={
							languageData?.data.find(
								(language) => language.id === memberDetail?.language_id
							)
								? {
										label: languageData?.data.find(
											(language) => language.id === memberDetail?.language_id
										).name,
										value: languageData?.data.find(
											(language) => language.id === memberDetail?.language_id
										).id
								  }
								: null
						}
						onChange={(e) => {
							setMemberDetail({
								...memberDetail,
								language_id: e.value
							});
						}}
					/>
				</Col>
				{/* FIXME: STATUS SWITCH IS NOT WORKING */}
				<Col md={6}>
					<label>Durumu</label>
					<div className='form-check form-switch mb-2'>
						<Input
							className='form-check-input'
							type='checkbox'
							role='switch'
							id='flexSwitchCheckDefault'
							name='isActive'
							onChange={(e) => handleSwitch(e)}
							checked={checked}
						/>

						<label
							className='form-check-label'
							htmlFor='flexSwitchCheckDefault'
						>
							{checked ? 'Aktif' : 'Pasif'}
						</label>
					</div>
				</Col>
			</Row>

			<ModalFooter className='p-3 bg-white'>
				<Button color='primary' type='submit' className='px-5'>
					{isEdit ? 'Düzenle' : 'Oluştur'}
				</Button>
			</ModalFooter>
		</Form>
	);
};

export default AddUserModal;
