import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getToken } from '../store/slices/auth/authSlice';

const AuthProtected = (props) => {
	// const isToken = useSelector((state) => state.authSlice.access_token);
	const isToken = useSelector(getToken);

	if (!isToken) {
		return (
			<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
		);
	} else {
		<Redirect
			to={{ pathname: '/dashboard', state: { from: props.location } }}
		/>;
	}

	return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				return (
					<>
						<Component {...props} />
					</>
				);
			}}
		/>
	);
};

export { AuthProtected, AccessRoute };
