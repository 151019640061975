import React from 'react';
import { withRouter } from 'react-router-dom';

const ParticlesAuth = ({ children }) => {
	return (
		<React.Fragment>
			<div className='auth-page-wrapper'>
				<div className='auth-one-bg-position auth-one-bg' id='auth-particles'>
					<div className='bg-overlay'></div>
				</div>

				{/* pass the children */}
				{children}

				<footer className='footer'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-12'>
								<div className='text-center'>
									<p className='mb-0 text-muted'>
										&copy; {new Date().getFullYear()} Yeditepe. Crafted with{' '}
										<i className='mdi mdi-heart text-danger'></i> by
										Nono.company
									</p>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		</React.Fragment>
	);
};

export default withRouter(ParticlesAuth);
