import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
	Alert,
	Col,
	Form,
	Input,
	Nav,
	NavItem,
	Row,
	Spinner,
	TabContent,
	TabPane
} from 'reactstrap';
import { functionFindName } from '../../helpers/functionFindRoleName';
import { useGetLanguageQuery } from '../../store/api/languages';

const LanguagesTab = ({
	role,
	fetchedData,
	fetchedDataSuccess,
	handleRoleChange,
	withoutHint,
	type
}) => {
	const {
		data: languages,
		isSuccess: isLanguagesSuccess,
		isLoading
	} = useGetLanguageQuery();

	const [activeTab, setActiveTab] = useState(languages?.data[0]?.id);

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const [emptyFields, setEmptyFields] = useState(false);

	const objToAray = (obj) => {
		return Object.keys(obj).map((key) => {
			return { key: key, value: obj[key] };
		});
	};

	const [array, setArray] = useState([]);

	useEffect(() => {
		if (fetchedData) {
			setArray(objToAray(fetchedData?.data?.translations));
		} else {
			setArray([]);
		}
	}, [fetchedData, fetchedDataSuccess]);

	if (fetchedData) {
		return (
			<div>
				<Nav className='nav-tabs dropdown-tabs nav-tabs-custom gap-5 mb-3'>
					{languages?.data.map((item, index) => (
						<NavItem key={index}>
							<NavLink
								className={classNames({
									'border-primary nav-link-active': activeTab === item.id
								})}
								onClick={() => {
									toggleTab(item.id);
								}}
								to='#'
							>
								{item?.name}
							</NavLink>
						</NavItem>
					))}
				</Nav>

				<Nav className='nav-tabs dropdown-tabs nav-tabs-custom gap-5'></Nav>

				<TabContent activeTab={activeTab}>
					{array &&
						languages?.data.map((language, index) => (
							<TabPane tabId={language.id} key={index}>
								<Form>
									<Row className='align-items-center '>
										<Col md={12} className='mb-4'>
											<Input
												placeholder={`${language.name} ${type} Adı`}
												name='name'
												className='mb-3'
												text='Rol Adı'
												aria-hidden='true'
												type='text'
												id='name-title-input'
												defaultValue={functionFindName(array, language, 'name')}
												onChange={(e) => handleRoleChange(e, language, 'name')}
												onBlurCapture={(e) => {
													if (e.target.value === '') {
														setEmptyFields((prev) => !prev);
													} else {
														setEmptyFields(false);
													}
												}}
											/>
											<Input
												placeholder={`${language.name} ${type} ${
													type === 'Rol' ? 'Açıklaması' : 'İpucu'
												}
												`}
												name={type === 'Rol' ? 'description' : 'hint'}
												text='Rol Açıklaması'
												aria-hidden='true'
												type='text'
												id='name-title-input'
												defaultValue={functionFindName(
													array,
													language,
													type === 'Rol' ? 'description' : 'hint'
												)}
												onChange={(e) =>
													handleRoleChange(
														e,
														language,
														type === 'Rol' ? 'description' : 'hint'
													)
												}
												onBlurCapture={(e) => {
													if (e.target.value === '') {
														setEmptyFields((prev) => !prev);
													} else {
														setEmptyFields(false);
													}
												}}
											/>

											<Alert
												className='mt-3 mb-1'
												color='danger'
												isOpen={emptyFields}
											>
												{language.name} için rol adı zorunludur.
											</Alert>
										</Col>
									</Row>
								</Form>
							</TabPane>
						))}
				</TabContent>
			</div>
		);
	} else {
		return (
			<div>
				{isLoading && !isLanguagesSuccess ? (
					<Spinner />
				) : (
					<>
						<Nav className='nav-tabs dropdown-tabs nav-tabs-custom gap-5 mb-3'>
							{languages?.data.map((language, index) => (
								<NavItem key={index}>
									<NavLink
										href='#'
										className={classNames('transition mb-3', {
											'border-primary nav-link-active':
												activeTab === language.id
										})}
										onClick={() => {
											toggleTab(language.id);
										}}
										to='#'
									>
										{language.name}
									</NavLink>
								</NavItem>
							))}
						</Nav>
						<TabContent activeTab={activeTab}>
							{languages?.data.map((language, index) => (
								<TabPane tabId={language.id} key={index}>
									<Form>
										<Row className='align-items-center '>
											<Col md={12} className='mb-4'>
												<Input
													placeholder={`${language.name} Soru Adı`}
													name='name'
													text='Rol Adı'
													className='mb-4'
													aria-hidden='true'
													type='text'
													id='name-title-input'
													onChange={(e) =>
														handleRoleChange(e, language, 'name')
													}
													onBlurCapture={(e) => {
														if (e.target.value === '') {
															setEmptyFields((prev) => !prev);
														} else {
															setEmptyFields(false);
														}
													}}
												/>

												<Input
													placeholder={`${language.name} Soru Açıklaması`}
													name='hint'
													text='Soru Açıklaması'
													aria-hidden='true'
													type='text'
													id='name-title-input'
													onChange={(e) => {
														handleRoleChange(e, language, 'hint');
													}}
													onBlurCapture={(e) => {
														if (e.target.value === '') {
															setEmptyFields((prev) => !prev);
														} else {
															setEmptyFields(false);
														}
													}}
												/>

												<Alert
													className='mt-3 mb-1'
													color='danger'
													isOpen={emptyFields}
												>
													{language.name} için rol adı zorunludur.
												</Alert>
											</Col>
										</Row>
									</Form>
								</TabPane>
							))}
						</TabContent>
					</>
				)}
			</div>
		);
	}
};

export default LanguagesTab;
