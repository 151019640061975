import apiMiddleware from './middleware/ApiMiddleware';

export const countriesApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getAllCountries: builder.query({
			query: (filter) => ({
				url: '/countries/get-filtered/',
				params: filter
			})
		}),
		changeStatus: builder.mutation({
			query: ({ id, data }) => ({
				url: `/countries/${id}`,
				method: 'PUT',
				body: data
			})
		})
	})
});

export const { useGetAllCountriesQuery, useChangeStatusMutation } =
	countriesApi;
