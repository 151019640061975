import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Spinner
} from 'reactstrap';
import FormInput from '../form/Input';
import * as Yup from 'yup';
import LanguagesTab from '../../Common/MultiLanguageTab';
import {
	useGetQuestionByIdQuery,
	useQuestionTypesQuery,
	useSaveQuestionMutation,
	useUpdateQuestionMutation
} from '../../../store/api/questions';
import Select from 'react-select';
import { toast } from 'react-hot-toast';

const EditQuestionModal = ({ addModal, isEdit, dataId, setAddModal }) => {
	const { data, isLoading, isSuccess, isError, error } = useQuestionTypesQuery({
		skip: !addModal
	});

	const { data: getData, isSuccess: isSuccessGetData } =
		useGetQuestionByIdQuery(dataId, {
			skip: !dataId && !isEdit
		});

	const [
		updateQuestion,
		{
			isLoading: isUpdateLoading,
			isSuccess: isUpdateSuccess,
			isError: isUpdateError
		}
	] = useUpdateQuestionMutation();

	const [switchStatus, setSwitchStatus] = useState(true);
	const photoOpinion = [
		{
			id: 1,
			name: 'Her cevapta fotoğraf çekilmeli',
			value: 0
		},
		{
			id: 2,
			name: 'Her cevapta fotoğraf çekilmemeli',
			value: 1
		},
		{
			id: 3,
			name: 'Her cevapta fotoğraf gerekli ama zorunlu değil',
			value: 2
		},
		{
			id: 4,
			name: 'Cevap evet ise fotoğraf zorunlu',
			value: 3
		},
		{
			id: 5,
			name: 'Cevap hayır ise fotoğraf zorunlu',
			value: 4
		}
	];
	const centralApprove = [
		{
			id: 1,
			name: 'Cevap Belirtilen Aralık Dışındaysa Merkez Onayı İstenecek',
			value: 0
		},
		{
			id: 2,
			name: 'Onay İstenmiyor',
			value: 1
		}
	];
	const [required, setRequired] = useState();
	const [type, setType] = useState({});
	const [options, setOptions] = useState([]);
	const [value, setValue] = useState('');
	const [values, setValues] = useState({
		minValue: '',
		maxValue: ''
	});
	const [roles, setRoles] = useState({
		translations: []
	});

	const handleSelect = (selectedInput) => {
		setType({
			...type,
			...selectedInput
		});
	};

	const handleInputChange = (e) => {
		const { value } = e.target;
		setValue(value);
	};

	const handleRequired = (e) => {
		setRequired(e.target.checked);
	};

	useEffect(() => {
		if (isSuccessGetData) {
			setType({
				questionType: {
					value: getData.data?.question_type?.id,
					label: getData.data?.question_type?.translation?.label
				},
				photoRequest: {
					value: getData.data?.image_required_array.value,
					label: getData.data?.image_required_array.label
				},
				centralApproval: {
					value: getData.data?.headquarters_approval_array?.value,
					label: getData.data?.headquarters_approval_array?.label
				}
			});
			setSwitchStatus(getData.data.status === 1 ? true : false);
			setRequired(getData?.data?.required === 1 ? true : false);
			setValues({
				maxValue: getData.data.max_value,
				minValue: getData.data.min_value
			});
			setOptions(getData.data.choices);
			setRoles({
				translations: getData.data.translations
			});
		}
	}, [isSuccessGetData, getData, dataId, isEdit]);

	const handleButton = (e) => {
		e.preventDefault();
		const name = value.trim();
		const found = options.find((el) => el.name === name);
		if (found) {
			toast.error('Bu değer zaten mevcut');
			return;
		}
		const newOptions = [...options, { name: value }];
		// Todo : If value is duplicated, dont add
		setOptions(
			newOptions.filter((item) => item.name) // remove empty values
		);
		setValue('');
	};

	const handleRoleChange = (e, language, name) => {
		setRoles({
			...roles,
			translations: {
				...roles.translations,
				[language.id]: {
					...roles.translations[language.id],
					[name]: e.target.value
				}
			}
		});
	};

	const handleSubmit = () => {
		const data = {
			question_type_id: type?.questionType?.value,
			status: switchStatus ? 1 : 0,
			required: required === true ? 1 : 0,
			image_required: type?.photoRequest?.value,
			headquarters_approval: type?.centralApproval?.value,
			choices: options,
			min_value: values?.minValue,
			max_value: values?.maxValue,
			translations: roles?.translations
		};

		if (isEdit) {
			if (!data?.question_type_id) {
				toast.error('Lütfen soru tipi seçiniz');
				return;
			}

			if (!data?.status) {
				toast.error('Lütfen durum seçiniz');
				return;
			}

			if (type?.photoRequest?.value === undefined) {
				toast.error('Lütfen fotoğraf isteği seçiniz');
				return;
			}

			if (!type?.centralApproval?.value === undefined) {
				toast.error('Lütfen merkez onayı seçiniz');
				return;
			}

			if (
				type?.questionType?.label === 'Seçenekli Soru' &&
				!options.length > 0
			) {
				toast.error('Lütfen en az bir seçenek giriniz');
				return;
			}

			if (type?.questionType?.label === 'Sayı' && !data?.min_value) {
				toast.error('Lütfen min değer giriniz');
				return;
			}

			if (type?.questionType?.label === 'Sayı' && !data?.max_value) {
				toast.error('Lütfen max değer giriniz');
				return;
			}

			if (
				type?.questionType?.label === 'Sayı' &&
				data?.min_value > data?.max_value
			) {
				toast.error('Min değer max değerden büyük olamaz');
				return;
			}

			if (
				type?.questionType?.label === 'Sayı' &&
				data?.min_value === data?.max_value
			) {
				toast.error('Min değer max değere eşit olamaz');
				return;
			}

			updateQuestion({ id: dataId, data });
		}
	};

	useEffect(() => {
		if (isUpdateSuccess) {
			toast.success('Soru başarıyla güncellendi');
			setAddModal(false);
		}
	}, [isUpdateSuccess]);

	useEffect(() => {
		if (isUpdateError) {
			toast.error('Soru güncellenirken bir hata oluştu');
		}
	}, [isUpdateError]);

	const closeModal = () => {
		setAddModal((prevState) => !prevState);
	};

	return (
		<Modal
			id={dataId ? dataId : 'EditQuestionModal'}
			isOpen={addModal}
			toggle={closeModal}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
		>
			<ModalHeader toggle={() => setAddModal(false)} className='p-3 bg-white'>
				{isEdit ? 'Soru Düzenle' : 'Soru Ekle'}
			</ModalHeader>
			<ModalBody>
				{isLoading ? (
					<Spinner
						style={{
							width: '3rem',
							height: '3rem'
						}}
					/>
				) : (
					<>
						<LanguagesTab
							role={roles}
							setRole={setRoles}
							handleRoleChange={handleRoleChange}
							fetchedData={getData}
							isQuestion={true}
						/>
						<Select
							className='mb-4'
							placeholder='Soru Tipi'
							options={data?.data?.map((item) => ({
								value: item?.id,
								label: item?.translation.label
							}))}
							name='questionType'
							isMulti={false}
							onChange={(e) => handleSelect({ questionType: e })}
							defaultValue={type?.questionType}
							value={type?.questionType}
						/>
						{type?.questionType?.label === 'Sayı' && (
							<div className='d-flex gap-2 mb-4'>
								<Input
									type='number'
									placeholder='Min Değer'
									name='minValue'
									onChange={(e) =>
										setValues({
											...values,
											minValue: e.target.value
										})
									}
									value={isEdit ? getData?.data?.min_value : values.minValue}
								/>
								<Input
									type='number'
									placeholder='Maks Değer'
									name='maxValue'
									onChange={(e) =>
										setValues({
											...values,
											maxValue: e.target.value
										})
									}
									value={isEdit ? getData?.data?.max_value : values.maxValue}
								/>
							</div>
						)}
						<Select
							className='mb-4'
							placeholder='Fotoğraf İsteği'
							options={photoOpinion?.map((item) => ({
								value: item?.value,
								label: item?.name
							}))}
							name='photoRequest'
							isMulti={false}
							isClearable={true}
							onChange={(e) => handleSelect({ photoRequest: e })}
							defaultValue={type?.photoRequest}
							value={type?.photoRequest}
						/>
						{(type?.questionType?.label === 'Sayı' ||
							type?.questionType?.label === 'Evet/Hayır' ||
							type?.questionType?.label === 'Ondalık') && (
							<>
								<Select
									className='mb-4'
									placeholder='Merkez Onayı'
									options={centralApprove?.map((item) => ({
										value: item?.value,
										label: item?.name
									}))}
									name='centralApproval'
									isMulti={false}
									isClearable={true}
									onChange={(e) => handleSelect({ centralApproval: e })}
									defaultValue={type?.centralApproval}
									value={type?.centralApproval}
								/>
							</>
						)}
						{(type?.questionType?.label === 'Tek Seçmeli' ||
							type?.questionType?.label === 'Çoktan Seçmeli') && (
							<>
								<hr className='mt-4 mb-4' />
								<Input
									type='text'
									className='mb-4'
									placeholder='Seçenekler'
									onChange={handleInputChange}
									value={value}
								/>
								<Button
									color='primary'
									className='mb-4 w-100'
									onClick={handleButton}
								>
									Seçenek Ekle
								</Button>

								{options?.map((item, key) => (
									<div
										className='d-flex justify-content-between align-items-center mb-2
											p-2 rounded bg-light 
										'
										key={key}
									>
										<span>{item.name}</span>
										<Button
											color='danger'
											size='sm'
											onClick={() => {
												const newOptions = options.filter(
													(option) => option !== item
												);
												setOptions(newOptions);
											}}
										>
											<span className='bi bi-trash w-25 h-25 aspect-ratio-1'>
												x
											</span>
										</Button>
									</div>
								))}
							</>
						)}
						<div className='mb-4 form-check form-switch mb-2'>
							<Input
								className='form-check-input'
								type='checkbox'
								role='switch'
								id='flexSwitchCheckDefault'
								name='isActive'
								onChange={(e) => setSwitchStatus(e.target.checked)}
								checked={switchStatus}
							/>
							<label
								className='form-check-label'
								htmlFor='flexSwitchCheckDefault'
							>
								{switchStatus ? 'Aktif' : 'Pasif'}
							</label>
						</div>
						<div className='form-check notification-check'>
							<Input
								className='form-check-input'
								type='checkbox'
								value={required}
								id='requiredInput'
								defaultChecked={required}
								onChange={(e) => handleRequired(e)}
							/>
							<label className='form-check-label' htmlFor='requiredInput'>
								Bu sorunun cevaplanması zorunlu
							</label>
						</div>
						<div className='hstack gap-2 justify-content-start mt-5'>
							<button
								type='submit'
								className='btn btn-primary'
								onClick={handleSubmit}
							>
								<i className='ri-save-3-fill align-bottom'></i> Soruyu Kaydet
							</button>

							<Button
								type='button'
								className='btn btn-danger'
								onClick={() => setAddModal(false)}
							>
								<i className='ri-close-fill align-bottom'></i> Kapat
							</Button>
						</div>
					</>
				)}
			</ModalBody>
		</Modal>
	);
};

export { EditQuestionModal };
