import apiMiddleware from './middleware/ApiMiddleware';

export const shipmentApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getAllCustomer: builder.query({
			query: () => ({
				url: '/customers/get-all'
			}),
			providesTags: ['Customer']
		}),
		addCustomer: builder.mutation({
			query: (data) => ({
				url: '/customers',
				method: 'POST',
				body: data
			}),

			providesTags: ['Customer']
		}),
		updateCustomer: builder.mutation({
			query: ({ customer }) => ({
				url: `/customers/${customer}`,
				method: 'PUT'
			}),
			transformResponse: (response) => {
				return response.data;
			}
		}),

		deleteCustomer: builder.mutation({
			query: ({ customer }) => ({
				url: `/customers/${customer}`,
				method: 'DELETE'
			}),
			transformResponse: (response) => {
				return response.data;
			}
		}),

		getCustomerFilterById: builder.query({
			query: (customer) => ({
				url: `/customers/get-by-id/${customer}`
			})
		}),

		getCustomerUsers: builder.query({
			query: (customer) => ({
				url: `/customers/get-users/${customer}`
			})
		}),

		getCustomerByFiltered: builder.query({
			query: (filter) => ({
				url: `customers/get-filtered`,
				params: filter
			})
		})
	})
});
export const {
	useGetAllCustomerQuery,
	useAddCustomerMutation,
	useUpdateCustomerMutation,
	useDeleteCustomerMutation,
	useGetCustomerFilterByIdQuery,
	useGetCustomerByFilteredQuery,
	useGetCustomerUsersQuery
} = shipmentApi;
