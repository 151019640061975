import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	ModalFooter
} from 'reactstrap';

import Select from 'react-select';
import {
	useAddRoleMutation,
	useSetRoleStatusMutation,
	useGetRolesByIdQuery,
	useGetAllModulesQuery
} from '../../../store/api/roles';
import { useGetAllCustomerQuery } from '../../../store/api/customer';
import { toast } from 'react-hot-toast';
import { useGetAllCompaniesQuery } from '../../../store/api/companies';
import LanguagesTab from '../../Common/MultiLanguageTab';

export const EditRoleModal = ({
	addModal,
	setAddModal,
	isEdit,
	dataId,
	closeModal
}) => {
	const [id, setId] = useState(dataId?.id);
	const [roleModule, setRoleModule] = useState([]);
	const [fetchedDataRole, setFetchedDataRole] = useState();

	const [checked, setChecked] = useState(true);
	const [selectedPermissions, setSelectedPermissions] = useState([]);

	const [roles, setRoles] = useState({
		translations: [],
		company_id: null,
		customer_id: null
	});

	const { data: getRoleById, isSuccess: getRoleByIdSuccess } =
		useGetRolesByIdQuery(id, {
			skip: !id
		});

	const { data: moduleData, isSuccess: moduleDataIsSuccess } =
		useGetAllModulesQuery(undefined, {
			skip: !addModal
		});

	const { data: companies } = useGetAllCompaniesQuery({
		skip: !addModal
	});

	const [
		updateRole,
		{
			isLoading: isUpdateLoading,
			isError: isUpdateError,
			isSuccess: isUpdateSuccess,
			error: updateError,
			reset: updateDateReset
		}
	] = useSetRoleStatusMutation();

	useEffect(() => {
		setId(dataId?.id);
	}, [dataId, addModal]);

	useEffect(() => {
		if (getRoleByIdSuccess) {
			setRoles({
				...roles,
				company_id: getRoleById?.data?.role?.company?.id,
				customer_id: getRoleById?.data?.role?.customer?.id,
				translations: getRoleById.data.role.translations
			});
			setSelectedPermissions(
				getRoleById?.data?.role?.selectedModulesPermissions
			);
			setChecked(getRoleById?.data?.role?.status === 1 ? true : false);
		}
	}, [getRoleByIdSuccess, dataId]);

	useEffect(() => {
		getRoleByIdSuccess &&
			setFetchedDataRole({
				data: getRoleById.data.role
			});
	}, [getRoleById, getRoleByIdSuccess, addModal]);

	useEffect(() => {
		if (moduleDataIsSuccess) {
			setRoleModule(moduleData.data);
		}
	}, [moduleDataIsSuccess, moduleData, addModal]);

	const handleFormSubmit = (e) => {
		e.preventDefault();

		// console.log('roles', roles);
		updateRole({
			data: {
				...roles,
				status: checked === true ? 1 : 0,
				modules: selectedPermissions
			},
			id: dataId.id
		});
	};

	useEffect(() => {
		if (isUpdateSuccess) {
			toast.success('Rol başarıyla güncellendi');
			setAddModal(false);
		}
	}, [isUpdateSuccess]);

	useEffect(() => {
		if (isUpdateError) {
			toast.error(updateError?.data?.message);
		}
	}, [isUpdateError]);

	const handleSwitch = (e) => {
		setChecked(e.target.checked);
	};

	const handleRoleChange = (e, language) => {
		setRoles({
			...roles,
			translations: {
				...roles.translations,
				[language.id]: {
					...roles.translations[language.id],
					name: e.target.value
				}
			}
		});
	};

	const companyOptions = companies?.data?.map((company) => ({
		value: company.id,
		label: company.name
	}));

	const handleCloseModal = () => {
		setAddModal(false);
	};

	return (
		<Modal
			id='RoleModal'
			isOpen={addModal}
			toggle={closeModal ? closeModal : handleCloseModal}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
			size='xl'
		>
			<ModalHeader
				toggle={closeModal ? closeModal : handleCloseModal}
				className='p-3 bg-white'
			>
				{isEdit ? 'Rolü Düzenle' : 'Rol Oluştur'}
			</ModalHeader>
			<ModalBody>
				<LanguagesTab
					roles={roles}
					setRoles={setRoles}
					handleRoleChange={handleRoleChange}
					fetchedData={fetchedDataRole}
					fetchedDataSuccess={getRoleByIdSuccess}
					type='Rol'
				/>

				<Row>
					<Col md={6} className='mb-4'>
						<label>Şirket</label>
						<Select
							placeholder='Şirket Seçiniz'
							onChange={(value, removedValue) => {
								if (removedValue.action === 'clear') {
									setRoles({
										...roles,
										company_id: null
									});
								}

								setRoles({
									...roles,
									company_id: value.value
								});
							}}
							value={
								companyOptions?.find(
									(company) => company.value === roles.company_id
								) || null
							}
							options={companyOptions}
							isDisabled={roles.customer_id ? true : false}
							isClearable
						/>
					</Col>

					<Col md={6} className='mb-4'>
						<label>Müşteri</label>
						<Select
							placeholder='Müşteri Seçiniz'
							onChange={(value, removedValue) => {
								if (removedValue.action === 'clear') {
									setRoles({
										...roles,
										customer_id: null
									});
								}

								setRoles({
									...roles,
									customer_id: value.value
								});
							}}
							value={
								companyOptions?.find(
									(company) => company.value === roles.customer_id
								) || null
							}
							options={companyOptions}
							isDisabled={roles.company_id ? true : false}
							isClearable
						/>
					</Col>

					<hr className='mb-3' />

					<Col md={12} className='mb-4'>
						<SelectModuleAndRole
							roles={roles}
							setRoles={setRoles}
							roleModules={roleModule}
							selectedPermissions={selectedPermissions}
							setSelectedPermissions={setSelectedPermissions}
						/>
					</Col>

					<hr />
					<Col md={6}>
						<label>Durumu</label>
						<div className='mb-4 form-check form-switch mb-2'>
							<Input
								className='form-check-input'
								type='checkbox'
								role='switch'
								id='flexSwitchCheckDefault'
								name='isActive'
								onChange={(e) => handleSwitch(e)}
								checked={checked}
							/>

							<label
								className='form-check-label'
								htmlFor='flexSwitchCheckDefault'
							>
								{checked ? 'Aktif' : 'Pasif'}
							</label>
						</div>
					</Col>
				</Row>

				<ModalFooter className='p-3 bg-white'>
					<Button color='primary' onClick={handleFormSubmit}>
						{isEdit ? 'Düzenle' : 'Oluştur'}
					</Button>
				</ModalFooter>
			</ModalBody>
		</Modal>
	);
};

const SelectModuleAndRole = ({
	roleModules,
	selectedPermissions,
	setSelectedPermissions
}) => {
	const prepareModule =
		roleModules &&
		roleModules.map((item) => {
			return {
				moduleId: item?.value,
				key: item?.key,
				permissions: item?.permissions?.map((role) => {
					return {
						label: role?.translation?.name,
						value: role?.translation?.permission_id
					};
				}),
				name: item?.translation?.name
			};
		});

	const handleSelectedPermission = (selectedInput, moduleId) => {
		const data = {
			module_id: moduleId,
			permissions: selectedInput.map((item) => item.value)
		};

		const findIndex = selectedPermissions.findIndex(
			(item) => item.module_id === moduleId
		);

		if (findIndex === -1) {
			setSelectedPermissions([...selectedPermissions, data]);
		}

		if (findIndex !== -1) {
			const newArray = selectedPermissions.filter(
				(item) => item.module_id !== moduleId
			);

			setSelectedPermissions([...newArray, data]);
		}

		if (selectedInput.length === 0) {
			const newArray = selectedPermissions.filter(
				(item) => item.module_id !== moduleId
			);

			setSelectedPermissions(newArray);
		}
	};

	return (
		<Row>
			{prepareModule &&
				prepareModule.map((item, key) => {
					return (
						<Col md={4} className='mb-4' key={key}>
							<label>{item.name}</label>
							<Select
								placeholder='Rol Seçiniz'
								onChange={(selectedInput) =>
									handleSelectedPermission(selectedInput, item.moduleId)
								}
								isClearable={true}
								isMulti
								options={item.permissions}
								value={
									selectedPermissions.filter(
										(t) => t.module_id === item.moduleId
									) &&
									selectedPermissions.filter(
										(t) => t.module_id === item.moduleId
									)[0]?.permissions &&
									item.permissions.filter((p) => {
										if (
											selectedPermissions
												.filter((t) => t.module_id === item.moduleId)[0]
												?.permissions?.includes(p.value)
										)
											return p;
									})
								}
							/>
						</Col>
					);
				})}
		</Row>
	);
};

export default EditRoleModal;
