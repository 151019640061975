import React from 'react';
import { Redirect } from 'react-router-dom';

import Login from '../pages/Authentication/Login';
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword';
import Logout from '../pages/Authentication/Logout';

import BasicPasswCreate from '../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate';
import BasicSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg';

import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';

import ActivityLog from '../pages/Administration/ActiviyLogs';
import Notifications from '../pages/Administration/Notifications';
import Roles from '../pages/Roles';
import SystemLogs from '../pages/Administration/SystemLogs';
import Companies from '../pages/Companies';
import Dashboard from '../pages/Dashboard';
import Countries from '../pages/Countries/Countries';
import Cities from '../pages/Cities/Cities';
import Tracker from '../pages/Tracker';
import SystemNotifications from '../pages/SystemNotifications';
import Settings from '../pages/Settings';
import AirPorts from '../pages/Airports';
// //Dashboard
// import OrnekSayfa from '../pages/Pages/Starter/Starter';
// import Dashboard from '../pages/Dashboard/index';

// //Shipments
// import ActiveShipments from '../pages/Shipments/ActiveShipments/index';
// import ComplatedShipments from '../pages/Shipments/ComplatedShipments/index';
// import PendingShipments from '../pages/Shipments/PendingShipments/index';
import ShipmentDetail from '../pages/Shipments/ShipmentDetail/index';
import CustomerDetail from '../pages/Customers/CustomerDetail/index';
import MatchedQuestion from '../pages/Questions/MatchedQuestions';
import { TransactionsCodeDetail } from '../pages/TransactionsCodes/detail';
import Languages from '../pages/Languages';

const customRoutes = [
	{ path: '/dashboard', component: Dashboard },
	{ path: '/activity-logs', component: ActivityLog },
	{ path: '/notifications', component: Notifications },
	{ path: '/system-logs', component: SystemLogs },
	{ path: '/companies', component: Companies },
	{ path: '/languages', component: Languages },
	{ path: '/countries', component: Countries },
	{ path: '/cities', component: Cities },
	{ path: '/tracker', component: Tracker },
	{ path: '/system-notifications', component: SystemNotifications },
	{ path: '/settings', component: Settings },
	{ path: '/match-questions', component: MatchedQuestion },
	// { path: '/shipment-detail', component: ShipmentDetail },
	{ path: '/shipments/detail/:id', component: ShipmentDetail },
	{ path: '/customer/detail/:id', component: CustomerDetail },
	{ path: '/transactions-codes/:id', component: TransactionsCodeDetail }
];

const publicRoutes = [
	// Authentication Page
	{ path: '/logout', component: Logout },
	{ path: '/login', component: Login },
	{ path: '/forgot-password', component: ForgetPasswordPage },
	{ path: '/change-password/:slug', component: BasicPasswCreate },
	{ path: '/change-password-success', component: BasicSuccessMsg },

	// // 404 Page
	{ path: '/404', component: Basic404 }
];

export { publicRoutes, customRoutes };
