import React, { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Alert,
	ModalFooter
} from 'reactstrap';

import {
	useSetPermissionStatusMutation,
	useCreatePermissionMutation,
	useGetPermissionsByIdQuery
} from '../../../store/api/roles';

import { toast } from 'react-hot-toast';

import LanguagesTab from '../../Common/MultiLanguageTab';

export const AddPermissionModal = ({
	addModal,
	setAddModal,
	isEdit,
	dataId
}) => {
	const [checked, setChecked] = useState(true);
	const [roles, setRoles] = useState({
		value: '',
		translations: []
	});

	const [createPermission, { isError: isRoleError, isSuccess: isRoleSuccess }] =
		useCreatePermissionMutation();

	const { data: getDataFromId, isSuccess } = useGetPermissionsByIdQuery(
		{ id: dataId?.id },
		{ skip: !isEdit }
	);

	const [updatePermission] = useSetPermissionStatusMutation(
		{
			id: dataId?.id
		},
		{
			skip: !isEdit && !dataId?.id
		},
		{
			onSuccess: () => {
				toast.success('İzin başarıyla güncellendi');
			},
			onError: () => {
				toast.error('İzin güncellenirken bir hata oluştu');
			}
		}
	);

	useEffect(() => {
		isSuccess && setRoles(getDataFromId.data);
	}, [isSuccess, getDataFromId]);

	useEffect(() => {
		if (isEdit && isSuccess && getDataFromId?.data) {
			setRoles({
				value: getDataFromId?.data?.value,
				translations: getDataFromId?.data?.translations
			});
		}
	}, [isEdit, isSuccess, getDataFromId]);

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (isEdit) {
			updatePermission({
				id: dataId?.id,
				data: roles
			});
			setAddModal(false);
		} else {
			createPermission(roles);
			setAddModal(false);
		}
	};

	const handleSwitch = (e) => {
		setChecked(e.target.checked);
	};

	const handleRoleChange = (e, language) => {
		setRoles({
			...roles,
			translations: {
				...roles.translations,
				[language.id]: {
					...roles.translations[language.id],
					name: e.target.value
				}
			}
		});
	};

	const handleInputChange = (e) => {
		setRoles({
			...roles,
			value: e.target.value
		});
	};

	useEffect(() => {
		if (isRoleSuccess) {
			toast.success('İzin başarıyla oluşturuldu.');
		} else if (isRoleError) {
			toast.error('İzin oluşturulurken bir hata oluştu.');
		}
	}, [isRoleSuccess, isRoleError]);

	return (
		<Modal
			id='RoleModal'
			isOpen={addModal}
			toggle={() => setAddModal((prevState) => !prevState)}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
			size='lg'
		>
			<ModalHeader toggle={() => setAddModal(false)} className='p-3 bg-white'>
				{isEdit ? 'İzni Düzenle' : 'İzin Oluştur'}
			</ModalHeader>
			<ModalBody>
				<LanguagesTab
					roles={roles}
					setRoles={setRoles}
					handleRoleChange={handleRoleChange}
					fetchedData={getDataFromId}
					fetchedDataSuccess={isSuccess}
				/>
				<Form onSubmit={(e) => handleFormSubmit(e)}>
					<Row>
						<Col md={12}>
							<label>Anahtar Kelime</label>
							<Input
								type='text'
								name='key'
								placeholder='Anahtar Kelime (index, create, update, delete)'
								className='mb-4'
								onChange={(e) => handleInputChange(e)}
								pattern='[a-z]+'
								required
								value={isEdit ? roles.value : null}
							/>
							<Alert color='info' className='mb-4' style={{ fontSize: '12px' }}>
								İzin anahtar kelimesi küçük harfle ve bir kelime ile
								yazılmalıdır.
							</Alert>
						</Col>

						<Col md={12}>
							<label>Durumu</label>
							<div className='mb-4 form-check form-switch mb-2'>
								<Input
									className='form-check-input'
									type='checkbox'
									role='switch'
									id='flexSwitchCheckDefault'
									name='isActive'
									onChange={(e) => handleSwitch(e)}
									checked={checked}
								/>

								<label
									className='form-check-label'
									htmlFor='flexSwitchCheckDefault'
								>
									{checked ? 'Aktif' : 'Pasif'}
								</label>
							</div>
						</Col>
					</Row>

					<ModalFooter className='p-3 bg-white'>
						<Button color='primary' type='submit'>
							{isEdit ? 'Düzenle' : 'Oluştur'}
						</Button>
					</ModalFooter>
				</Form>
			</ModalBody>
		</Modal>
	);
};

export default AddPermissionModal;
