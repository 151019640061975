import apiMiddleware from './middleware/ApiMiddleware';

export const dashboardApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getDashboardStats: builder.query({
			query: () => ({
				url: `/dashboard/get-stats`,
				method: 'GET'
			})
		})
	})
});
export const { useGetDashboardStatsQuery } = dashboardApi;
