import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	changeSidebarTheme,
	changeTopbarTheme
} from '../../store/slices/theme/theme';
//constants

const LightDark = ({ layoutMode, onChangeLayoutMode }) => {
	const dispatch = useDispatch();

	const layoutColor = useSelector((state) => state.layoutSlice.leftSidebarType);
	const changeColor = () => {
		dispatch(changeSidebarTheme(layoutColor === 'light' ? 'dark' : 'light'));
		dispatch(changeTopbarTheme(layoutColor === 'light' ? 'dark' : 'light'));
	};

	return (
		<div className='ms-1 header-item d-none d-sm-flex'>
			<button
				onClick={() => changeColor()}
				type='button'
				className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none'
			>
				<i className='bx bx-moon fs-22'></i>
			</button>
		</div>
	);
};

export default LightDark;
