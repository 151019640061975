import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';

const Starter = () => {
	document.title = 'Müşteri Raporları | Yeditepe Go';

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<BreadCrumb title='Müşteri Raporları' pageTitle='Müşteriler' />
					<Row>
						<Col xs={12}></Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Starter;
