import apiMiddleware from './middleware/ApiMiddleware';

export const shippingParametersApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getAllShipmentParameters: builder.query({
			query: (filter) => ({
				url: '/incoterms/get-filtered',
				params: filter
			})
		})
	})
});

export const { useGetAllShipmentParametersQuery } = shippingParametersApi;
