import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';
// Import Data
import navdata from '../LayoutMenuData';
//i18n
import { withTranslation } from 'react-i18next';

const VerticalLayout = (props) => {
	const menuData = navdata().props.children;
	const [currentPath, setCurrentPath] = useState(props.location.pathname);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		const initMenu = () => {
			const pathName = props.location.pathname;
			const ul = document.getElementById('navbar-nav');
			const items = ul.getElementsByTagName('a');
			let itemsArray = [...items]; // converts NodeList to Array
			removeActivation(itemsArray);
			let matchingMenuItem = itemsArray.find((x) => {
				return x.pathname === pathName;
			});
			if (matchingMenuItem) {
				activateParentDropdown(matchingMenuItem);
			}
		};
		if (props.layoutType === 'vertical') {
			initMenu();
		}
		setCurrentPath(props.location.pathname);
	}, [props.location.pathname, props.layoutType]);

	function activateParentDropdown(item) {
		for (const li of document.querySelectorAll('li.active')) {
			li.classList.remove('active');
		}

		item.classList.add('active');
		let parentCollapseDiv = item.closest('.collapse.menu-dropdown');

		if (parentCollapseDiv) {
			// to set aria expand true remaining
			parentCollapseDiv.classList.add('show');
			parentCollapseDiv.parentElement.children[0].classList.add('active');
			parentCollapseDiv.parentElement.children[0].setAttribute(
				'aria-expanded',
				'true'
			);
			if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown')) {
				parentCollapseDiv.parentElement
					.closest('.collapse')
					.classList.add('show');
				if (
					parentCollapseDiv.parentElement.closest('.collapse')
						.previousElementSibling
				)
					parentCollapseDiv.parentElement
						.closest('.collapse')
						.previousElementSibling.classList.add('active');
				if (
					parentCollapseDiv.parentElement
						.closest('.collapse')
						.previousElementSibling.closest('.collapse')
				) {
					parentCollapseDiv.parentElement
						.closest('.collapse')
						.previousElementSibling.closest('.collapse')
						.classList.add('show');
					parentCollapseDiv.parentElement
						.closest('.collapse')
						.previousElementSibling.closest('.collapse')
						.previousElementSibling.classList.add('active');
				}
			}
			return false;
		}
		return false;
	}

	const removeActivation = (items) => {
		let actiItems = items.filter((x) => x.classList.contains('active'));

		actiItems.forEach((item) => {
			if (item.classList.contains('menu-link')) {
				if (!item.classList.contains('active')) {
					item.setAttribute('aria-expanded', false);
				}
				if (item.nextElementSibling) {
					item.nextElementSibling.classList.remove('show');
				}
			}
			if (item.classList.contains('nav-link')) {
				if (item.nextElementSibling) {
					item.nextElementSibling.classList.remove('show');
				}
				item.setAttribute('aria-expanded', false);
			}
			item.classList.remove('active');
		});
	};

	const [openMenuIndex, setOpenMenuIndex] = useState(null);

	const toggleMenu = (index) => {
		if (openMenuIndex === index) {
			setOpenMenuIndex(null);
		} else {
			setOpenMenuIndex(index);
		}
	};

	return (
		<React.Fragment>
			{/* menu Items */}
			{(menuData || []).map((item, key) => {
				return (
					<React.Fragment key={key}>
						{/* Main Header */}
						{item['isHeader'] ? (
							<li className='menu-title'>
								<span data-key='t-menu'>{props.t(item.name)}</span>
							</li>
						) : item.subItems ? (
							<li className='nav-item'>
								{/* eslint-disable-next-line  */}
								<div
									onClick={() => {
										toggleMenu(key);
									}}
									className='nav-link menu-link'
									// to={item.path ? item.path : '/dashboard'}
									data-bs-toggle='collapse'
								>
									<i className={item.icon}></i>{' '}
									<span data-key='t-apps'>{props.t(item.name)}</span>
									{/* classnames(
											{ openMenuIndex === key && 'nav-link-chevron.active' },
											'nav-link--chevron bx bx-chevron-down' 
										) */}
									<i
										className={classnames(
											{ active: openMenuIndex === key },
											'nav-link--chevron bx bx-chevron-down'
										)}
									></i>
								</div>
								<Collapse
									className='menu-dropdown'
									isOpen={openMenuIndex === key}
									id='sidebarApps'
								>
									<ul className='nav nav-sm flex-column test'>
										{item.subItems &&
											(item.subItems || []).map((subItem, key) => (
												<React.Fragment key={key}>
													{!subItem.isChildItem ? (
														<li className='nav-item'>
															<Link
																to={subItem.path ? subItem.path : '/#'}
																className={
																	subItem.path == currentPath
																		? 'nav-link active'
																		: 'nav-link'
																}
															>
																{props.t(subItem.name)}
																{subItem.badgeName ? (
																	<span
																		className={
																			'badge badge-pill bg-' +
																			subItem.badgeColor
																		}
																		data-key='t-new'
																	>
																		{subItem.badgeName}
																	</span>
																) : null}
															</Link>
														</li>
													) : (
														<li className='nav-item'>
															<Link
																onClick={subItem.click}
																className='nav-link'
																to='/#'
																data-bs-toggle='collapse'
															>
																{props.t(subItem.name)}
															</Link>
															<Collapse
																className='menu-dropdown'
																isOpen={subItem.stateVariables}
																id='sidebarEcommerce'
															>
																<ul className='nav nav-sm flex-column'>
																	{/* child subItms  */}
																	{subItem.childItems &&
																		(subItem.childItems || []).map(
																			(childItem, key) => (
																				<React.Fragment key={key}>
																					{!childItem.childItems ? (
																						<li className='nav-item'>
																							<Link
																								to={
																									childItem.link
																										? childItem.link
																										: '/#'
																								}
																								className={
																									subItem.path == currentPath
																										? 'nav-link active'
																										: 'nav-link'
																								}
																							>
																								{props.t(childItem.name)}
																							</Link>
																						</li>
																					) : (
																						<li className='nav-item'>
																							<Link
																								to='/#'
																								className='nav-link'
																								onClick={childItem.click}
																								data-bs-toggle='collapse'
																							>
																								{props.t(childItem.name)}
																							</Link>
																							<Collapse
																								className='menu-dropdown'
																								isOpen={
																									childItem.stateVariables
																								}
																								id='sidebaremailTemplates'
																							>
																								<ul className='nav nav-sm flex-column'>
																									{childItem.childItems.map(
																										(subChildItem, key) => (
																											<li
																												className={
																													subItem.path ==
																													currentPath
																														? 'nav-item active'
																														: 'nav-item'
																												}
																												key={key}
																											>
																												<Link
																													to={subChildItem.link}
																													className='nav-link'
																													data-key='t-basic-action'
																												>
																													{props.t(
																														subChildItem.name
																													)}{' '}
																												</Link>
																											</li>
																										)
																									)}
																								</ul>
																							</Collapse>
																						</li>
																					)}
																				</React.Fragment>
																			)
																		)}
																</ul>
															</Collapse>
														</li>
													)}
												</React.Fragment>
											))}
									</ul>
								</Collapse>
							</li>
						) : (
							<li className='nav-item'>
								<Link
									className='nav-link menu-link'
									to={item.path ? item.path : '/#'}
								>
									<i className={item.icon}></i>{' '}
									<span>{props.t(item.name)}</span>
								</Link>
							</li>
						)}
					</React.Fragment>
				);
			})}
		</React.Fragment>
	);
};

VerticalLayout.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any
};

export default withRouter(withTranslation()(VerticalLayout));
