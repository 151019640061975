import apiMiddleware from './middleware/ApiMiddleware';

export const addressApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getAddressByUser: builder.query({
			query: (user) => ({
				url: `/addresses/get-by-user-id/${user}`
			})
		}),
		updateOrCreateUserAddress: builder.mutation({
			query: (data) => ({
				url: '/addresses',
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['Question']
		})
	})
});

export const {
	useGetAddressByUserQuery,
	useUpdateOrCreateUserAddressMutation
} = addressApi;
