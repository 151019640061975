import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import {
	Badge,
	Col,
	Form,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Table
} from 'reactstrap';
import { useChangeCityStatusMutation } from '../../../store/api/cities';
import FormInput from '../form/Input';

export const CitiesModal = ({ cityModal, setCityModal, id, item, refetch }) => {
	const [filter, setFilter] = React.useState({
		search: ''
	});

	const [
		status,
		{
			data: statusData,
			isLoading: statusLoading,
			isSuccess: statusSuccess,
			isError: statusError
		}
	] = useChangeCityStatusMutation();

	return (
		<Modal
			id={id}
			isOpen={cityModal ? true : false}
			toggle={() => setCityModal((prevState) => !prevState)}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
		>
			<ModalHeader toggle={() => setCityModal(false)} className='p-3 bg-white'>
				Şehirler
			</ModalHeader>
			<ModalBody>
				<Input
					type='text'
					name='search'
					id='search'
					placeholder='Şehir Ara'
					className='mb-4'
					onChange={(e) => setFilter({ ...filter, search: e.target.value })}
				/>

				<Table
					className='table table table-bordered table-striped table-hover'
					style={{
						maxHeight: '300px',
						overflowY: 'auto',
						display: 'table',
						width: '100%'
					}}
				>
					<thead>
						<tr>
							<th>Şehir</th>
							<th
								style={{
									width: '30%'
								}}
							>
								Durumu
							</th>
						</tr>
					</thead>
					<tbody
						style={{
							maxHeight: '300px',
							overflowY: 'auto',
							display: 'table-caption',
							width: '100%'
						}}
					>
						{item && item.cities.length > 0 ? (
							filter.search.length > 0 ? (
								item.cities
									.filter((city) =>
										city.name
											.toLowerCase()
											.includes(filter.search.toLowerCase())
									)
									.map((city) => (
										<CitiesModal.Item
											item={city}
											key={city.id}
											status={status}
											value={city.status}
											refetch={refetch}
										/>
									))
							) : (
								item.cities.map((city) => (
									<CitiesModal.Item
										item={city}
										key={city.id}
										status={status}
										value={city.status}
										refetch={refetch}
									/>
								))
							)
						) : (
							<tr>
								<td>Şehir Bulunamadı</td>
							</tr>
						)}
					</tbody>
				</Table>
			</ModalBody>
		</Modal>
	);
};

CitiesModal.Item = ({ item, status, value, refetch }) => {
	return (
		<React.Fragment key={item.id}>
			<tr
				key={item.id}
				className='w-100'
				style={{
					display: 'table'
				}}
			>
				<td>{item.name}</td>
				<td
					style={{
						width: '30%'
					}}
				>
					<SwitchInput
						refetch={refetch}
						value={value}
						status={status}
						item={item}
					/>
				</td>
			</tr>
		</React.Fragment>
	);
};

const SwitchInput = ({ value, status, item, setPage, page, refetch }) => {
	const [checked, setChecked] = useState(value === 1 ? true : false);

	const handleSwitch = async (item, e) => {
		setChecked(!checked);

		status({
			id: item.id,
			data: {
				status: checked ? 0 : 1
			}
		});

		setPage(page);
		toast.success('Şehir durumu güncellendi.');
	};
	return (
		<div className='mb-4 form-check form-switch mb-2'>
			<Input
				className='form-check-input'
				type='checkbox'
				role='switch'
				id='flexSwitchCheckDefault'
				name='isActive'
				onChange={() => handleSwitch(item)}
				checked={checked}
			/>

			<label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
				{checked ? 'Aktif' : 'Pasif'}
			</label>
		</div>
	);
};

CitiesModal.Item.displayName = 'Cities Modal Item';

export default CitiesModal;
