import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Container, Input, Spinner } from 'reactstrap';
import {
	useDeleteUsersMutation,
	useGetAllUsersQuery
} from '../../store/api/user';

import DeleteModal from '../../Components/Common/DeleteModal';
import { toast } from 'react-hot-toast';
import TableComponent from '../../Components/ui/table/Table';
import AddUserModal from '../../Components/ui/modals/AddUserModal';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { UserAirportModal } from '../../Components/ui/modals/UserAirportModal';

const Staff = () => {
	document.title = 'Kullanıcı İşlemleri | Yeditepe Go';

	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [limit, setLimit] = useState(10);
	const [modal, setModal] = useState(false);
	const [dataId, setDataId] = useState(null);
	const [addModal, setAddModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [debounceSearch, setDebounceSearch] = useState('');
	const [deleteModal, setDeleteModal] = useState(false);
	const [airportModal, setAirportModal] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState();
	const [selectedUserName, setSelectedUserName] = useState();

	//Pagnation
	const [pagination, setPagination] = useState({
		currentPage: 1,
		hasNextPage: false,
		hasPreviousPage: false,
		total: 0,
		totalPages: 0
	});

	const {
		data: dataArray,
		isLoading,
		isFetching,
		isSuccess,
		refetch
	} = useGetAllUsersQuery({
		search: search ? search : undefined,
		page: page,
		limit: limit
	});

	const handlePageChange = (data) => {
		setPage(data);
	};

	//Memo Data from api
	const memoData = React.useMemo(() => {
		if (isSuccess) {
			setData(dataArray.data.data);
			setPagination({
				total: dataArray.data.total,
				currentPage: dataArray.data.current_page,
				totalPages: dataArray.data.last_page,
				hasNextPage: dataArray.data.next_page_url,
				hasPreviousPage: dataArray.data.prev_page_url ? true : false
			});
		}

		return data;
	}, [isSuccess, dataArray, data]);

	const toggle = () => {
		if (modal) {
			setModal(false);
			setIsEdit(false);
		} else {
			setModal(true);
		}
	};

	const columnData = [
		{
			id: 1,
			name: 'Adı',
			value: 'email'
		},
		{
			id: 2,
			name: 'Firma Adı',
			value: 'companyName'
		},

		{
			id: 3,
			name: 'Rolü',
			value: 'roles'
		},
		{
			id: 4,
			name: 'Status',
			value: 'status'
		},
		{
			id: 5,
			name: 'İşlemler',
			value: 'actions'
		}
	];

	const handleEdit = (id) => {
		setDataId(id);
		setIsEdit(true);
		setAddModal(!addModal);
	};

	const handleAdd = (id) => {
		setAddModal(!addModal);
		setIsEdit(false);
		toggle();
	};

	const handleEditAirport = (user) => {
		setSelectedUserId(user.id);
		setSelectedUserName(user.email);
		setAirportModal(true);
	};

	const openDeleteModal = (id) => {
		setDataId(id);
		setDeleteModal(true);
	};

	const [
		deleteUser,
		{
			isLoading: isDeleteLoading,
			isSuccess: isDeleteSuccess,
			isError: isDeleteError,
			error: deleteError
		}
	] = useDeleteUsersMutation();

	const handleDeleteEvent = () => {
		deleteUser(dataId);
		setDeleteModal(false);
	};

	useEffect(() => {
		isDeleteLoading ? toast.loading('Kullanıcı siliniyor...') : toast.dismiss();

		if (isDeleteSuccess) {
			toast.success('Kullanıcı başarıyla silindi');
			setAddModal(false);
		}
		if (isDeleteError) {
			toast.error(deleteError?.data?.message);
		}
	}, [isDeleteLoading, isDeleteSuccess, isDeleteError, setAddModal]);

	return (
		<>
			<React.Fragment>
				<div className='page-content'>
					<Container fluid>
						<BreadCrumb title='Personel Listesi' pageTitle='Yönetim' />

						<TableComponent
							data={memoData}
							isLoading={isLoading}
							columnData={columnData}
							isFetching={isFetching}
							filter='true'
							handleAdd={handleAdd}
							handleEdit={handleEdit}
							handleEditAirport={handleEditAirport}
							limit={limit}
							handlePageChange={handlePageChange}
							setPage={setPage}
							page={page}
							pagination={pagination}
							setLimit={setLimit}
							search={search}
							setSearch={setSearch}
							addData={true}
							setDebounceSearch={setDebounceSearch}
							openDeleteModal={openDeleteModal}
							addButtonText='Kullanıcı Ekle'
						/>

						<DeleteModal
							show={deleteModal}
							onDeleteClick={handleDeleteEvent}
							onCloseClick={() => setDeleteModal(false)}
							dataId={dataId}
							isLoading={isDeleteLoading}
						/>

						<AddUserModal
							addModal={addModal}
							setAddModal={setAddModal}
							dataId={dataId}
							isEdit={isEdit}
						/>

						<UserAirportModal
							show={airportModal}
							onCloseClick={() => setAirportModal(false)}
							userId={selectedUserId}
							userName={selectedUserName}
						/>
					</Container>
				</div>
			</React.Fragment>
		</>
	);
};

export default Staff;
