import React from 'react';
import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Spinner,
	Table
} from 'reactstrap';
import { useGetShipmentDetailByMawbQuery } from '../../../store/api/shipments';
import TableComponent from '../table/Table';
import { useHistory } from 'react-router-dom';

export const ShowChildShipments = ({ addModal, isEdit, setAddModal, id }) => {
	const {
		data: data,
		isLoading,
		error,
		isSuccess
	} = useGetShipmentDetailByMawbQuery(id, { skip: !id });

	// React.useEffect(() => {
	// 	if (isSuccess) {
	// 		setImage(getImageByIdData.data?.media?.url);
	// 	}
	// }, [isSuccess, getImageByIdData]);

	const columnData = [
		{
			id: 1,
			name: 'Şirket',
			value: 'carrier.num_code'
		},

		{
			id: 3,
			name: 'MAWB',
			value: 'mawb_code'
		},
		{
			id: 2,
			name: 'HAWB',
			value: 'hawb_code'
		},
		{
			id: 4,
			name: 'Firma Adı',
			value: 'company.name'
		},
		{
			id: 5,
			name: 'Ürün Türü',
			value: 'product_code.name'
		},
		{
			id: 6,
			name: 'Kalkış Yeri',
			value: 'port_departure'
		},
		{
			id: 7,
			name: 'Varış Yeri',
			value: 'port_arrival'
		},
		{
			id: 8,
			name: 'Detaylar',
			value: 'details',
			detailLink: '/shipments/detail'
		}
	];

	const history = useHistory();

	return (
		<Modal
			id={id}
			isOpen={addModal}
			toggle={() => setAddModal((prevState) => !prevState)}
			modalClassName='zoomIn'
			centered
			tabIndex='-1'
			keyboard={true}
			size='xl'
		>
			<ModalHeader toggle={() => setAddModal(false)} className='p-3 bg-white'>
				Yavru Konşimento Görüntüle
			</ModalHeader>
			<ModalBody>
				<Table striped bordered hover className='table'>
					<thead className='table-head'>
						<tr>
							{columnData.map((item, index) => (
								<th key={item.id}>{item.name}</th>
							))}
						</tr>
					</thead>
					<tbody className='table-body'>
						{isLoading ? (
							<div className='flex justify-content-center align-items-center w-100 h-100'>
								<Spinner color='primary' />
							</div>
						) : (
							data?.data?.map((item, index) => (
								<React.Fragment key={index}>
									<tr>
										{columnData.map((columnItem, index) => {
											if (columnItem.value === 'carrier.num_code') {
												return <td key={index}>{item?.carrier?.num_code}</td>;
											}
											if (columnItem.value === 'product_code.name') {
												return <td key={index}>{item?.product_code?.name}</td>;
											}

											if (columnItem.value === 'company.name') {
												return <td key={index}>{item.company?.name}</td>;
											}

											if (columnItem.value === 'roles') {
												return (
													<td key={index}>{item?.role?.translation.name}</td>
												);
											}

											if (columnItem.value === 'translation') {
												return <td key={index}>{item?.translation?.name}</td>;
											}

											if (columnItem.value === 'details') {
												return (
													<td key={index}>
														<Button
															color='info'
															size='sm'
															className='d-flex gap-1'
															onClick={() => {
																history.push(
																	`${columnItem.detailLink}/${item.id}`
																);
															}}
														>
															<i className='ri-eye-line'></i>
															Görüntüle
														</Button>
													</td>
												);
											}

											return <td key={index}>{item[columnItem.value]}</td>;
										})}
									</tr>
								</React.Fragment>
							))
						)}
					</tbody>
				</Table>
			</ModalBody>
		</Modal>
	);
};
export default ShowChildShipments;
