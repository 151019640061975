import React from 'react';
import { shipmentWidgets } from './data';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';

const Widgets = () => {
	const count = useSelector((state) => state.shipmentsSlices.count);
	const weight = useSelector((state) => state.shipmentsSlices.weight);

	return (
		<React.Fragment>
			<div className='col-xl-12'>
				<div className='card crm-widget'>
					<div className='card-body p-0'>
						<div className='row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0'>
							<div className='col'>
								<div className='py-4 px-3'>
									<h5 className='text-muted text-uppercase fs-13'>
										Toplam Sevkiyat
									</h5>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<i
												className={'bx bxs-plane-alt display-6 text-muted'}
											></i>
										</div>
										<div className='flex-grow-1 ms-3'>
											<h4 className='mb-0'>
												<span className='counter-value' data-target='197'>
													<CountUp
														start={0}
														prefix={''}
														suffix={''}
														separator={''}
														end={count}
														decimals={0}
														duration={2}
													/>
												</span>
											</h4>
										</div>
									</div>
								</div>
							</div>
							<div className='col'>
								<div className='py-4 px-3'>
									<h5 className='text-muted text-uppercase fs-13'>
										Toplam Ağırlık
									</h5>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<i
												className={'bx bxs-plane-alt display-6 text-muted'}
											></i>
										</div>
										<div className='flex-grow-1 ms-3'>
											<h4 className='mb-0'>
												<span className='counter-value' data-target='197'>
													<CountUp
														start={0}
														prefix={''}
														suffix={''}
														separator={''}
														end={weight}
														decimals={0}
														duration={2}
													/>
												</span>
											</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Widgets;
