import apiMiddleware from './middleware/ApiMiddleware';

export const languageApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getLanguage: builder.query({
			query: () => ({
				url: '/languages/get-active-languages'
			}),
			providesTags: ['Languages']
		}),

		getAllLanguages: builder.query({
			query: (filter) => ({
				url: '/languages/get-filtered',
				params: filter
			}),
			providesTags: ['Languages']
		}),

		setLanguagesStatus: builder.mutation({
			query: ({ id, data }) => ({
				url: `/languages/${id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['Languages']
		}),

		setLanguage: builder.mutation({
			query: (data) => ({
				method: 'POST',
				url: '/languages/change-language',
				body: data
			}),
			invalidatesTags: ['Languages']
		})
	})
});

export const {
	useGetLanguageQuery,
	useSetLanguageMutation,
	useSetLanguagesStatusMutation,
	useGetAllLanguagesQuery
} = languageApi;
