import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: { name: null, email: null },
		token_type: null,
		expires_in: null,
		access_token: null,
		isLogout: true,
		LoginAt: null,
		refreshAt: null,
		menu: [],
		menuChanges: null,
		menuLang: 'a559150f-fe22-4ec4-bc16-8d418d8b9a6d'
	},
	reducers: {
		setUser(state, action) {
			state.user = action.payload.user;
			state.isLogout = false;
		},
		setIsSignedIn(state, action) {
			state.user = action.payload.user;
			state.token_type = action.payload.token_type;
			state.expires_in = action.payload.expires_in;
			state.access_token = action.payload.access_token;
			state.isLogout = false;
			state.LoginAt = Date.now();
			state.menu = action.payload.menu;
			state.menuChanges = action.payload.menuChanges;
		},
		setIsLoggedIn(state, action) {
			state.user = action.payload.user;
			state.token = action.payload.token;
			state.isLogout = false;
		},

		logOutState(state, _) {
			state.user = { name: null, email: null };
			state.token_type = null;
			state.expires_in = null;
			state.access_token = null;
			state.isLogout = true;
			state.menu = [];
		},

		setRefreshToken(state, action) {
			state.access_token = action.payload.access_token;
			state.isLogout = false;
			state.refreshAt = Date.now();
		},

		setMenu(state, action) {
			state.menu = action.payload.menu;
		},

		changeLanguage(state, action) {
			state.menuLang = action.payload;
		}
	}
});

export const {
	setUser,
	setIsSignedIn,
	setIsLoggedIn,
	logOutState,
	setRefreshToken,
	setMenu,
	changeLanguage
} = authSlice.actions;

export const userInformation = (state) => state.auth.user;
export const token_type = (state) => state.auth.token_type;
export const getToken = (state) => state.authSlice.access_token;
export const getIsLogout = (state) => state.authSlice.isLogout;
export const getLoginAt = (state) => state.authSlice.LoginAt;
export const getRefreshAt = (state) => state.authSlice.refreshAt;
export const getMenu = (state) => state.authSlice.menu;
export const getMenuChanges = (state) => state.authSlice.menuChanges;

export default authSlice.reducer;
