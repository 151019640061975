import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	Label,
	Nav,
	NavItem,
	NavLink,
	Pagination,
	PaginationItem,
	PaginationLink,
	Progress,
	Row,
	TabContent,
	Table,
	TabPane,
	UncontrolledCollapse,
	UncontrolledDropdown
} from 'reactstrap';
import classnames from 'classnames';
import { Grid, _ } from 'gridjs-react';
import { useHistory } from 'react-router';

//Images
import profileBg from '../../../assets/images/profile-bg.jpg';
import avatar1 from '../../../assets/images/users/avatar-1.jpg';
import { setCompaniesCount } from '../../../store/slices/shipments/shipmentsSlices';
import {
	useGetCustomerFilterByIdQuery,
	useGetCustomerUsersQuery
} from '../../../store/api/customer';
import { useGetShipmentsByCustomerQuery } from '../../../store/api/shipments';

import { general, activities, overview, projects, document } from './data';
import moment from 'moment';
import TableComponent from '../../../Components/ui/table/Table';
import { useDispatch } from 'react-redux';

const CustomerDetail = () => {
	const history = useHistory();
	const paths = history.location.pathname.split('/');
	const dataId = history.location.pathname.split('/')[3];
	const dispatch = useDispatch();

	const [weight, setWeight] = useState(0);
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [data, setData] = useState([]);
	const [debounceSearch, setDebounceSearch] = useState('');
	const [pagination, setPagination] = useState({
		currentPage: 1,
		hasNextPage: false,
		hasPreviousPage: false,
		total: 0,
		totalPages: 0
	});
	const [flatData, setFlatData] = useState([]);

	const {
		data: detail,
		isLoading,
		isFetching,
		isSuccess
	} = useGetCustomerFilterByIdQuery(dataId, {
		skip: !dataId
	});

	const {
		data: shipments,
		isSuccess: isShipmentSuccess,
		isLoading: isShipmentLoading,
		isFetching: isShipmentFetching,
		refetch: refetchShipment
	} = useGetShipmentsByCustomerQuery({
		customer: dataId,
		filter: {
			search: debounceSearch ? debounceSearch : undefined,
			page: page === 0 ? 1 : page,
			limit: limit
		}
	});

	const {
		data: staffs,
		isLoading: isStaffLoading,
		isFetching: isStaffFetching,
		isSuccess: isStaffSuccess
	} = useGetCustomerUsersQuery(dataId, {
		skip: !dataId
	});

	const shipmentColumns = [
		{
			id: 1,
			name: 'Taşıyıcı',
			value: 'carrier.num_code'
		},
		{
			id: 3,
			name: 'MAWB',
			value: 'mawb_code'
		},
		{
			id: 2,
			name: 'HAWB',
			value: 'hawb_code'
		},
		{
			id: 4,
			name: 'Firma Adı',
			value: 'company.name'
		},
		{
			id: 5,
			name: 'Ürün Türü',
			value: 'product_code.name'
		},
		{
			id: 6,
			name: 'Kalkış Yeri',
			value: 'port_departure'
		},
		{
			id: 7,
			name: 'Varış Yeri',
			value: 'port_arrival'
		},
		{
			id: 8,
			name: 'Tarih',
			value: 'option_date'
		},
		{
			id: 9,
			name: 'Detaylar',
			value: 'details',
			detailLink: '/shipments/detail'
		}
	];

	const staffColumns = [
		{
			id: 1,
			name: 'Adı',
			value: 'email'
		},
		{
			id: 2,
			name: 'Firma Adı',
			value: 'companyName'
		},

		{
			id: 3,
			name: 'Rolü',
			value: 'roles'
		},
		{
			id: 4,
			name: 'Status',
			value: 'status'
		},
		{
			id: 5,
			name: 'İşlemler',
			value: 'actions'
		}
	];

	const [activeTab, setActiveTab] = useState('1');
	const [activityTab, setActivityTab] = useState('1');

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const toggleActivityTab = (tab) => {
		if (activityTab !== tab) {
			setActivityTab(tab);
		}
	};

	useEffect(() => {
		if (debounceSearch) {
			setPage(1);
		} else {
			refetchShipment();
		}
	}, [debounceSearch]);

	useEffect(() => {
		if (isShipmentSuccess) {
			const flat = Object.values(shipments?.data.data)
				.map((item) => item)
				.flatMap((item) => item);
			setFlatData(flat);
		}
	}, [isShipmentSuccess, shipments]);

	useEffect(() => {
		if (isShipmentSuccess) {
			setData(flatData);
			setPagination({
				total: shipments.data.total,
				currentPage: shipments.data.current_page,
				totalPages: shipments.data.last_page,
				hasNextPage: shipments.data.next_page_url,
				hasPreviousPage: shipments.data.prev_page_url ? true : false
			});

			data.map((item, key) => {
				setWeight(weight + Number(item.chargeable_weight));
			});

			dispatch(
				setCompaniesCount({
					count: shipments.data.total,
					weight: weight
				})
			);
		}
	}, [isShipmentSuccess, shipments, data]);

	const handlePageChange = (data) => {
		setPage(data);
		refetchShipment();
	};

	document.title = 'Sevkiyat Detay | Yeditepe Go';

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<div className='profile-foreground position-relative mx-n4 mt-n4'>
						<div className='profile-wid-bg'>
							<img
								src={general[0].coverBg}
								alt=''
								className='profile-wid-img'
							/>
						</div>
					</div>
					<div className='pt-4 mb-4 mb-lg-3 pb-lg-4'>
						<Row className='g-4'>
							<div className='col-auto'>
								<div className='avatar-lg'>
									<img
										src={detail?.data?.media?.url ?? general[0].avatar}
										alt='user-img'
										className='img-thumbnail rounded-circle'
									/>
								</div>
							</div>

							<Col>
								<div className='p-2'>
									<h3 className='text-white mb-1'>{detail?.data?.name}</h3>
									<div className='hstack text-white-50 gap-1'>
										<div className='me-2 d-flex align-items-center'>
											<i className='bx bx-phone me-2'></i>
											{detail?.data?.phone ? detail?.data?.phone : '-'}
										</div>
									</div>
								</div>
							</Col>

							<Col xs={12} lg={3} className=' order-last order-lg-0'>
								<Row className='text text-white-50 text-center'>
									<Col lg={6} xs={6}>
										<div className='p-2'>
											<h4 className='text-white mb-1'>{general[0].shipping}</h4>
											<p className='fs-14 mb-0'>Sevkiyat</p>
										</div>
									</Col>
									<Col lg={6} xs={4}>
										<div className='p-2'>
											<h4 className='text-white mb-1'>{general[0].process}</h4>
											<p className='fs-14 mb-0'>Adet</p>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>

					<Row>
						<Col lg={12}>
							<div>
								<div className='d-flex'>
									<Nav
										pills
										className='animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1'
										role='tablist'
									>
										<NavItem>
											<NavLink
												href='#profile'
												className={classnames(
													{ active: activeTab === '1' },
													'fs-14'
												)}
												onClick={() => {
													toggleTab('1');
												}}
											>
												<i className='ri-folder-4-line d-inline-block d-md-none'></i>{' '}
												<span className='d-none d-md-inline-block'>
													Genel Bilgiler
												</span>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												href='#staffs'
												className={classnames(
													{ active: activeTab === '2' },
													'fs-14'
												)}
												onClick={() => {
													toggleTab('2');
												}}
											>
												<i className='ri-folder-4-line d-inline-block d-md-none'></i>{' '}
												<span className='d-none d-md-inline-block'>
													Personeller
												</span>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												href='#shipments'
												className={classnames(
													{ active: activeTab === '3' },
													'fs-14'
												)}
												onClick={() => {
													toggleTab('3');
												}}
											>
												<i className='ri-folder-4-line d-inline-block d-md-none'></i>{' '}
												<span className='d-none d-md-inline-block'>
													Sevkiyatlar
												</span>
											</NavLink>
										</NavItem>
									</Nav>
								</div>
								{!isLoading && !isStaffLoading && !isShipmentLoading && (
									<TabContent activeTab={activeTab} className='pt-4'>
										<TabPane tabId='1'>
											<Card>
												<CardBody>
													<h5 className='card-title mb-3'>Genel Bilgiler</h5>
													<div className='activity-timeline'>
														<div className='table-responsive'>
															<table className='table table-borderless mb-0'>
																<tbody>
																	<tr>
																		<th className='ps-0' scope='row'>
																			Adı :
																		</th>
																		<td className='text-muted'>
																			{detail?.data?.name}
																		</td>
																	</tr>
																	<tr>
																		<th className='ps-0' scope='row'>
																			Kısa Adı :
																		</th>
																		<td className='text-muted'>
																			{detail?.data?.short_name}
																		</td>
																	</tr>
																	<tr>
																		<th className='ps-0' scope='row'>
																			Adresi :
																		</th>
																		<td className='text-muted'>
																			{detail?.data?.address}
																		</td>
																	</tr>
																	<tr>
																		<th className='ps-0' scope='row'>
																			Telefon :
																		</th>
																		<td className='text-muted'>
																			{detail?.data?.phone
																				? detail?.data?.phone
																				: '-'}
																		</td>
																	</tr>
																	<tr>
																		<th className='ps-0' scope='row'>
																			Vergi Numarası :
																		</th>
																		<td className='text-muted'>
																			{detail?.data?.tax_number
																				? detail?.data?.tax_number
																				: '-'}
																		</td>
																	</tr>
																	<tr>
																		<th className='ps-0' scope='row'>
																			Açıklama :
																		</th>
																		<td className='text-muted'>
																			{detail?.data?.description
																				? detail?.data?.description
																				: '-'}
																		</td>
																	</tr>
																	<tr>
																		<th className='ps-0' scope='row'>
																			Eklenme Tarihi
																		</th>
																		<td className='text-muted'>
																			{moment(detail?.data?.created_at).format(
																				'DD.MM.YYYY'
																			)}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</CardBody>
											</Card>
										</TabPane>
										<TabPane tabId='2'>
											<Card>
												<CardBody>
													<h5 className='card-title mb-3'>Personeller</h5>
													<div className='acitivity-timeline'>
														{staffs?.data?.length > 0 ? (
															<TableComponent
																columnData={staffColumns}
																data={staffs.data}
																setSearch={setSearch}
																search={search}
																filter={true}
															/>
														) : (
															<Row>
																<Col>
																	<div className='text-center'>
																		<div className='mb-4'>
																			<i className='ri-file-list-3-line text-primary display-4' />
																		</div>
																		<h5>Hiçbir personel bulunamadı</h5>
																		<p className='text-muted'>
																			{staffs?.data?.length === 0 &&
																				'Liste boş'}
																		</p>
																	</div>
																</Col>
															</Row>
														)}
													</div>
												</CardBody>
											</Card>
										</TabPane>
										<TabPane tabId='3'>
											<Card>
												<CardBody>
													<h5 className='card-title mb-3'>Sevkiyatlar</h5>
													<div className='acitivity-timeline'>
														{shipments?.data != null ? (
															<TableComponent
																columnData={shipmentColumns}
																data={shipments.data.data}
																setSearch={setSearch}
																search={search}
																filter={true}
																isLoading={isLoading}
																limit={limit}
																setLimit={setLimit}
																page={page}
																setPage={setPage}
																setDebounceSearch={setDebounceSearch}
																pagination={pagination}
																setPagination={setPagination}
																handlePageChange={handlePageChange}
															/>
														) : (
															<Row>
																<Col>
																	<div className='text-center'>
																		<div className='mb-4'>
																			<i className='ri-file-list-3-line text-primary display-4' />
																		</div>
																		<h5>Hiçbir sevkiyat bulunamadı</h5>
																		<p className='text-muted'>
																			{shipments?.data == null && 'Liste boş'}
																		</p>
																	</div>
																</Col>
															</Row>
														)}
													</div>
												</CardBody>
											</Card>
										</TabPane>
									</TabContent>
								)}
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default CustomerDetail;
