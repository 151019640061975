import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Container, Input, Spinner } from 'reactstrap';

import DeleteModal from '../../../Components/Common/DeleteModal';
import { toast } from 'react-hot-toast';
import TableComponent from '../../../Components/ui/table/Table';
import AddQuestionModal from '../../../Components/ui/modals/AddQuestionModal';
import EditRoleModal from '../../../Components/ui/modals/EditRoleModal';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useHistory } from 'react-router-dom';
import {
	useGetFilteredQuestionsQuery,
	useUpdateQuestionMutation,
	useDeleteQuestionMutation,
	useWantsToBeDeleteMutation
} from '../../../store/api/questions';
import { useSelector } from 'react-redux';
import { MatchQuestionModal } from '../../../Components/ui/modals/MatchQuestionModal';
import { MatchedQuestionModal } from '../../../Components/ui/modals/MatchedQuestionModal';
import { EditQuestionModal } from '../../../Components/ui/modals/EditQuestionModal';
const QuestionList = () => {
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [limit, setLimit] = useState(10);
	const [modal, setModal] = useState(false);
	const [dataId, setDataId] = useState(null);
	const [company, setCompany] = useState(null);
	const [addModal, setAddModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [debounceSearch, setDebounceSearch] = useState('');
	const [deleteModal, setDeleteModal] = useState(false);

	document.title = 'Soru Listesi | Yeditepe Go';

	const languageId = useSelector(
		(state) => state.authSlice.user.name.profile.language_id
	);

	//Pagnation
	const [pagination, setPagination] = useState({
		currentPage: 1,
		hasNextPage: false,
		hasPreviousPage: false,
		total: 0,
		totalPages: 0
	});

	const {
		data: dataArray,
		isLoading,
		isFetching,
		isSuccess,
		refetch
	} = useGetFilteredQuestionsQuery({
		search: search ? search : undefined,
		page: page,
		limit: limit
	});

	const [
		deleteQuestion,
		{
			isLoading: isDeleteLoading,
			isSuccess: isDeleteSuccess,
			isError: isDeleteError
		}
	] = useDeleteQuestionMutation();

	useEffect(() => {
		if (isDeleteSuccess) {
			toast.success('Soru başarıyla silindi');
		}
		if (isDeleteError) {
			toast.error('Soru silinirken bir hata oluştu');
		}
	}, [isDeleteSuccess, isDeleteError]);

	const handlePageChange = (data) => {
		setPage(data);
	};
	//Memo Data from api
	const memoData = React.useMemo(() => {
		if (isSuccess) {
			setData(dataArray.data.data);
			setPagination({
				total: dataArray.data.total,
				currentPage: dataArray.data.current_page,
				totalPages: dataArray.data.last_page,
				hasNextPage: dataArray.data.next_page_url,
				hasPreviousPage: dataArray.data.prev_page_url ? true : false,
				limit: limit
			});
		}

		return data;
	}, [isSuccess, dataArray, data]);

	const toggle = () => {
		if (modal) {
			setModal(false);
		} else {
			setModal(true);
		}
	};

	const [
		wantsToBeDelete,
		{
			data: wantToBeDeleteData,
			isLoading: isWantToBeDeleteLoading,
			isSuccess: isWantToBeDeleteSuccess,
			isError: isWantToBeDeleteError,
			error: wantToBeDeleteError
		}
	] = useWantsToBeDeleteMutation();

	useEffect(() => {
		if (isWantToBeDeleteSuccess) {
			toast.success('Soru başarıyla silindi');
		}
	}, [isWantToBeDeleteSuccess]);

	const openDeleteModal = (id) => {
		setDataId(id);
		setDeleteModal(true);
	};

	const handleAdd = () => {
		setCompany('');
		setAddModal(!addModal);
		setIsEdit(false);
		toggle();
	};

	const handleDeleteEvent = () => {
		wantsToBeDelete(dataId);

		setDeleteModal(false);
		toggle();
	};

	const columnData = [
		{
			id: 2,
			name: 'Adı',
			value: 'questionName'
		},

		{
			id: 3,
			name: 'Soru Tipi',
			value: 'question_type_string'
		},
		{
			id: 4,
			name: 'Fotoğraf Zorunluluğu',
			value: 'image_required_string'
		},
		{
			id: 7,
			name: 'Soru Zorunluluğu',
			value: 'headquarters_approval_string'
		},
		{
			id: 5,
			name: 'Durumu',
			value: 'status'
		},
		{
			id: 6,
			name: 'Eylem',
			value: 'actions'
		}
	];

	// const history = useHistory();

	const handleEdit = (data) => {
		setDataId(data.id);
		// setAddModal(!addModal);
		setIsEdit(true);
		setEditModal(true);
	};

	const [editModal, setEditModal] = useState(false);
	const [matchModal, setMatchModal] = useState(false);

	const [matchedQuestion, setMatchedQuestion] = useState(null);

	const handleMatch = (data) => {
		setDataId(data.id);
		// setAddModal(!addModal);
		setMatchModal(true);
	};

	return (
		<>
			<React.Fragment>
				<div className='page-content'>
					<Container fluid>
						<BreadCrumb title='Soru Listesi' pageTitle='Sorular' />

						<TableComponent
							data={memoData}
							isLoading={isLoading}
							isFetching={isFetching}
							columnData={columnData}
							filter='true'
							handleAdd={handleAdd}
							handleEdit={handleEdit}
							limit={limit}
							handlePageChange={handlePageChange}
							setPage={setPage}
							page={page}
							pagination={pagination}
							setLimit={setLimit}
							search={search}
							setSearch={setSearch}
							addData={true}
							setDebounceSearch={setDebounceSearch}
							openDeleteModal={openDeleteModal}
							addButtonText='Soru Ekle'
							languageId={languageId}
							handleMatch={handleMatch}
						/>

						<DeleteModal
							show={deleteModal}
							onDeleteClick={handleDeleteEvent}
							onCloseClick={() => setDeleteModal(false)}
							dataId={dataId}
							isLoading={isWantToBeDeleteLoading}
							wantToBeDeleteData={wantToBeDeleteData}
						/>

						<AddQuestionModal
							addModal={addModal}
							setAddModal={setAddModal}
							dataId={dataId}
							isEdit={isEdit}
						/>

						<EditQuestionModal
							addModal={editModal}
							setAddModal={setEditModal}
							dataId={dataId}
							isEdit={isEdit}
						/>

						<MatchedQuestionModal
							addModal={matchedQuestion}
							setAddModal={setMatchedQuestion}
							dataId={dataId}
							// isEdit={isEdit}
						/>

						<MatchQuestionModal
							addModal={matchModal}
							setAddModal={setMatchModal}
							data={dataId}
							dataId={dataId?.id}
							// isEdit={isEdit}
						/>
					</Container>
				</div>
			</React.Fragment>
		</>
	);
};

export default QuestionList;
