import React, { useState } from 'react';
import { PaginationControl } from 'react-bootstrap-pagination-control';
export const PaginationComponent = ({
	page,
	setPage,
	totalPages,
	limit,
	total,
	handlePageChange
}) => {
	return (
		<PaginationControl
			page={page}
			between={2}
			last={totalPages}
			total={total}
			limit={limit}
			changePage={(page) => {
				setPage(page);
			}}
			ellipsis={1}
		/>
	);
};

export default PaginationComponent;
