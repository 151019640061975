import { createSlice } from '@reduxjs/toolkit';

export const shipmentsSlices = createSlice({
	name: 'companies',
	initialState: {
		count: 0,
		weight: 0
	},
	reducers: {
		setCompaniesCount(state, action) {
			state.count = action.payload.count;
			state.weight = action.payload.weight;
		}
	}
});

export const { setCompaniesCount } = shipmentsSlices.actions;

export default shipmentsSlices.reducer;
