import apiMiddleware from './middleware/ApiMiddleware';

export const citiesApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getAllCities: builder.query({
			query: (filter) => ({
				url: '/cities/get-filtered/',
				params: filter
			})
		}),
		changeCityStatus: builder.mutation({
			query: ({ id, data }) => ({
				url: `/cities/${id}`,
				method: 'PUT',
				body: data
			})
		})
	})
});

export const { useGetAllCitiesQuery, useChangeCityStatusMutation } = citiesApi;
