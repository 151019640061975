import apiMiddleware from './middleware/ApiMiddleware';

export const usersApi = apiMiddleware.injectEndpoints({
	endpoints: (builder) => ({
		getAllUsers: builder.query({
			query: (filter) => ({
				url: '/users/get-filtered',
				params: filter
			}),
			providesTags: ['Users']
		}),

		getUsersById: builder.query({
			query: (id) => ({
				url: `/users/get-by-id/${id}`
			})
		}),

		updateUser: builder.mutation({
			query: ({ id, data }) => ({
				url: `/users/${id}`,
				method: 'PUT',
				body: data
			})
		}),

		addUsers: builder.mutation({
			query: (data) => ({
				url: '/users',
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['Users']
		}),
		deleteUsers: builder.mutation({
			query: (id) => ({
				method: 'DELETE',
				url: `/users/${id}`
			}),
			invalidatesTags: ['Users']
		}),

		createRolebackUser: builder.mutation({
			query: (id) => ({
				url: `/users/roll-back/${id}`,
				method: 'POST'
			}),
			invalidatesTags: ['Users']
		})
	})
});

export const {
	useAddUsersMutation,
	useDeleteUsersMutation,
	useGetAllUsersQuery,
	useGetUsersByIdQuery,

	useUpdateUserMutation,

	useCreateRolebackUserMutation
} = usersApi;
