import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Container, Row, Spinner } from 'reactstrap';

import BreadCrumb from '../../Components/Common/BreadCrumb';
import Tab from '../../Components/ui/tab/Tab';
import TableComponent from '../../Components/ui/table/Table';
// import List from './List';

const Tracker = () => {
	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<BreadCrumb title='Ayarlar' pageTitle='Sistem' />
					<Tab />
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Tracker;
